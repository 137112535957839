/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CustomerDataResponse } from '../models/CustomerDataResponse';
import type { ErrorResponse } from '../models/ErrorResponse';
import type { NoonSigninRequest } from '../models/NoonSigninRequest';
import type { NoonVerifyOtpRequest } from '../models/NoonVerifyOtpRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class NoonAuthService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Noon Sign-in
     * @returns any Successful Response
     * @throws ApiError
     */
    public noonSignin({
        xContent,
        xPlatform,
        xLocale,
        xMp,
        requestBody,
    }: {
        xContent?: string,
        xPlatform?: string,
        xLocale?: string,
        xMp?: string,
        requestBody?: NoonSigninRequest,
    }): CancelablePromise<(CustomerDataResponse | ErrorResponse)> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/_svc/noon-auth/auth/sign-in',
            headers: {
                'X-Content': xContent,
                'X-Platform': xPlatform,
                'X-Locale': xLocale,
                'X-Mp': xMp,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Noon Verify OTP
     * @returns any Successful Response
     * @throws ApiError
     */
    public noonVerifyOtp({
        xContent,
        xPlatform,
        xLocale,
        xMp,
        requestBody,
    }: {
        xContent?: string,
        xPlatform?: string,
        xLocale?: string,
        xMp?: string,
        requestBody?: NoonVerifyOtpRequest,
    }): CancelablePromise<(CustomerDataResponse | ErrorResponse)> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/_svc/noon-auth/auth/verify-sign-in',
            headers: {
                'X-Content': xContent,
                'X-Platform': xPlatform,
                'X-Locale': xLocale,
                'X-Mp': xMp,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Noon Sign-out
     * @returns any Successful Response
     * @throws ApiError
     */
    public noonSignout({
        xContent,
        xPlatform,
        xLocale,
        xMp,
        requestBody,
    }: {
        xContent?: string,
        xPlatform?: string,
        xLocale?: string,
        xMp?: string,
        requestBody?: any,
    }): CancelablePromise<(CustomerDataResponse | ErrorResponse)> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/_svc/noon-auth/auth/sign-out',
            headers: {
                'X-Content': xContent,
                'X-Platform': xPlatform,
                'X-Locale': xLocale,
                'X-Mp': xMp,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}

/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateCardTokenRequest } from '../models/CreateCardTokenRequest';
import type { CreateCardTokenResponse } from '../models/CreateCardTokenResponse';
import type { GetCardTokensResponse } from '../models/GetCardTokensResponse';
import type { InitCardTokenResponse } from '../models/InitCardTokenResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class CardtokensService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get Public Encryption Key
     * @returns InitCardTokenResponse Successful Response
     * @throws ApiError
     */
    public getPublicEncryptionKeyCustomerTokensInitGet({
        xMp,
    }: {
        /**
         * Marketplace code (default is noon)
         */
        xMp?: any,
    }): CancelablePromise<InitCardTokenResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/_svc/payment/customer/tokens/init',
            headers: {
                'x-mp': xMp,
            },
        });
    }

    /**
     * Create Card Token
     * @returns CreateCardTokenResponse Successful Response
     * @throws ApiError
     */
    public createCardTokenCustomerTokensPost({
        requestBody,
        xMp,
    }: {
        requestBody: CreateCardTokenRequest,
        /**
         * Marketplace code (default is noon)
         */
        xMp?: any,
    }): CancelablePromise<CreateCardTokenResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/_svc/payment/customer/tokens',
            headers: {
                'x-mp': xMp,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Card Token
     * @returns GetCardTokensResponse Successful Response
     * @throws ApiError
     */
    public getCardTokenCustomerPaymentcardsGet({
        xMp,
    }: {
        /**
         * Marketplace code (default is noon)
         */
        xMp?: any,
    }): CancelablePromise<GetCardTokensResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/_svc/payment/customer/paymentcards',
            headers: {
                'x-mp': xMp,
            },
        });
    }

    /**
     * Delete Card Token
     * @returns GetCardTokensResponse Successful Response
     * @throws ApiError
     */
    public deleteCardTokenCustomerPaymentcardsIdTokenDelete({
        idToken,
        xMp,
    }: {
        idToken: number,
        /**
         * Marketplace code (default is noon)
         */
        xMp?: any,
    }): CancelablePromise<GetCardTokensResponse> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/_svc/payment/customer/paymentcards/{id_token}',
            path: {
                'id_token': idToken,
            },
            headers: {
                'x-mp': xMp,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Set Card Token Default
     * @returns GetCardTokensResponse Successful Response
     * @throws ApiError
     */
    public setCardTokenDefaultCustomerPaymentcardsIdTokenDefaultPost({
        idToken,
        xMp,
    }: {
        idToken: number,
        /**
         * Marketplace code (default is noon)
         */
        xMp?: any,
    }): CancelablePromise<GetCardTokensResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/_svc/payment/customer/paymentcards/{id_token}/default',
            path: {
                'id_token': idToken,
            },
            headers: {
                'x-mp': xMp,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}

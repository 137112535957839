/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { KeysResponse } from '../models/KeysResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class TokenizationService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get public key
     * Returns the public key as plain text based on the provided key name.
     * @returns string Successful response with public key
     * @throws ApiError
     */
    public getTokenizationKey({
        key,
    }: {
        /**
         * Public key name
         */
        key: string,
    }): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/tokenization/{key}/',
            path: {
                'key': key,
            },
        });
    }

    /**
     * Get available keys
     * Returns a list of available tokenization keys in JSON format.
     * @returns KeysResponse Successful response with list of keys
     * @throws ApiError
     */
    public getTokeizationKeysList(): CancelablePromise<KeysResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/tokenization/keys/',
        });
    }

}

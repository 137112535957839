/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddItemToWishlistRequest } from '../models/AddItemToWishlistRequest';
import type { AllSkusResponse } from '../models/AllSkusResponse';
import type { AllWardrobesResponse } from '../models/AllWardrobesResponse';
import type { CreateWardrobeRequest } from '../models/CreateWardrobeRequest';
import type { GetFilteredItemsResponse } from '../models/GetFilteredItemsResponse';
import type { GetItemsListResponse } from '../models/GetItemsListResponse';
import type { MoveItemToWardrobeRequest } from '../models/MoveItemToWardrobeRequest';
import type { WardrobeResponse } from '../models/WardrobeResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class WardrobeService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * get all wardrobes info for customer
     * @returns AllWardrobesResponse Successful Response
     * @throws ApiError
     */
    public getWardrobes({
        xVisitorId,
        xAddressKey,
        xAddresskey,
        xLocale,
        xForwardedUser,
        xPlatform,
    }: {
        /**
         * Visitor ID
         */
        xVisitorId?: any,
        /**
         * Address Key
         */
        xAddressKey?: any,
        /**
         * Old Address Key
         */
        xAddresskey?: any,
        /**
         * Locale
         */
        xLocale?: any,
        /**
         * Customer Code
         */
        xForwardedUser?: any,
        /**
         * Platform
         */
        xPlatform?: any,
    }): CancelablePromise<AllWardrobesResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/_svc/wishlist/wardrobe/',
            headers: {
                'X-Visitor-Id': xVisitorId,
                'X-Address-Key': xAddressKey,
                'X-Addresskey': xAddresskey,
                'X-Locale': xLocale,
                'X-Forwarded-User': xForwardedUser,
                'X-Platform': xPlatform,
            },
        });
    }

    /**
     * create wardrobe for customer
     * @returns WardrobeResponse Successful Response
     * @throws ApiError
     */
    public createWardrobe({
        requestBody,
        xVisitorId,
        xAddressKey,
        xAddresskey,
        xLocale,
        xForwardedUser,
        xPlatform,
    }: {
        requestBody: CreateWardrobeRequest,
        /**
         * Visitor ID
         */
        xVisitorId?: any,
        /**
         * Address Key
         */
        xAddressKey?: any,
        /**
         * Old Address Key
         */
        xAddresskey?: any,
        /**
         * Locale
         */
        xLocale?: any,
        /**
         * Customer Code
         */
        xForwardedUser?: any,
        /**
         * Platform
         */
        xPlatform?: any,
    }): CancelablePromise<WardrobeResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/_svc/wishlist/wardrobe/',
            headers: {
                'X-Visitor-Id': xVisitorId,
                'X-Address-Key': xAddressKey,
                'X-Addresskey': xAddresskey,
                'X-Locale': xLocale,
                'X-Forwarded-User': xForwardedUser,
                'X-Platform': xPlatform,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * edit wardrobe name for customer
     * @returns WardrobeResponse Successful Response
     * @throws ApiError
     */
    public updateWardrobe({
        wardrobeCode,
        requestBody,
        xVisitorId,
        xAddressKey,
        xAddresskey,
        xLocale,
        xForwardedUser,
        xPlatform,
    }: {
        wardrobeCode: string,
        requestBody: CreateWardrobeRequest,
        /**
         * Visitor ID
         */
        xVisitorId?: any,
        /**
         * Address Key
         */
        xAddressKey?: any,
        /**
         * Old Address Key
         */
        xAddresskey?: any,
        /**
         * Locale
         */
        xLocale?: any,
        /**
         * Customer Code
         */
        xForwardedUser?: any,
        /**
         * Platform
         */
        xPlatform?: any,
    }): CancelablePromise<WardrobeResponse> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/_svc/wishlist/wardrobe/{wardrobe_code}',
            path: {
                'wardrobe_code': wardrobeCode,
            },
            headers: {
                'X-Visitor-Id': xVisitorId,
                'X-Address-Key': xAddressKey,
                'X-Addresskey': xAddresskey,
                'X-Locale': xLocale,
                'X-Forwarded-User': xForwardedUser,
                'X-Platform': xPlatform,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * delete wardrobe for customer
     * @returns any Successful Response
     * @throws ApiError
     */
    public deleteWardrobe({
        wardrobeCode,
        xVisitorId,
        xAddressKey,
        xAddresskey,
        xLocale,
        xForwardedUser,
        xPlatform,
    }: {
        wardrobeCode: string,
        /**
         * Visitor ID
         */
        xVisitorId?: any,
        /**
         * Address Key
         */
        xAddressKey?: any,
        /**
         * Old Address Key
         */
        xAddresskey?: any,
        /**
         * Locale
         */
        xLocale?: any,
        /**
         * Customer Code
         */
        xForwardedUser?: any,
        /**
         * Platform
         */
        xPlatform?: any,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/_svc/wishlist/wardrobe/{wardrobe_code}',
            path: {
                'wardrobe_code': wardrobeCode,
            },
            headers: {
                'X-Visitor-Id': xVisitorId,
                'X-Address-Key': xAddressKey,
                'X-Addresskey': xAddresskey,
                'X-Locale': xLocale,
                'X-Forwarded-User': xForwardedUser,
                'X-Platform': xPlatform,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * get all wishlist items for customer
     * @returns GetItemsListResponse Successful Response
     * @throws ApiError
     */
    public getAllItems({
        page,
        xVisitorId,
        xAddressKey,
        xAddresskey,
        xLocale,
        xForwardedUser,
        xPlatform,
    }: {
        page: number,
        /**
         * Visitor ID
         */
        xVisitorId?: any,
        /**
         * Address Key
         */
        xAddressKey?: any,
        /**
         * Old Address Key
         */
        xAddresskey?: any,
        /**
         * Locale
         */
        xLocale?: any,
        /**
         * Customer Code
         */
        xForwardedUser?: any,
        /**
         * Platform
         */
        xPlatform?: any,
    }): CancelablePromise<GetItemsListResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/_svc/wishlist/wardrobe/items/{page}',
            path: {
                'page': page,
            },
            headers: {
                'X-Visitor-Id': xVisitorId,
                'X-Address-Key': xAddressKey,
                'X-Addresskey': xAddresskey,
                'X-Locale': xLocale,
                'X-Forwarded-User': xForwardedUser,
                'X-Platform': xPlatform,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * get all items in a specific wardrobe
     * @returns GetItemsListResponse Successful Response
     * @throws ApiError
     */
    public getAllWardrobeItems({
        wardrobeCode,
        page,
        xVisitorId,
        xAddressKey,
        xAddresskey,
        xLocale,
        xForwardedUser,
        xPlatform,
    }: {
        wardrobeCode: string,
        page: number,
        /**
         * Visitor ID
         */
        xVisitorId?: any,
        /**
         * Address Key
         */
        xAddressKey?: any,
        /**
         * Old Address Key
         */
        xAddresskey?: any,
        /**
         * Locale
         */
        xLocale?: any,
        /**
         * Customer Code
         */
        xForwardedUser?: any,
        /**
         * Platform
         */
        xPlatform?: any,
    }): CancelablePromise<GetItemsListResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/_svc/wishlist/wardrobe/{wardrobe_code}/items/{page}',
            path: {
                'wardrobe_code': wardrobeCode,
                'page': page,
            },
            headers: {
                'X-Visitor-Id': xVisitorId,
                'X-Address-Key': xAddressKey,
                'X-Addresskey': xAddresskey,
                'X-Locale': xLocale,
                'X-Forwarded-User': xForwardedUser,
                'X-Platform': xPlatform,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * add item to wishlist
     * @returns any Successful Response
     * @throws ApiError
     */
    public addItem1({
        requestBody,
        xVisitorId,
        xAddressKey,
        xAddresskey,
        xLocale,
        xForwardedUser,
        xPlatform,
    }: {
        requestBody: AddItemToWishlistRequest,
        /**
         * Visitor ID
         */
        xVisitorId?: any,
        /**
         * Address Key
         */
        xAddressKey?: any,
        /**
         * Old Address Key
         */
        xAddresskey?: any,
        /**
         * Locale
         */
        xLocale?: any,
        /**
         * Customer Code
         */
        xForwardedUser?: any,
        /**
         * Platform
         */
        xPlatform?: any,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/_svc/wishlist/wardrobe/items',
            headers: {
                'X-Visitor-Id': xVisitorId,
                'X-Address-Key': xAddressKey,
                'X-Addresskey': xAddresskey,
                'X-Locale': xLocale,
                'X-Forwarded-User': xForwardedUser,
                'X-Platform': xPlatform,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * delete item from wishlist
     * @returns any Successful Response
     * @throws ApiError
     */
    public deleteItem({
        parentSku,
        xVisitorId,
        xAddressKey,
        xAddresskey,
        xLocale,
        xForwardedUser,
        xPlatform,
    }: {
        parentSku: string,
        /**
         * Visitor ID
         */
        xVisitorId?: any,
        /**
         * Address Key
         */
        xAddressKey?: any,
        /**
         * Old Address Key
         */
        xAddresskey?: any,
        /**
         * Locale
         */
        xLocale?: any,
        /**
         * Customer Code
         */
        xForwardedUser?: any,
        /**
         * Platform
         */
        xPlatform?: any,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/_svc/wishlist/wardrobe/items/{parent_sku}',
            path: {
                'parent_sku': parentSku,
            },
            headers: {
                'X-Visitor-Id': xVisitorId,
                'X-Address-Key': xAddressKey,
                'X-Addresskey': xAddresskey,
                'X-Locale': xLocale,
                'X-Forwarded-User': xForwardedUser,
                'X-Platform': xPlatform,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * delete item from a wardrobe
     * @returns any Successful Response
     * @throws ApiError
     */
    public deleteItem1({
        parentSku,
        wardrobeCode,
        xVisitorId,
        xAddressKey,
        xAddresskey,
        xLocale,
        xForwardedUser,
        xPlatform,
    }: {
        parentSku: string,
        wardrobeCode: string,
        /**
         * Visitor ID
         */
        xVisitorId?: any,
        /**
         * Address Key
         */
        xAddressKey?: any,
        /**
         * Old Address Key
         */
        xAddresskey?: any,
        /**
         * Locale
         */
        xLocale?: any,
        /**
         * Customer Code
         */
        xForwardedUser?: any,
        /**
         * Platform
         */
        xPlatform?: any,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/_svc/wishlist/wardrobe/{wardrobe_code}/items/{parent_sku}',
            path: {
                'parent_sku': parentSku,
                'wardrobe_code': wardrobeCode,
            },
            headers: {
                'X-Visitor-Id': xVisitorId,
                'X-Address-Key': xAddressKey,
                'X-Addresskey': xAddresskey,
                'X-Locale': xLocale,
                'X-Forwarded-User': xForwardedUser,
                'X-Platform': xPlatform,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * move item to a wardrobe
     * @returns any Successful Response
     * @throws ApiError
     */
    public moveItem({
        wardrobeCode,
        requestBody,
        xVisitorId,
        xAddressKey,
        xAddresskey,
        xLocale,
        xForwardedUser,
        xPlatform,
    }: {
        wardrobeCode: string,
        requestBody: MoveItemToWardrobeRequest,
        /**
         * Visitor ID
         */
        xVisitorId?: any,
        /**
         * Address Key
         */
        xAddressKey?: any,
        /**
         * Old Address Key
         */
        xAddresskey?: any,
        /**
         * Locale
         */
        xLocale?: any,
        /**
         * Customer Code
         */
        xForwardedUser?: any,
        /**
         * Platform
         */
        xPlatform?: any,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/_svc/wishlist/wardrobe/{wardrobe_code}/items',
            path: {
                'wardrobe_code': wardrobeCode,
            },
            headers: {
                'X-Visitor-Id': xVisitorId,
                'X-Address-Key': xAddressKey,
                'X-Addresskey': xAddresskey,
                'X-Locale': xLocale,
                'X-Forwarded-User': xForwardedUser,
                'X-Platform': xPlatform,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * get wishlist items filtering specific wardrobe items
     * @returns GetFilteredItemsResponse Successful Response
     * @throws ApiError
     */
    public getFilteredWishlistItems({
        wardrobeCode,
        xVisitorId,
        xAddressKey,
        xAddresskey,
        xLocale,
        xForwardedUser,
        xPlatform,
    }: {
        wardrobeCode: string,
        /**
         * Visitor ID
         */
        xVisitorId?: any,
        /**
         * Address Key
         */
        xAddressKey?: any,
        /**
         * Old Address Key
         */
        xAddresskey?: any,
        /**
         * Locale
         */
        xLocale?: any,
        /**
         * Customer Code
         */
        xForwardedUser?: any,
        /**
         * Platform
         */
        xPlatform?: any,
    }): CancelablePromise<GetFilteredItemsResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/_svc/wishlist/wardrobe/{wardrobe_code}/filtered-items',
            path: {
                'wardrobe_code': wardrobeCode,
            },
            headers: {
                'X-Visitor-Id': xVisitorId,
                'X-Address-Key': xAddressKey,
                'X-Addresskey': xAddresskey,
                'X-Locale': xLocale,
                'X-Forwarded-User': xForwardedUser,
                'X-Platform': xPlatform,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * get parent skus in customer wishlist for PDP
     * @returns AllSkusResponse Successful Response
     * @throws ApiError
     */
    public getWishlistParentSkus({
        xVisitorId,
        xAddressKey,
        xAddresskey,
        xLocale,
        xForwardedUser,
        xPlatform,
    }: {
        /**
         * Visitor ID
         */
        xVisitorId?: any,
        /**
         * Address Key
         */
        xAddressKey?: any,
        /**
         * Old Address Key
         */
        xAddresskey?: any,
        /**
         * Locale
         */
        xLocale?: any,
        /**
         * Customer Code
         */
        xForwardedUser?: any,
        /**
         * Platform
         */
        xPlatform?: any,
    }): CancelablePromise<AllSkusResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/_svc/wishlist/wardrobe/all-skus',
            headers: {
                'X-Visitor-Id': xVisitorId,
                'X-Address-Key': xAddressKey,
                'X-Addresskey': xAddresskey,
                'X-Locale': xLocale,
                'X-Forwarded-User': xForwardedUser,
                'X-Platform': xPlatform,
            },
        });
    }

}

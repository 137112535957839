export const REACT_QUERY_KEYS = {
    ADD_ITEM_TO_SESSION: 'addItemToSession',
    ADD_ITEM_TO_WARDROBE: 'addItemToWardrobe',
    APPLY_COUPON_CODE: 'applyCouponCode',
    CANCEL_ITEM: 'cancelItem',
    CANCEL_ORDER: 'cancelOrder',
    CREATE_ADDRESS: 'createAddress',
    CREATE_EXCHANGE: 'createExchange',
    CREATE_RETURN: 'createReturn',
    CREATE_WARDROBE: 'createWardrobe',
    CREATE_PRODUCT_REVIEW: 'createProductReview',
    COMPLETE_PAYMENT_INTENT: 'completePaymentIntent',
    DEACTIVATE_ACCOUNT: 'deactivateAccount',
    DELETE_ADDRESS: 'deleteAddress',
    DELETE_COUPON_CODES: 'deleteCouponCodes',
    DELETE_WARDROBE: 'deleteWardrobe',
    DELETE_ITEM_FROM_WISHLIST: 'deleteItemFromWishlist',
    DELETE_ITEM_FROM_ACTIVE_SESSION: 'deleteItemFromActiveSession',
    DISMISS_SESSION_MESSAGES: 'dismissSessionMessages',
    EDIT_ADDRESS: 'editAddress',
    EDIT_WARDROBE: 'editWardrobe',
    GET_ACTIVE_SESSION: 'getActiveSession',
    GET_ACTIVE_SESSION_CHECKOUT: 'getActiveSession-checkout',
    GET_SESSION_COUPONS: 'getSessionCoupons',
    GET_WISHLIST_ITEMS: 'getAllItems',
    GET_AREA_FROM_LOCATION: 'getAreaFromLocation',
    GET_CANCELLABLE_ITEMS: 'getCancellableItems',
    GET_CATALOG_EXTRAS: 'getCatalogExtras',
    GET_COLLECTION_POINTS: 'GET_COLLECTION_POINTS',
    GET_CUSTOMER: 'getCustomer',
    GET_LEGACY_CUSTOMER: 'getLegacyCustomer',
    GET_CUSTOMER_ADDRESSES: 'getCustomerAddresses',
    GET_CUSTOMER_PAYMENT_CARDS: 'getCustomerPaymentCards',
    GET_CUSTOMER_WALLET: 'getCustomerWallet',
    GET_CUSTOMER_EXPIRY_CREDITS: 'getCustomerExpiryCredits',
    GET_DEACTIVATION_CONFIG: 'getDeactivationConfig',
    GET_ENCRYPTION_TOKENS: 'getEncryptionTokens',
    GET_PAYMENT_CONFIG: 'getPaymentConfig',
    GET_TOKENIZATION_KEY: 'getTokenizationKey',
    GET_TOKENIZATION_KEYS: 'getTokenizationKeys',
    ADD_CARD_V2: 'addCardV2',
    GET_ELIGIBLE_EXCHANGES: 'getEligibleExchanges',
    GET_EXCHANGES_LIST: 'getExchangesList',
    GET_INVOICE_TOKEN: 'getInvoiceToken',
    GET_NAVIGATION_CATEGORIES: 'getNavigationCategories',
    GET_ORDER_DETAILS: 'getOrderDetails',
    GET_ORDERS: 'getOrders',
    GET_ORDER_TRACKING_DATA: 'getOrderTrackingData',
    GET_PAGE_CMS_DATA: 'getPageCmsData',
    GET_PAYMENT_INTENT_STATUS: 'getPaymentIntentStatus',
    GET_PHONE_CODES: 'GET_PHONE_CODES',
    GET_PRODUCTS: 'getProducts',
    GET_PRODUCT_DETAILS: 'getProductDetails', // Quickbuy relevant
    GET_RETURNS_LIST: 'getReturnsList',
    GET_RECOMMENDATIONS: 'getRecommendations',
    GET_SPONSORED: 'getSponsored',
    GET_SEARCH_SUGGESTIONS: 'getSearchSuggestions',
    GET_SIZING_GUIDE: 'getSizingGuide',
    GET_USER_VERSION: 'getUserVersion',
    GET_SINGLE_WARDROBE_DATA: 'getSingleWardrobeData',
    GET_FILTERED_WISHLIST_ITEMS: 'getFilteredWishlistItems',
    GET_WEATHER_THEME: 'getWeatherTheme',
    GET_NATIONALITIES: 'getNationalities',
    GET_V2_CUSTOMER: 'getV2Customer',
    GET_USER_SIZE_PROFILE_SIZES: 'getUserSizeProfileSizes',
    GET_USER_SIZE_PROFILE_INFO: 'getUserSizeProfileInfo',
    UPDATE_USER_PROFILE_SIZES: 'updateUserSizeProfileSizes',
    PLACE_ORDER: 'placeOrder',
    REACTIVATE_ACCOUNT: 'reactivateAccount',
    REDEEM_GIFT_CARD: 'redeemGiftCard',
    REDEEM_GIFT_CARD_WITH_PIN: 'redeemGiftCardWithPin',
    REGISTER: 'register',
    REQUEST_PHONE_VERIFICATION_OTP: 'requestPhoneVerificationOtp',
    RESET_PASSWORD: 'resetPassword',
    SAVE_CUSTOMER_TOKENS: 'saveCustomerTokens', // Used when adding a credit card to a customer account
    SIGNIN: 'signin',
    SIGNOUT: 'signout',
    SET_DEFAULT_ADDRESS: 'setDefaultAddress',
    SET_DEFAULT_PAYMENTCARD: 'setDefaultPaymentcard',
    SET_FOUPON: 'setFoupon',
    SET_PAYMENT_METHOD: 'setPaymentMethod',
    SET_SHIPPING_PREFERENCE: 'setShippingPreference',
    UPDATE_SHIPPING_ADDRESS: 'updateShippingAddress',
    UPDATE_PICKUP_ADDRESS: 'updatePickupAddress',
    UPDATE_CUSTOMER: 'updateCustomer',
    VERIFY_PHONE_WITH_OTP: 'verifyPhoneWithOtp',
    GET_CONFIG: 'getConfig',
    NAMSHI_SIGNIN: 'namshiSignin',
    NAMSHI_RESET_PASSWORD: 'namshiResetPassword',
    NOON_SIGNIN: 'noonSignin',
    NOON_VERIFY_OTP: 'noonVerifyOtp',
    CUSTOMER_MIGRATION: 'customerMigration',
    CANCEL_EXCHANGE: 'cancelExchange',
    CANCEL_RETURN: 'cancelReturn',
    REFUND_DETAILS: 'refundDetails',
    CHECK_EMAIL: 'checkEmail',
    INIT_MIGRATION_FLOW: 'initMigrationFlow',
    MIGRATION_SUMMARY: 'migrationSummary',
    VERIFY_ADDRESS_ORDER: 'verifyAddressOrder',
    GET_USER_COUPONS: 'getUserCoupons',
    BRAND_LIST: 'brandList',
    TOGGLE_FOLLOW_BRAND: 'toggleFollowBrand',
    DELIVERY_PROOF: 'deliveryProof',
    GET_RECENTLY_VIEWED: 'recentlyViewed',
    GET_RECENTLY_SEARCHED: 'recentlySearched',
    GET_ALL_WARDROBES: 'getWardrobes',
    GET_WISHLIST_PARENTSKUS: 'getWishlistParentSkus',
    GET_DELIVERY_ZONE_CODE: 'getDeliveryZoneCode',
    GET_CANCEL_REASONS: 'getCancelReasons',
    GET_PLP_FILTERS: 'getPLPFilters',
    GET_GROUPED_PRODUCTS: 'getGroupedProducts',
    GET_ORDER_SUMMARY: 'getOrderSummary',
    MARK_ORDER_CANCELLED: 'markOrderCancelled',
    GET_REVIEWABLE_ITEMS_BY_ORDER: 'getReviewableItemsByOrder',
    GET_REVIEWED_ITEMS_BY_ORDER: 'getReviewedItemsByOrder',
    GET_REVIEWABLE_ITEMS: 'getReviewableItems',
    GET_REVIEWED_ITEMS: 'getReviewedItems',
    DELETE_REVIEW_MEDIA: 'deleteReviewMedia',
    MEDIA_UPLOAD_CONFIRMED: 'mediaUploadConfirmed',
    GET_REVIEWED_ITEM: 'getReviewedItem',
    UPDATE_PRODUCT_REVIEW: 'updateProductReview',
    GET_NEED_HELP_QUESTIONS: 'getNeedHelpQuestions',
    NEED_HELP_LIST: 'needHelpList',
    REQUEST_CALLBACK: 'createCzentrixLead',
    GET_CUSTOMER_OVERLAY: 'getCustomerOverlay',
} as const;

/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ExtrasRequest } from '../models/ExtrasRequest';
import type { ExtrasResponse } from '../models/ExtrasResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ExtrasService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * get extras of a catalog page
     * @returns ExtrasResponse Successful Response
     * @throws ApiError
     */
    public catalogExtras({
        requestBody,
        xVisitorId,
        xAddressKey,
        xLocale,
        xForwardedUser,
        xPlatform,
        xContent,
        xDebug,
    }: {
        requestBody: ExtrasRequest,
        /**
         * Visitor ID
         */
        xVisitorId?: any,
        /**
         * Address Key
         */
        xAddressKey?: any,
        /**
         * Locale
         */
        xLocale?: any,
        /**
         * Customer Code
         */
        xForwardedUser?: any,
        /**
         * Platform
         */
        xPlatform?: any,
        /**
         * Content
         */
        xContent?: any,
        /**
         * Debug Mode
         */
        xDebug?: any,
    }): CancelablePromise<ExtrasResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/_svc/catalog/catalog/extras',
            headers: {
                'X-Visitor-Id': xVisitorId,
                'X-Address-Key': xAddressKey,
                'X-Locale': xLocale,
                'X-Forwarded-User': xForwardedUser,
                'X-Platform': xPlatform,
                'X-Content': xContent,
                'X-Debug': xDebug,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}

/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CatalogRequest } from '../models/CatalogRequest';
import type { CatalogResponse } from '../models/CatalogResponse';
import type { GetProductsRequest } from '../models/GetProductsRequest';
import type { GroupedProductsResponse } from '../models/GroupedProductsResponse';
import type { NavCategoriesRequest } from '../models/NavCategoriesRequest';
import type { NavCategoriesResponse } from '../models/NavCategoriesResponse';
import type { Placement } from '../models/Placement';
import type { PlpFiltersResponse } from '../models/PlpFiltersResponse';
import type { ProductsResponse } from '../models/ProductsResponse';
import type { RecommendationRequest } from '../models/RecommendationRequest';
import type { SuggestionRequest } from '../models/SuggestionRequest';
import type { SuggestionResponse } from '../models/SuggestionResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ProductService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * get product(s) on certain page
     * @returns CatalogResponse Successful Response
     * @throws ApiError
     */
    public catalog({
        requestBody,
        xVisitorId,
        xAddressKey,
        xLocale,
        xForwardedUser,
        xPlatform,
        xContent,
        xDebug,
    }: {
        requestBody: CatalogRequest,
        /**
         * Visitor ID
         */
        xVisitorId?: any,
        /**
         * Address Key
         */
        xAddressKey?: any,
        /**
         * Locale
         */
        xLocale?: any,
        /**
         * Customer Code
         */
        xForwardedUser?: any,
        /**
         * Platform
         */
        xPlatform?: any,
        /**
         * Content
         */
        xContent?: any,
        /**
         * Debug Mode
         */
        xDebug?: any,
    }): CancelablePromise<CatalogResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/_svc/catalog/catalog',
            headers: {
                'X-Visitor-Id': xVisitorId,
                'X-Address-Key': xAddressKey,
                'X-Locale': xLocale,
                'X-Forwarded-User': xForwardedUser,
                'X-Platform': xPlatform,
                'X-Content': xContent,
                'X-Debug': xDebug,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * get similar products
     * @returns CatalogResponse Successful Response
     * @throws ApiError
     */
    public similarProducts({
        zsku,
        xVisitorId,
        xAddressKey,
        xLocale,
        xForwardedUser,
        xPlatform,
        xContent,
        xDebug,
    }: {
        zsku: string,
        /**
         * Visitor ID
         */
        xVisitorId?: any,
        /**
         * Address Key
         */
        xAddressKey?: any,
        /**
         * Locale
         */
        xLocale?: any,
        /**
         * Customer Code
         */
        xForwardedUser?: any,
        /**
         * Platform
         */
        xPlatform?: any,
        /**
         * Content
         */
        xContent?: any,
        /**
         * Debug Mode
         */
        xDebug?: any,
    }): CancelablePromise<CatalogResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/_svc/catalog/catalog/products/{zsku}/similar',
            path: {
                'zsku': zsku,
            },
            headers: {
                'X-Visitor-Id': xVisitorId,
                'X-Address-Key': xAddressKey,
                'X-Locale': xLocale,
                'X-Forwarded-User': xForwardedUser,
                'X-Platform': xPlatform,
                'X-Content': xContent,
                'X-Debug': xDebug,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * get product(s) on certain page
     * @returns CatalogResponse Successful Response
     * @throws ApiError
     */
    public getCatalog({
        uri,
        xVisitorId,
        xAddressKey,
        xLocale,
        xForwardedUser,
        xPlatform,
        xContent,
        xDebug,
    }: {
        uri: string,
        /**
         * Visitor ID
         */
        xVisitorId?: any,
        /**
         * Address Key
         */
        xAddressKey?: any,
        /**
         * Locale
         */
        xLocale?: any,
        /**
         * Customer Code
         */
        xForwardedUser?: any,
        /**
         * Platform
         */
        xPlatform?: any,
        /**
         * Content
         */
        xContent?: any,
        /**
         * Debug Mode
         */
        xDebug?: any,
    }): CancelablePromise<CatalogResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/_svc/catalog/catalog/{uri}',
            path: {
                'uri': uri,
            },
            headers: {
                'X-Visitor-Id': xVisitorId,
                'X-Address-Key': xAddressKey,
                'X-Locale': xLocale,
                'X-Forwarded-User': xForwardedUser,
                'X-Platform': xPlatform,
                'X-Content': xContent,
                'X-Debug': xDebug,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * get grouped products info.
     * @returns GroupedProductsResponse Successful Response
     * @throws ApiError
     */
    public getGroupedProducts({
        sku,
        xVisitorId,
        xAddressKey,
        xLocale,
        xForwardedUser,
        xPlatform,
        xContent,
        xDebug,
    }: {
        sku: string,
        /**
         * Visitor ID
         */
        xVisitorId?: any,
        /**
         * Address Key
         */
        xAddressKey?: any,
        /**
         * Locale
         */
        xLocale?: any,
        /**
         * Customer Code
         */
        xForwardedUser?: any,
        /**
         * Platform
         */
        xPlatform?: any,
        /**
         * Content
         */
        xContent?: any,
        /**
         * Debug Mode
         */
        xDebug?: any,
    }): CancelablePromise<GroupedProductsResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/_svc/catalog/product/{sku}/grouped-products',
            path: {
                'sku': sku,
            },
            headers: {
                'X-Visitor-Id': xVisitorId,
                'X-Address-Key': xAddressKey,
                'X-Locale': xLocale,
                'X-Forwarded-User': xForwardedUser,
                'X-Platform': xPlatform,
                'X-Content': xContent,
                'X-Debug': xDebug,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * get product(s), max 20
     * @returns ProductsResponse Successful Response
     * @throws ApiError
     */
    public products({
        requestBody,
        xVisitorId,
        xAddressKey,
        xLocale,
        xForwardedUser,
        xPlatform,
        xContent,
        xDebug,
    }: {
        requestBody: GetProductsRequest,
        /**
         * Visitor ID
         */
        xVisitorId?: any,
        /**
         * Address Key
         */
        xAddressKey?: any,
        /**
         * Locale
         */
        xLocale?: any,
        /**
         * Customer Code
         */
        xForwardedUser?: any,
        /**
         * Platform
         */
        xPlatform?: any,
        /**
         * Content
         */
        xContent?: any,
        /**
         * Debug Mode
         */
        xDebug?: any,
    }): CancelablePromise<ProductsResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/_svc/catalog/products',
            headers: {
                'X-Visitor-Id': xVisitorId,
                'X-Address-Key': xAddressKey,
                'X-Locale': xLocale,
                'X-Forwarded-User': xForwardedUser,
                'X-Platform': xPlatform,
                'X-Content': xContent,
                'X-Debug': xDebug,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * get recommendations
     * @returns ProductsResponse Successful Response
     * @throws ApiError
     */
    public recommendations({
        requestBody,
        xVisitorId,
        xAddressKey,
        xLocale,
        xForwardedUser,
        xPlatform,
        xContent,
        xDebug,
    }: {
        requestBody: RecommendationRequest,
        /**
         * Visitor ID
         */
        xVisitorId?: any,
        /**
         * Address Key
         */
        xAddressKey?: any,
        /**
         * Locale
         */
        xLocale?: any,
        /**
         * Customer Code
         */
        xForwardedUser?: any,
        /**
         * Platform
         */
        xPlatform?: any,
        /**
         * Content
         */
        xContent?: any,
        /**
         * Debug Mode
         */
        xDebug?: any,
    }): CancelablePromise<ProductsResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/_svc/catalog/recommendations',
            headers: {
                'X-Visitor-Id': xVisitorId,
                'X-Address-Key': xAddressKey,
                'X-Locale': xLocale,
                'X-Forwarded-User': xForwardedUser,
                'X-Platform': xPlatform,
                'X-Content': xContent,
                'X-Debug': xDebug,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * get recommendations
     * Retrieves recommendations based on the placement, SKU, add_sku_to_carousels, tags, and exclusion tags.
     *
     * :param placement: Placement enum indicating the type of recommendations to retrieve.
     * :param sku: Optional string representing the sku.
     * :param add_sku_to_carousels: Optional boolean indicating whether to add the sku to carousels. Default is False.
     * :param tags: Optional list of strings representing product tags for filtering recommendations. Default is None.
     * :param exclude_tags: Optional list of strings representing tags to exclude from recommendations. Default is None.
     *
     * :return: ProductsResponse: The response includes product details and analytics.
     *
     * Documentation:
     * https://docs.google.com/document/d/1N2n0bABYCccOuDU29x2ybR_6P9gz0AatEbHlA-OxjEU/edit
     * @returns ProductsResponse Successful Response
     * @throws ApiError
     */
    public similarRecommendations({
        placement,
        sku,
        addSkuToCarousels = false,
        tags,
        excludeTags,
        xVisitorId,
        xAddressKey,
        xLocale,
        xForwardedUser,
        xPlatform,
        xContent,
        xDebug,
    }: {
        placement: Placement,
        sku?: string,
        addSkuToCarousels?: boolean,
        tags?: Array<string>,
        excludeTags?: Array<string>,
        /**
         * Visitor ID
         */
        xVisitorId?: any,
        /**
         * Address Key
         */
        xAddressKey?: any,
        /**
         * Locale
         */
        xLocale?: any,
        /**
         * Customer Code
         */
        xForwardedUser?: any,
        /**
         * Platform
         */
        xPlatform?: any,
        /**
         * Content
         */
        xContent?: any,
        /**
         * Debug Mode
         */
        xDebug?: any,
    }): CancelablePromise<ProductsResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/_svc/catalog/recommendations/{placement}',
            path: {
                'placement': placement,
            },
            headers: {
                'X-Visitor-Id': xVisitorId,
                'X-Address-Key': xAddressKey,
                'X-Locale': xLocale,
                'X-Forwarded-User': xForwardedUser,
                'X-Platform': xPlatform,
                'X-Content': xContent,
                'X-Debug': xDebug,
            },
            query: {
                'sku': sku,
                'add_sku_to_carousels': addSkuToCarousels,
                'tags': tags,
                'exclude_tags': excludeTags,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * search suggestions
     * @returns SuggestionResponse Successful Response
     * @throws ApiError
     */
    public suggestions({
        requestBody,
        xVisitorId,
        xAddressKey,
        xLocale,
        xForwardedUser,
        xPlatform,
        xContent,
        xDebug,
    }: {
        requestBody: SuggestionRequest,
        /**
         * Visitor ID
         */
        xVisitorId?: any,
        /**
         * Address Key
         */
        xAddressKey?: any,
        /**
         * Locale
         */
        xLocale?: any,
        /**
         * Customer Code
         */
        xForwardedUser?: any,
        /**
         * Platform
         */
        xPlatform?: any,
        /**
         * Content
         */
        xContent?: any,
        /**
         * Debug Mode
         */
        xDebug?: any,
    }): CancelablePromise<SuggestionResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/_svc/catalog/suggestions',
            headers: {
                'X-Visitor-Id': xVisitorId,
                'X-Address-Key': xAddressKey,
                'X-Locale': xLocale,
                'X-Forwarded-User': xForwardedUser,
                'X-Platform': xPlatform,
                'X-Content': xContent,
                'X-Debug': xDebug,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * [deprecated] get the navbar content for root categories
     * @returns NavCategoriesResponse Successful Response
     * @throws ApiError
     */
    public getNavigationCategories({
        departmentCode,
        xVisitorId,
        xAddressKey,
        xLocale,
        xForwardedUser,
        xPlatform,
        xContent,
        xDebug,
    }: {
        departmentCode?: string,
        /**
         * Visitor ID
         */
        xVisitorId?: any,
        /**
         * Address Key
         */
        xAddressKey?: any,
        /**
         * Locale
         */
        xLocale?: any,
        /**
         * Customer Code
         */
        xForwardedUser?: any,
        /**
         * Platform
         */
        xPlatform?: any,
        /**
         * Content
         */
        xContent?: any,
        /**
         * Debug Mode
         */
        xDebug?: any,
    }): CancelablePromise<NavCategoriesResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/_svc/catalog/navigation-categories',
            headers: {
                'X-Visitor-Id': xVisitorId,
                'X-Address-Key': xAddressKey,
                'X-Locale': xLocale,
                'X-Forwarded-User': xForwardedUser,
                'X-Platform': xPlatform,
                'X-Content': xContent,
                'X-Debug': xDebug,
            },
            query: {
                'departmentCode': departmentCode,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * [deprecated] get the navbar content for root categories
     * @returns NavCategoriesResponse Successful Response
     * @throws ApiError
     */
    public navigationCategories({
        requestBody,
        xVisitorId,
        xAddressKey,
        xLocale,
        xForwardedUser,
        xPlatform,
        xContent,
        xDebug,
    }: {
        requestBody: NavCategoriesRequest,
        /**
         * Visitor ID
         */
        xVisitorId?: any,
        /**
         * Address Key
         */
        xAddressKey?: any,
        /**
         * Locale
         */
        xLocale?: any,
        /**
         * Customer Code
         */
        xForwardedUser?: any,
        /**
         * Platform
         */
        xPlatform?: any,
        /**
         * Content
         */
        xContent?: any,
        /**
         * Debug Mode
         */
        xDebug?: any,
    }): CancelablePromise<NavCategoriesResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/_svc/catalog/navigation-categories',
            headers: {
                'X-Visitor-Id': xVisitorId,
                'X-Address-Key': xAddressKey,
                'X-Locale': xLocale,
                'X-Forwarded-User': xForwardedUser,
                'X-Platform': xPlatform,
                'X-Content': xContent,
                'X-Debug': xDebug,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * get the navbar content for root categories
     * @returns any Successful Response
     * @throws ApiError
     */
    public getNavigationCategoriesVersion({
        version,
        xVisitorId,
        xAddressKey,
        xLocale,
        xForwardedUser,
        xPlatform,
        xContent,
        xDebug,
    }: {
        version: string,
        /**
         * Visitor ID
         */
        xVisitorId?: any,
        /**
         * Address Key
         */
        xAddressKey?: any,
        /**
         * Locale
         */
        xLocale?: any,
        /**
         * Customer Code
         */
        xForwardedUser?: any,
        /**
         * Platform
         */
        xPlatform?: any,
        /**
         * Content
         */
        xContent?: any,
        /**
         * Debug Mode
         */
        xDebug?: any,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/_svc/catalog/{version}/navigation-categories',
            path: {
                'version': version,
            },
            headers: {
                'X-Visitor-Id': xVisitorId,
                'X-Address-Key': xAddressKey,
                'X-Locale': xLocale,
                'X-Forwarded-User': xForwardedUser,
                'X-Platform': xPlatform,
                'X-Content': xContent,
                'X-Debug': xDebug,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get size chart for the sku
     * @returns any Successful Response
     * @throws ApiError
     */
    public getSizeChart({
        sku,
        xVisitorId,
        xAddressKey,
        xLocale,
        xForwardedUser,
        xPlatform,
        xContent,
        xDebug,
    }: {
        sku: string,
        /**
         * Visitor ID
         */
        xVisitorId?: any,
        /**
         * Address Key
         */
        xAddressKey?: any,
        /**
         * Locale
         */
        xLocale?: any,
        /**
         * Customer Code
         */
        xForwardedUser?: any,
        /**
         * Platform
         */
        xPlatform?: any,
        /**
         * Content
         */
        xContent?: any,
        /**
         * Debug Mode
         */
        xDebug?: any,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/_svc/catalog/size-chart/{sku}',
            path: {
                'sku': sku,
            },
            headers: {
                'X-Visitor-Id': xVisitorId,
                'X-Address-Key': xAddressKey,
                'X-Locale': xLocale,
                'X-Forwarded-User': xForwardedUser,
                'X-Platform': xPlatform,
                'X-Content': xContent,
                'X-Debug': xDebug,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * get products by customer label
     * @returns ProductsResponse Successful Response
     * @throws ApiError
     */
    public getProductsCarousel({
        customerLabel,
        xVisitorId,
        xAddressKey,
        xLocale,
        xForwardedUser,
        xPlatform,
        xContent,
        xDebug,
    }: {
        customerLabel: string,
        /**
         * Visitor ID
         */
        xVisitorId?: any,
        /**
         * Address Key
         */
        xAddressKey?: any,
        /**
         * Locale
         */
        xLocale?: any,
        /**
         * Customer Code
         */
        xForwardedUser?: any,
        /**
         * Platform
         */
        xPlatform?: any,
        /**
         * Content
         */
        xContent?: any,
        /**
         * Debug Mode
         */
        xDebug?: any,
    }): CancelablePromise<ProductsResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/_svc/catalog/products-carousel/{customer_label}',
            path: {
                'customer_label': customerLabel,
            },
            headers: {
                'X-Visitor-Id': xVisitorId,
                'X-Address-Key': xAddressKey,
                'X-Locale': xLocale,
                'X-Forwarded-User': xForwardedUser,
                'X-Platform': xPlatform,
                'X-Content': xContent,
                'X-Debug': xDebug,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * get the filters response of a plp to refresh filters
     * @returns PlpFiltersResponse Successful Response
     * @throws ApiError
     */
    public getFilters({
        uri,
        xVisitorId,
        xAddressKey,
        xLocale,
        xForwardedUser,
        xPlatform,
        xContent,
        xDebug,
    }: {
        uri: string,
        /**
         * Visitor ID
         */
        xVisitorId?: any,
        /**
         * Address Key
         */
        xAddressKey?: any,
        /**
         * Locale
         */
        xLocale?: any,
        /**
         * Customer Code
         */
        xForwardedUser?: any,
        /**
         * Platform
         */
        xPlatform?: any,
        /**
         * Content
         */
        xContent?: any,
        /**
         * Debug Mode
         */
        xDebug?: any,
    }): CancelablePromise<PlpFiltersResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/_svc/catalog/plp_filters/{uri}',
            path: {
                'uri': uri,
            },
            headers: {
                'X-Visitor-Id': xVisitorId,
                'X-Address-Key': xAddressKey,
                'X-Locale': xLocale,
                'X-Forwarded-User': xForwardedUser,
                'X-Platform': xPlatform,
                'X-Content': xContent,
                'X-Debug': xDebug,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}

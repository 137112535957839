/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ProductReviewGetRequest } from '../models/ProductReviewGetRequest';
import type { ProductReviewList } from '../models/ProductReviewList';
import type { ProductReviewListRequest } from '../models/ProductReviewListRequest';
import type { PurchaseListRequest } from '../models/PurchaseListRequest';
import type { PurchaseListResponse } from '../models/PurchaseListResponse';
import type { PurchaseReview } from '../models/PurchaseReview';
import type { PurchaseReviewCreateRequest } from '../models/PurchaseReviewCreateRequest';
import type { PurchaseReviewGetRequest } from '../models/PurchaseReviewGetRequest';
import type { PurchaseReviewList } from '../models/PurchaseReviewList';
import type { PurchaseReviewListRequest } from '../models/PurchaseReviewListRequest';
import type { ReviewableCountResponse } from '../models/ReviewableCountResponse';
import type { ReviewableListRequest } from '../models/ReviewableListRequest';
import type { ReviewableListResponse } from '../models/ReviewableListResponse';
import type { ReviewProductReview } from '../models/ReviewProductReview';
import type { ReviewProductReviewCreateRequest } from '../models/ReviewProductReviewCreateRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ReviewsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get a product review based on its sku
     * @returns ReviewProductReview Successful Response
     * @throws ApiError
     */
    public getProductReviews({
        requestBody,
    }: {
        requestBody: ProductReviewGetRequest,
    }): CancelablePromise<ReviewProductReview> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/_svc/reviews/collect/v1/product-reviews/get',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create a product review
     * @returns ReviewProductReview Successful Response
     * @throws ApiError
     */
    public postProductReview({
        requestBody,
    }: {
        requestBody: ReviewProductReviewCreateRequest,
    }): CancelablePromise<ReviewProductReview> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/_svc/reviews/collect/v1/product-reviews/create',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get a list of reviewable  purchases based on order_nr
     * @returns PurchaseListResponse Successful Response
     * @throws ApiError
     */
    public getReviewableByOrder({
        requestBody,
    }: {
        requestBody: PurchaseListRequest,
    }): CancelablePromise<PurchaseListResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/_svc/reviews/collect/v1/purchase/list',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get a purchase review based on its purchase item nr
     * @returns PurchaseReview Successful Response
     * @throws ApiError
     */
    public getReviewableByItem({
        requestBody,
    }: {
        requestBody: PurchaseReviewGetRequest,
    }): CancelablePromise<PurchaseReview> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/_svc/reviews/collect/v1/purchase-reviews/get',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create a purchase review
     * @returns PurchaseReview Successful Response
     * @throws ApiError
     */
    public postPurchaseReview({
        requestBody,
    }: {
        requestBody: PurchaseReviewCreateRequest,
    }): CancelablePromise<PurchaseReview> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/_svc/reviews/collect/v1/purchase-reviews/create',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * list a reviews for review section
     * @returns ReviewableListResponse Successful Response
     * @throws ApiError
     */
    public getReviewableItems({
        requestBody,
    }: {
        requestBody: ReviewableListRequest,
    }): CancelablePromise<ReviewableListResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/_svc/reviews/collect/v1/reviewable_entities/list',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * get count of number of reviewable reviews
     * @returns ReviewableCountResponse Successful Response
     * @throws ApiError
     */
    public getReviewableCount(): CancelablePromise<ReviewableCountResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/_svc/reviews/collect/v1/reviewable_entities/count',
        });
    }

    /**
     * list a product reviews
     * @returns ProductReviewList Successful Response
     * @throws ApiError
     */
    public postProductReviewList({
        requestBody,
        reviewLang,
    }: {
        requestBody: ProductReviewListRequest,
        reviewLang?: string,
    }): CancelablePromise<ProductReviewList> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/_svc/reviews/fetch/v1/product-reviews/list',
            cookies: {
                'review_lang': reviewLang,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * list a purchase reviews
     * @returns PurchaseReviewList Successful Response
     * @throws ApiError
     */
    public postPurchaseReviewList({
        requestBody,
        reviewLang,
    }: {
        requestBody: PurchaseReviewListRequest,
        reviewLang?: string,
    }): CancelablePromise<PurchaseReviewList> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/_svc/reviews/fetch/v1/purchase-reviews/list',
            cookies: {
                'review_lang': reviewLang,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}

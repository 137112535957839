/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { UpdateFollowRequest } from '../models/UpdateFollowRequest';
import type { UpdateFollowResponse } from '../models/UpdateFollowResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class CreatorsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Follow/Unfollow a creator for a specific customer
     * @returns UpdateFollowResponse Successful Response
     * @throws ApiError
     */
    public updateFollowVideoCreator({
        creatorCode,
        requestBody,
    }: {
        creatorCode: string,
        requestBody: UpdateFollowRequest,
    }): CancelablePromise<UpdateFollowResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/_svc/video/creators/{creator_code}/update_follow',
            path: {
                'creator_code': creatorCode,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}

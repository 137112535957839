/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetPaymentConfigResponse } from '../models/GetPaymentConfigResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class CheckoutService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get Payment Config
     * @returns GetPaymentConfigResponse Successful Response
     * @throws ApiError
     */
    public getPaymentConfigConfigGet({
        trueBalance = false,
        xMp,
    }: {
        trueBalance?: boolean,
        /**
         * Marketplace code (default is noon)
         */
        xMp?: any,
    }): CancelablePromise<GetPaymentConfigResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/_svc/payment/config',
            headers: {
                'x-mp': xMp,
            },
            query: {
                'true_balance': trueBalance,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}

/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ConfigService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get a apps-config yaml from CMS
     * @returns any Successful Response
     * @throws ApiError
     */
    public getMetaConfig({
        fileName,
        xVisitorId,
        xAddressKey,
        xLocale,
        xForwardedUser,
        xPlatform,
        xContent,
        xDebug,
    }: {
        fileName: string,
        /**
         * Visitor ID
         */
        xVisitorId?: any,
        /**
         * Address Key
         */
        xAddressKey?: any,
        /**
         * Locale
         */
        xLocale?: any,
        /**
         * Customer Code
         */
        xForwardedUser?: any,
        /**
         * Platform
         */
        xPlatform?: any,
        /**
         * Content
         */
        xContent?: any,
        /**
         * Debug Mode
         */
        xDebug?: any,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/_svc/catalog/meta-config/{file_name}',
            path: {
                'file_name': fileName,
            },
            headers: {
                'X-Visitor-Id': xVisitorId,
                'X-Address-Key': xAddressKey,
                'X-Locale': xLocale,
                'X-Forwarded-User': xForwardedUser,
                'X-Platform': xPlatform,
                'X-Content': xContent,
                'X-Debug': xDebug,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}

/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class CmsMigrationService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Migrate CMS content to Spanner
     * @returns string Successful Response
     * @throws ApiError
     */
    public cmsMigration({
        file,
        countryCode,
        pageType,
        token = '',
        xVisitorId,
        xAddressKey,
        xLocale,
        xForwardedUser,
        xPlatform,
        xContent,
        xDebug,
    }: {
        /**
         * cms file name
         */
        file?: string,
        /**
         * country code
         */
        countryCode?: string,
        /**
         * page type
         */
        pageType?: string,
        /**
         * enter password to continue
         */
        token?: string,
        /**
         * Visitor ID
         */
        xVisitorId?: any,
        /**
         * Address Key
         */
        xAddressKey?: any,
        /**
         * Locale
         */
        xLocale?: any,
        /**
         * Customer Code
         */
        xForwardedUser?: any,
        /**
         * Platform
         */
        xPlatform?: any,
        /**
         * Content
         */
        xContent?: any,
        /**
         * Debug Mode
         */
        xDebug?: any,
    }): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/_svc/catalog/cms_migration',
            headers: {
                'X-Visitor-Id': xVisitorId,
                'X-Address-Key': xAddressKey,
                'X-Locale': xLocale,
                'X-Forwarded-User': xForwardedUser,
                'X-Platform': xPlatform,
                'X-Content': xContent,
                'X-Debug': xDebug,
            },
            query: {
                'file': file,
                'country_code': countryCode,
                'page_type': pageType,
                'token': token,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}

/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ListVideoResponse } from '../models/ListVideoResponse';
import type { UpdateVideoActionRequest } from '../models/UpdateVideoActionRequest';
import type { UpdateVideoActionResponse } from '../models/UpdateVideoActionResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class VideosService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get videos
     * @returns ListVideoResponse Successful Response
     * @throws ApiError
     */
    public listVideos({
        page = 1,
        limit = 100,
        codes,
        creator,
        h,
        hashtags,
    }: {
        /**
         * Page number (default is 1)
         */
        page?: number,
        /**
         * Number of videos per page (default is 100)
         */
        limit?: number,
        /**
         * List of video codes to display
         */
        codes?: Array<string>,
        /**
         * Creator code to filter videos
         */
        creator?: string,
        /**
         * List of hashtags to filter videos
         */
        h?: Array<string>,
        /**
         * List of hashtags to filter videos
         */
        hashtags?: Array<string>,
    }): CancelablePromise<ListVideoResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/_svc/video/videos/list',
            query: {
                'page': page,
                'limit': limit,
                'codes': codes,
                'creator': creator,
                'h': h,
                'hashtags': hashtags,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Add/Remove action for a customer on a specific video
     * @returns UpdateVideoActionResponse Successful Response
     * @throws ApiError
     */
    public updateVideoAction({
        videoCode,
        requestBody,
    }: {
        videoCode: string,
        requestBody: UpdateVideoActionRequest,
    }): CancelablePromise<UpdateVideoActionResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/_svc/video/videos/{video_code}/action',
            path: {
                'video_code': videoCode,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Add/Remove bookmark for a customer for a specific video
     * @returns UpdateVideoActionResponse Successful Response
     * @throws ApiError
     */
    public updateVideoCustomerBookmark({
        videoCode,
        requestBody,
    }: {
        videoCode: string,
        requestBody: UpdateVideoActionRequest,
    }): CancelablePromise<UpdateVideoActionResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/_svc/video/videos/{video_code}/update_bookmark',
            path: {
                'video_code': videoCode,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * List bookmarked videos for a customer
     * @returns ListVideoResponse Successful Response
     * @throws ApiError
     */
    public listSavedVideos({
        page = 1,
        limit = 10,
    }: {
        /**
         * Page number (default is 1)
         */
        page?: number,
        /**
         * Number of videos per page (default is 10)
         */
        limit?: number,
    }): CancelablePromise<ListVideoResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/_svc/video/videos/bookmarks',
            query: {
                'page': page,
                'limit': limit,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}

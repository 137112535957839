/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class MashreqService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get mashreq customer session
     * @returns any Successful Response
     * @throws ApiError
     */
    public getSessionMashreqSessionGet({
        xMp,
    }: {
        /**
         * Marketplace code (default is noon)
         */
        xMp?: any,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/_svc/payment/mashreq/session',
            headers: {
                'x-mp': xMp,
            },
        });
    }

}

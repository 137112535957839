import React from 'react';

import { TConversionRates } from '@nm-namshi-frontend/core/types';

type TProps = {
    children: React.ReactNode;
    conversionRates: TConversionRates;
};

type TContext = Omit<TProps, 'children'>;

const ConfigContext = React.createContext<TContext | null>(null);

export const ConfigContextProvider = ({ children, conversionRates }: TProps) => (
    <ConfigContext.Provider
        value={{
            conversionRates,
        }}
    >
        {children}
    </ConfigContext.Provider>
);

const useConfigContext = () => React.useContext(ConfigContext as React.Context<TContext>);

export default useConfigContext;

import React, { useEffect, useState } from 'react';

import clsx from 'clsx';
import Picker from 'react-mobile-picker';
import { useTranslation } from 'react-i18next';

import type { TOption } from '@nm-namshi-frontend/core/types';

import styles from './ModalSelect.module.scss';
import Icon from '../Icon';
import Modal from '../Modal';
import Button from '../Button';
import Loader from '../loaders/Loader';

type TProps = {
    options: TOption[];
    onChange: (value: any) => void;
    style?: any;
    defaultValue?: any;
    className?: string;
    loading?: boolean;
};

const ModalSelect = ({ className, defaultValue, loading, onChange, options, style }: TProps): JSX.Element => {
    const [modalVisable, setModalVisable] = useState(false);
    const [selectedOption, setSelectedOption] = useState(defaultValue);
    const [pickerValue, setPickerValue] = useState({ main: defaultValue });
    const { t } = useTranslation();

    useEffect(() => {
        if (defaultValue) {
            setSelectedOption(defaultValue);
        } else {
            setSelectedOption(options[0]?.value);
        }
    }, [defaultValue, options]);

    useEffect(() => {
        if (modalVisable) {
            setPickerValue({ main: selectedOption });
        }
    }, [modalVisable]);

    const submit = () => {
        setSelectedOption(pickerValue.main);
        onChange(pickerValue.main);
        setModalVisable(false);
    };

    const selectedOptionLabel = options.find((option) => option.value === selectedOption)?.label;

    return (
        <>
            <button
                style={style}
                onClick={() => {
                    setModalVisable(true);
                }}
                type="button"
                className={clsx(styles.mockSelect, className)}
            >
                <div className={styles.selectedOption}>{loading ? <Loader size={16} /> : selectedOptionLabel}</div>
                <Icon name="chevronDownBold" size={12} />
            </button>
            <Modal onClose={() => setModalVisable(false)} open={modalVisable} isContentCovering showCloseButton={false}>
                <div className={styles.modalWrapper}>
                    <Picker value={pickerValue} onChange={setPickerValue as any}>
                        <Picker.Column name="main">
                            {options.map((option) => (
                                <Picker.Item key={option.value} value={option.value}>
                                    {({ selected }) => (
                                        <div className={clsx(styles.option, selected && styles.selectedOption)}>
                                            {option.label}
                                        </div>
                                    )}
                                </Picker.Item>
                            ))}
                        </Picker.Column>
                    </Picker>
                    <Button variant="tertiary" onClick={submit}>
                        {t('done')}
                    </Button>
                </div>
            </Modal>
        </>
    );
};
export default ModalSelect;

/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CardTokenizationTokenRequest } from '../models/CardTokenizationTokenRequest';
import type { CreateCardTokenResponse } from '../models/CreateCardTokenResponse';
import type { TokenConfigResponse } from '../models/TokenConfigResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class CardtokensV2Service {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get Token Config
     * @returns TokenConfigResponse Successful Response
     * @throws ApiError
     */
    public getTokenConfigCustomerTokenConfigGet({
        xMp,
    }: {
        /**
         * Marketplace code (default is noon)
         */
        xMp?: any,
    }): CancelablePromise<TokenConfigResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/_svc/payment/customer/token-config',
            headers: {
                'x-mp': xMp,
            },
        });
    }

    /**
     * Create Card Tokenization Card Token
     * @returns CreateCardTokenResponse Successful Response
     * @throws ApiError
     */
    public createCardTokenizationCardTokenCustomerCardTokensPost({
        requestBody,
        xMp,
    }: {
        requestBody: CardTokenizationTokenRequest,
        /**
         * Marketplace code (default is noon)
         */
        xMp?: any,
    }): CancelablePromise<CreateCardTokenResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/_svc/payment/customer/card-tokens',
            headers: {
                'x-mp': xMp,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}

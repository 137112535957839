/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CategorySizePreferenceResponse } from '../models/CategorySizePreferenceResponse';
import type { ProfileInfoResponse } from '../models/ProfileInfoResponse';
import type { ProfileSizePreferenceRequest } from '../models/ProfileSizePreferenceRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class PreferencesService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * get customer preferences info
     * @returns ProfileInfoResponse Successful Response
     * @throws ApiError
     */
    public getProfileInfo({
        xVisitorId,
        xAddressKey,
        xLocale,
        xForwardedUser,
        xPlatform,
    }: {
        /**
         * Visitor ID
         */
        xVisitorId?: any,
        /**
         * Address Key
         */
        xAddressKey?: any,
        /**
         * Locale
         */
        xLocale?: any,
        /**
         * Customer Code
         */
        xForwardedUser?: any,
        /**
         * Platform
         */
        xPlatform?: any,
    }): CancelablePromise<ProfileInfoResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/_svc/profile/size_preferences/info',
            headers: {
                'X-Visitor-Id': xVisitorId,
                'X-Address-Key': xAddressKey,
                'X-Locale': xLocale,
                'X-Forwarded-User': xForwardedUser,
                'X-Platform': xPlatform,
            },
        });
    }

    /**
     * get customer preferences size
     * @returns CategorySizePreferenceResponse Successful Response
     * @throws ApiError
     */
    public getSizePreferences({
        xVisitorId,
        xAddressKey,
        xLocale,
        xForwardedUser,
        xPlatform,
    }: {
        /**
         * Visitor ID
         */
        xVisitorId?: any,
        /**
         * Address Key
         */
        xAddressKey?: any,
        /**
         * Locale
         */
        xLocale?: any,
        /**
         * Customer Code
         */
        xForwardedUser?: any,
        /**
         * Platform
         */
        xPlatform?: any,
    }): CancelablePromise<CategorySizePreferenceResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/_svc/profile/size_preferences/size',
            headers: {
                'X-Visitor-Id': xVisitorId,
                'X-Address-Key': xAddressKey,
                'X-Locale': xLocale,
                'X-Forwarded-User': xForwardedUser,
                'X-Platform': xPlatform,
            },
        });
    }

    /**
     * update customer preferences size
     * @returns ProfileInfoResponse Successful Response
     * @throws ApiError
     */
    public updateProfileSizePreferences({
        requestBody,
        xVisitorId,
        xAddressKey,
        xLocale,
        xForwardedUser,
        xPlatform,
    }: {
        requestBody: ProfileSizePreferenceRequest,
        /**
         * Visitor ID
         */
        xVisitorId?: any,
        /**
         * Address Key
         */
        xAddressKey?: any,
        /**
         * Locale
         */
        xLocale?: any,
        /**
         * Customer Code
         */
        xForwardedUser?: any,
        /**
         * Platform
         */
        xPlatform?: any,
    }): CancelablePromise<ProfileInfoResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/_svc/profile/size_preferences/size',
            headers: {
                'X-Visitor-Id': xVisitorId,
                'X-Address-Key': xAddressKey,
                'X-Locale': xLocale,
                'X-Forwarded-User': xForwardedUser,
                'X-Platform': xPlatform,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}

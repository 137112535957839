import React, { memo, useMemo, useState } from 'react';

import { getImageUrl, IMAGE_SIZE, PRODUCT_IMAGE_RATIO } from '@nm-namshi-frontend/core/utils/images';
import { IMG_BASE } from '@nm-namshi-frontend/core/config';

type TProps = {
    imageKey?: string;
    size?: IMAGE_SIZE;
    containerClassname?: string;
    imageClassname?: string;
    altText: string;
};

const fallBackURI = `${IMG_BASE}/namshi/images/namshi-placeholder-2.png`;
const blurDataURL =
    'data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mO8XQ8AAjsBXM7pODsAAAAASUVORK5CYII=';

const animationStyles: React.CSSProperties = {
    position: 'relative',
    transition: 'opacity 100ms ease',
    opacity: 1,
    overflow: 'hidden',
};

// eslint-disable-next-line react/display-name
const ProductImage: React.FC<TProps> = memo(
    ({ altText = '', containerClassname = '', imageClassname = '', imageKey, size: width = IMAGE_SIZE.ML }) => {
        const [hasImageFailedToLoad, setHasImageFailedToLoad] = useState(false);

        const imageUri = useMemo(
            () => (hasImageFailedToLoad || !imageKey ? fallBackURI : getImageUrl(imageKey, width)),
            [hasImageFailedToLoad, imageKey, width],
        );

        const height = width * PRODUCT_IMAGE_RATIO;

        return (
            <div
                className={containerClassname}
                style={animationStyles}
                onClick={(e) => {
                    if (hasImageFailedToLoad) {
                        e.stopPropagation();
                    }
                }}
            >
                <img
                    src={imageUri}
                    loading="eager"
                    alt={altText}
                    onError={() => setHasImageFailedToLoad(true)}
                    width={width}
                    height={height}
                    className={imageClassname}
                    style={{ backgroundImage: `url(${blurDataURL})` }}
                />
            </div>
        );
    },
);

export default ProductImage;

/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetValuTenuresPayload } from '../models/GetValuTenuresPayload';
import type { GetValuTenuresResponse } from '../models/GetValuTenuresResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ValuService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get tenures and valu account validation
     * @returns GetValuTenuresResponse Successful Response
     * @throws ApiError
     */
    public getValuTenuresValuTenuresPost({
        requestBody,
        xMp,
    }: {
        requestBody: GetValuTenuresPayload,
        /**
         * Marketplace code (default is noon)
         */
        xMp?: any,
    }): CancelablePromise<GetValuTenuresResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/_svc/payment/valu/tenures',
            headers: {
                'x-mp': xMp,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}

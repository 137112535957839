/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetTabbyAvailablePaymentTypesRequest } from '../models/GetTabbyAvailablePaymentTypesRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class PaymentProviderService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get tabby available payment types
     * @returns any Successful Response
     * @throws ApiError
     */
    public getTabbyAvailablePaymentTypesPaymentProviderTabbyAvailablePaymentTypesPost({
        requestBody,
        xMp,
    }: {
        requestBody: GetTabbyAvailablePaymentTypesRequest,
        /**
         * Marketplace code (default is noon)
         */
        xMp?: any,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/_svc/payment/payment_provider/tabby/available_payment_types',
            headers: {
                'x-mp': xMp,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}

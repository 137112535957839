/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AuthTokenRequest } from '../models/AuthTokenRequest';
import type { AuthTokenResponse } from '../models/AuthTokenResponse';
import type { CustomerDataResponse } from '../models/CustomerDataResponse';
import type { ErrorResponse } from '../models/ErrorResponse';
import type { ResetPasswordRequest } from '../models/ResetPasswordRequest';
import type { ResetPasswordResponse } from '../models/ResetPasswordResponse';
import type { SigninRequest } from '../models/SigninRequest';
import type { SignoutResponse } from '../models/SignoutResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AuthService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get Auth Token
     * @returns AuthTokenResponse Successful Response
     * @throws ApiError
     */
    public getAuthToken({
        requestBody,
        xContent,
        xPlatform,
        xLocale,
        xMp,
    }: {
        requestBody: AuthTokenRequest,
        xContent?: string,
        xPlatform?: string,
        xLocale?: string,
        xMp?: string,
    }): CancelablePromise<AuthTokenResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/_svc/customer-v1/auth/token',
            headers: {
                'X-Content': xContent,
                'X-Platform': xPlatform,
                'X-Locale': xLocale,
                'X-Mp': xMp,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Forgot password
     * @returns ResetPasswordResponse Successful Response
     * @throws ApiError
     */
    public resetPassword({
        requestBody,
        xContent,
        xPlatform,
        xLocale,
        xMp,
    }: {
        requestBody: ResetPasswordRequest,
        xContent?: string,
        xPlatform?: string,
        xLocale?: string,
        xMp?: string,
    }): CancelablePromise<ResetPasswordResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/_svc/customer-v1/auth/reset-password',
            headers: {
                'X-Content': xContent,
                'X-Platform': xPlatform,
                'X-Locale': xLocale,
                'X-Mp': xMp,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Sign-in
     * @returns any Successful Response
     * @throws ApiError
     */
    public signin({
        xContent,
        xPlatform,
        xLocale,
        xMp,
        requestBody,
    }: {
        xContent?: string,
        xPlatform?: string,
        xLocale?: string,
        xMp?: string,
        requestBody?: SigninRequest,
    }): CancelablePromise<(CustomerDataResponse | ErrorResponse)> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/_svc/customer-v1/auth/signin',
            headers: {
                'X-Content': xContent,
                'X-Platform': xPlatform,
                'X-Locale': xLocale,
                'X-Mp': xMp,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Sign-out
     * @returns SignoutResponse Successful Response
     * @throws ApiError
     */
    public signout({
        xContent,
        xPlatform,
        xLocale,
        xMp,
    }: {
        xContent?: string,
        xPlatform?: string,
        xLocale?: string,
        xMp?: string,
    }): CancelablePromise<SignoutResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/_svc/customer-v1/auth/signout',
            headers: {
                'X-Content': xContent,
                'X-Platform': xPlatform,
                'X-Locale': xLocale,
                'X-Mp': xMp,
            },
        });
    }

}

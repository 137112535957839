import clsx from 'clsx';

import styles from './DiscountTag.module.scss';

type TProps = {
    discountPercent?: number;
    size?: 'small' | 'regular' | 'large' | 'x-large';
    className?: boolean;
};

const DiscountTag = ({ className, discountPercent, size = 'regular' }: TProps): JSX.Element => {
    // Discuss : lets not set any thresholds on FE on whether or not to display discounts
    // const MIN_PERC_DISPLAY = 5;
    // const discountPercentage = Math.floor(((price - salePrice) / price) * 100);

    if (discountPercent) {
        return (
            <div
                className={clsx(
                    styles.container,
                    className,
                    size === 'small' && styles.small,
                    size === 'regular' && styles.regular,
                    size === 'large' && styles.large,
                    size === 'x-large' && styles.xLarge,
                )}
            >
                <span className={styles.value} dir="ltr">
                    -{discountPercent}%
                </span>
            </div>
        );
    }

    return <></>;
};

export default DiscountTag;

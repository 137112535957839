/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { FetchHttpRequest } from './core/FetchHttpRequest';

import { AbTestService } from './services/AbTestService';
import { AccountService } from './services/AccountService';
import { AdcbService } from './services/AdcbService';
import { AdmonService } from './services/AdmonService';
import { AdyenService } from './services/AdyenService';
import { AftersalesService } from './services/AftersalesService';
import { AuthService } from './services/AuthService';
import { BrandService } from './services/BrandService';
import { CardtokensService } from './services/CardtokensService';
import { CardtokensV2Service } from './services/CardtokensV2Service';
import { CarouselsService } from './services/CarouselsService';
import { CheckoutService } from './services/CheckoutService';
import { CmsService } from './services/CmsService';
import { CmsMigrationService } from './services/CmsMigrationService';
import { ConfigService } from './services/ConfigService';
import { CreatorsService } from './services/CreatorsService';
import { CurrencyService } from './services/CurrencyService';
import { CustomerService } from './services/CustomerService';
import { CustomerMigrationService } from './services/CustomerMigrationService';
import { CustomerOverlayService } from './services/CustomerOverlayService';
import { DeliveryZoneService } from './services/DeliveryZoneService';
import { ExchangesService } from './services/ExchangesService';
import { ExtrasService } from './services/ExtrasService';
import { GeoService } from './services/GeoService';
import { GiftcardsService } from './services/GiftcardsService';
import { MashreqService } from './services/MashreqService';
import { NamshiAuthService } from './services/NamshiAuthService';
import { NeedhelpService } from './services/NeedhelpService';
import { NoonAuthService } from './services/NoonAuthService';
import { OrderService } from './services/OrderService';
import { PageService } from './services/PageService';
import { PaymentintentService } from './services/PaymentintentService';
import { PaymentProviderService } from './services/PaymentProviderService';
import { PreferencesService } from './services/PreferencesService';
import { ProductService } from './services/ProductService';
import { ProductReviewService } from './services/ProductReviewService';
import { ProxyService } from './services/ProxyService';
import { RecentCarouselsService } from './services/RecentCarouselsService';
import { ReturnsService } from './services/ReturnsService';
import { ReviewsService } from './services/ReviewsService';
import { SessionService } from './services/SessionService';
import { ShopTheLookService } from './services/ShopTheLookService';
import { SizingGuideService } from './services/SizingGuideService';
import { SponsoredService } from './services/SponsoredService';
import { SubscriptionService } from './services/SubscriptionService';
import { SystemService } from './services/SystemService';
import { TokenService } from './services/TokenService';
import { TokenizationService } from './services/TokenizationService';
import { ValuService } from './services/ValuService';
import { VideosService } from './services/VideosService';
import { WardrobeService } from './services/WardrobeService';
import { WishlistService } from './services/WishlistService';

type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;

export class AppClient {

    public readonly abTest: AbTestService;
    public readonly account: AccountService;
    public readonly adcb: AdcbService;
    public readonly admon: AdmonService;
    public readonly adyen: AdyenService;
    public readonly aftersales: AftersalesService;
    public readonly auth: AuthService;
    public readonly brand: BrandService;
    public readonly cardtokens: CardtokensService;
    public readonly cardtokensV2: CardtokensV2Service;
    public readonly carousels: CarouselsService;
    public readonly checkout: CheckoutService;
    public readonly cms: CmsService;
    public readonly cmsMigration: CmsMigrationService;
    public readonly config: ConfigService;
    public readonly creators: CreatorsService;
    public readonly currency: CurrencyService;
    public readonly customer: CustomerService;
    public readonly customerMigration: CustomerMigrationService;
    public readonly customerOverlay: CustomerOverlayService;
    public readonly deliveryZone: DeliveryZoneService;
    public readonly exchanges: ExchangesService;
    public readonly extras: ExtrasService;
    public readonly geo: GeoService;
    public readonly giftcards: GiftcardsService;
    public readonly mashreq: MashreqService;
    public readonly namshiAuth: NamshiAuthService;
    public readonly needhelp: NeedhelpService;
    public readonly noonAuth: NoonAuthService;
    public readonly order: OrderService;
    public readonly page: PageService;
    public readonly paymentintent: PaymentintentService;
    public readonly paymentProvider: PaymentProviderService;
    public readonly preferences: PreferencesService;
    public readonly product: ProductService;
    public readonly productReview: ProductReviewService;
    public readonly proxy: ProxyService;
    public readonly recentCarousels: RecentCarouselsService;
    public readonly returns: ReturnsService;
    public readonly reviews: ReviewsService;
    public readonly session: SessionService;
    public readonly shopTheLook: ShopTheLookService;
    public readonly sizingGuide: SizingGuideService;
    public readonly sponsored: SponsoredService;
    public readonly subscription: SubscriptionService;
    public readonly system: SystemService;
    public readonly token: TokenService;
    public readonly tokenization: TokenizationService;
    public readonly valu: ValuService;
    public readonly videos: VideosService;
    public readonly wardrobe: WardrobeService;
    public readonly wishlist: WishlistService;

    public readonly request: BaseHttpRequest;

    constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = FetchHttpRequest) {
        this.request = new HttpRequest({
            BASE: config?.BASE ?? '',
            VERSION: config?.VERSION ?? '0.1.0',
            WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
            CREDENTIALS: config?.CREDENTIALS ?? 'include',
            TOKEN: config?.TOKEN,
            USERNAME: config?.USERNAME,
            PASSWORD: config?.PASSWORD,
            HEADERS: config?.HEADERS,
            ENCODE_PATH: config?.ENCODE_PATH,
        });

        this.abTest = new AbTestService(this.request);
        this.account = new AccountService(this.request);
        this.adcb = new AdcbService(this.request);
        this.admon = new AdmonService(this.request);
        this.adyen = new AdyenService(this.request);
        this.aftersales = new AftersalesService(this.request);
        this.auth = new AuthService(this.request);
        this.brand = new BrandService(this.request);
        this.cardtokens = new CardtokensService(this.request);
        this.cardtokensV2 = new CardtokensV2Service(this.request);
        this.carousels = new CarouselsService(this.request);
        this.checkout = new CheckoutService(this.request);
        this.cms = new CmsService(this.request);
        this.cmsMigration = new CmsMigrationService(this.request);
        this.config = new ConfigService(this.request);
        this.creators = new CreatorsService(this.request);
        this.currency = new CurrencyService(this.request);
        this.customer = new CustomerService(this.request);
        this.customerMigration = new CustomerMigrationService(this.request);
        this.customerOverlay = new CustomerOverlayService(this.request);
        this.deliveryZone = new DeliveryZoneService(this.request);
        this.exchanges = new ExchangesService(this.request);
        this.extras = new ExtrasService(this.request);
        this.geo = new GeoService(this.request);
        this.giftcards = new GiftcardsService(this.request);
        this.mashreq = new MashreqService(this.request);
        this.namshiAuth = new NamshiAuthService(this.request);
        this.needhelp = new NeedhelpService(this.request);
        this.noonAuth = new NoonAuthService(this.request);
        this.order = new OrderService(this.request);
        this.page = new PageService(this.request);
        this.paymentintent = new PaymentintentService(this.request);
        this.paymentProvider = new PaymentProviderService(this.request);
        this.preferences = new PreferencesService(this.request);
        this.product = new ProductService(this.request);
        this.productReview = new ProductReviewService(this.request);
        this.proxy = new ProxyService(this.request);
        this.recentCarousels = new RecentCarouselsService(this.request);
        this.returns = new ReturnsService(this.request);
        this.reviews = new ReviewsService(this.request);
        this.session = new SessionService(this.request);
        this.shopTheLook = new ShopTheLookService(this.request);
        this.sizingGuide = new SizingGuideService(this.request);
        this.sponsored = new SponsoredService(this.request);
        this.subscription = new SubscriptionService(this.request);
        this.system = new SystemService(this.request);
        this.token = new TokenService(this.request);
        this.tokenization = new TokenizationService(this.request);
        this.valu = new ValuService(this.request);
        this.videos = new VideosService(this.request);
        this.wardrobe = new WardrobeService(this.request);
        this.wishlist = new WishlistService(this.request);
        this.sponsored = new SponsoredService(this.request);
        this.customerMigration = new CustomerMigrationService(this.request);
        this.product = new ProductService(this.request);
        this.recentCarousels = new RecentCarouselsService(this.request);
        this.customerOverlay = new CustomerOverlayService(this.request);
    }
}

/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetNeedHelpListRequest } from '../models/GetNeedHelpListRequest';
import type { GetNeedHelpListResponse } from '../models/GetNeedHelpListResponse';
import type { NeedHelpResponse } from '../models/NeedHelpResponse';
import type { WasThisHelpfulRequest } from '../models/WasThisHelpfulRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class NeedhelpService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Need help
     * @returns NeedHelpResponse Successful Response
     * @throws ApiError
     */
    public needHelp({
        redirectionLink,
        xVisitorId,
        xAddressKey,
        xAddresskey,
        xLocale,
        xForwardedUser,
        xPlatform,
    }: {
        redirectionLink: string,
        /**
         * Visitor ID
         */
        xVisitorId?: any,
        /**
         * Address Key
         */
        xAddressKey?: any,
        /**
         * Old Address Key
         */
        xAddresskey?: any,
        /**
         * Locale
         */
        xLocale?: any,
        /**
         * Customer Code
         */
        xForwardedUser?: any,
        /**
         * Platform
         */
        xPlatform?: any,
    }): CancelablePromise<NeedHelpResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/_svc/order/{redirectionLink}',
            path: {
                'redirectionLink': redirectionLink,
            },
            headers: {
                'X-Visitor-Id': xVisitorId,
                'X-Address-Key': xAddressKey,
                'X-Addresskey': xAddresskey,
                'X-Locale': xLocale,
                'X-Forwarded-User': xForwardedUser,
                'X-Platform': xPlatform,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * List of ITEMS/ORDERS/SHIPMENTS/RETURNS/EXCHANGES
     * @returns GetNeedHelpListResponse Successful Response
     * @throws ApiError
     */
    public listNeedHelp({
        requestBody,
        xVisitorId,
        xAddressKey,
        xAddresskey,
        xLocale,
        xForwardedUser,
        xPlatform,
    }: {
        requestBody: GetNeedHelpListRequest,
        /**
         * Visitor ID
         */
        xVisitorId?: any,
        /**
         * Address Key
         */
        xAddressKey?: any,
        /**
         * Old Address Key
         */
        xAddresskey?: any,
        /**
         * Locale
         */
        xLocale?: any,
        /**
         * Customer Code
         */
        xForwardedUser?: any,
        /**
         * Platform
         */
        xPlatform?: any,
    }): CancelablePromise<GetNeedHelpListResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/_svc/order/needhelp/list',
            headers: {
                'X-Visitor-Id': xVisitorId,
                'X-Address-Key': xAddressKey,
                'X-Addresskey': xAddresskey,
                'X-Locale': xLocale,
                'X-Forwarded-User': xForwardedUser,
                'X-Platform': xPlatform,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Need help Enrichment Payload
     * @returns any Successful Response
     * @throws ApiError
     */
    public needHelpEnrichmentPayload({
        enrichmentType,
        identifier,
        xVisitorId,
        xAddressKey,
        xAddresskey,
        xLocale,
        xForwardedUser,
        xPlatform,
    }: {
        enrichmentType: string,
        identifier: string,
        /**
         * Visitor ID
         */
        xVisitorId?: any,
        /**
         * Address Key
         */
        xAddressKey?: any,
        /**
         * Old Address Key
         */
        xAddresskey?: any,
        /**
         * Locale
         */
        xLocale?: any,
        /**
         * Customer Code
         */
        xForwardedUser?: any,
        /**
         * Platform
         */
        xPlatform?: any,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/_svc/order/needhelp/get-payload/{enrichment_type}/{identifier}',
            path: {
                'enrichment_type': enrichmentType,
                'identifier': identifier,
            },
            headers: {
                'X-Visitor-Id': xVisitorId,
                'X-Address-Key': xAddressKey,
                'X-Addresskey': xAddresskey,
                'X-Locale': xLocale,
                'X-Forwarded-User': xForwardedUser,
                'X-Platform': xPlatform,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Submit Answer for was this helpful
     * @returns string Successful Response
     * @throws ApiError
     */
    public wasThisHelpful({
        requestBody,
        xVisitorId,
        xAddressKey,
        xAddresskey,
        xLocale,
        xForwardedUser,
        xPlatform,
    }: {
        requestBody: WasThisHelpfulRequest,
        /**
         * Visitor ID
         */
        xVisitorId?: any,
        /**
         * Address Key
         */
        xAddressKey?: any,
        /**
         * Old Address Key
         */
        xAddresskey?: any,
        /**
         * Locale
         */
        xLocale?: any,
        /**
         * Customer Code
         */
        xForwardedUser?: any,
        /**
         * Platform
         */
        xPlatform?: any,
    }): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/_svc/order/needhelp/was_this_helpful',
            headers: {
                'X-Visitor-Id': xVisitorId,
                'X-Address-Key': xAddressKey,
                'X-Addresskey': xAddresskey,
                'X-Locale': xLocale,
                'X-Forwarded-User': xForwardedUser,
                'X-Platform': xPlatform,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}

/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SizingGuideResponse } from '../models/SizingGuideResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class SizingGuideService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get sizing guide by sizing code
     * @returns SizingGuideResponse Successful Response
     * @throws ApiError
     */
    public getSizingGuideBySizingCode({
        sizingGuideCode,
        xContent,
        xPlatform,
        xLocale,
        xMp,
        accept = 'text/html',
    }: {
        sizingGuideCode: string,
        xContent?: string,
        xPlatform?: string,
        xLocale?: string,
        xMp?: string,
        accept?: string,
    }): CancelablePromise<SizingGuideResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/_svc/sizing-guide/v1/{sizingGuideCode}',
            path: {
                'sizingGuideCode': sizingGuideCode,
            },
            headers: {
                'X-Content': xContent,
                'X-Platform': xPlatform,
                'X-Locale': xLocale,
                'X-Mp': xMp,
                'Accept': accept,
            },
        });
    }

    /**
     * Get sizing guide by sku
     * @returns SizingGuideResponse Successful Response
     * @throws ApiError
     */
    public getSizingGuideBySku({
        sku,
        xContent,
        xPlatform,
        xLocale,
        xMp,
        accept = 'text/html',
    }: {
        sku: string,
        xContent?: string,
        xPlatform?: string,
        xLocale?: string,
        xMp?: string,
        accept?: string,
    }): CancelablePromise<SizingGuideResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/_svc/sizing-guide/{sku}',
            path: {
                'sku': sku,
            },
            headers: {
                'X-Content': xContent,
                'X-Platform': xPlatform,
                'X-Locale': xLocale,
                'X-Mp': xMp,
                'Accept': accept,
            },
        });
    }

}

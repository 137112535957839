import { create } from 'zustand';

import { CustomerAddressesResponse, ApiError, CustomerDataResponse } from '@nm-namshi-frontend/services';
import { getApiInstance } from '@nm-namshi-frontend/core/api';
import { getCookie, setCookie } from '@nm-namshi-frontend/core/utils/helper';
import { COOKIE_ID } from '@nm-namshi-frontend/core/constants/uiConstants';

interface ICustomerStore {
    selectedAddressKey: string;
    setSelectedAddressKey: (selectedAddressKey: string) => void;

    customerAddressData: CustomerAddressesResponse | null;
    isLoadingCustomerAddressData: boolean;
    errorCustomerAddressData: ApiError | null;
    fetchCustomerAddressData: () => Promise<void>;

    customerData: CustomerDataResponse | null;
    isLoadingCustomerData: boolean;
    errorCustomerData: ApiError | null;
    fetchCustomerData: () => Promise<void>;

    setDefaultAddress: ({
        onError,
        onSuccess,
        requestBody,
    }: {
        requestBody: { addressId: string };
        onSuccess?: () => void;
        onError?: (error: ApiError) => void;
    }) => Promise<void>;
    deleteCustomerAddress: ({
        onError,
        requestBody,
    }: {
        requestBody: { addressId: string };
        onError?: (error: ApiError) => void;
    }) => Promise<void>;
}

const useCustomerStore = create<ICustomerStore>((set, get) => ({
    selectedAddressKey: getCookie(COOKIE_ID.X_ADDRESS_KEY) || '',
    setSelectedAddressKey: (selectedAddressKey: string) => {
        set({ selectedAddressKey });
        setCookie(COOKIE_ID.X_ADDRESS_KEY, selectedAddressKey);
    },

    customerAddressData: null,
    isLoadingCustomerAddressData: false,
    errorCustomerAddressData: null,
    fetchCustomerAddressData: async () => {
        set({ isLoadingCustomerAddressData: true });
        try {
            const customerAddressData = await getApiInstance().customer.getCustomerAddresses({});
            set({
                selectedAddressKey: customerAddressData.selected.addressKey,
                customerAddressData,
                isLoadingCustomerAddressData: false,
                errorCustomerAddressData: null,
            });
        } catch (error) {
            set({
                selectedAddressKey: '',
                customerAddressData: null,
                isLoadingCustomerAddressData: false,
                errorCustomerAddressData: error as unknown as ApiError,
            });
        }
    },

    isLoadingCustomerData: true,
    errorCustomerData: null,
    customerData: null,
    fetchCustomerData: async () => {
        set({ isLoadingCustomerData: true });
        try {
            const customerData = await getApiInstance().customer.getCustomer({});
            set({
                customerData,
                isLoadingCustomerData: false,
                errorCustomerData: null,
            });
        } catch (error) {
            set({
                customerData: null,
                isLoadingCustomerData: false,
                errorCustomerData: error as unknown as ApiError,
            });
        }
    },

    setDefaultAddress: async ({ onError, onSuccess, requestBody }) => {
        set({ isLoadingCustomerAddressData: true });
        try {
            const customerAddressData = await getApiInstance().customer.setDefaultAddress(requestBody);
            onSuccess?.();
            set({
                customerAddressData,
                isLoadingCustomerAddressData: false,
                errorCustomerAddressData: null,
            });
        } catch (error) {
            onError?.(error as unknown as ApiError);
            set({
                customerAddressData: null,
                isLoadingCustomerAddressData: false,
                errorCustomerAddressData: error as unknown as ApiError,
            });
        }
    },
    deleteCustomerAddress: async ({ onError, requestBody }) => {
        set({ isLoadingCustomerAddressData: true });
        try {
            const customerAddressData = await getApiInstance().customer.deleteCustomerAddress(requestBody);
            set({
                customerAddressData,
                isLoadingCustomerAddressData: false,
                errorCustomerAddressData: null,
            });
        } catch (error) {
            onError?.(error as unknown as ApiError);
            set({
                customerAddressData: null,
                isLoadingCustomerAddressData: false,
                errorCustomerAddressData: error as unknown as ApiError,
            });
        }
    },
}));

export default useCustomerStore;

/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateReturnRequest } from '../models/CreateReturnRequest';
import type { CreateReturnResponse } from '../models/CreateReturnResponse';
import type { ListRequest } from '../models/ListRequest';
import type { ListReturnResponse } from '../models/ListReturnResponse';
import type { UpdateReturnAddressRequest } from '../models/UpdateReturnAddressRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ReturnsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Create return
     * @returns CreateReturnResponse Successful Response
     * @throws ApiError
     */
    public createReturn({
        requestBody,
        xVisitorId,
        xAddressKey,
        xAddresskey,
        xLocale,
        xForwardedUser,
        xPlatform,
    }: {
        requestBody: CreateReturnRequest,
        /**
         * Visitor ID
         */
        xVisitorId?: any,
        /**
         * Address Key
         */
        xAddressKey?: any,
        /**
         * Old Address Key
         */
        xAddresskey?: any,
        /**
         * Locale
         */
        xLocale?: any,
        /**
         * Customer Code
         */
        xForwardedUser?: any,
        /**
         * Platform
         */
        xPlatform?: any,
    }): CancelablePromise<CreateReturnResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/_svc/order/aftersales/return/create',
            headers: {
                'X-Visitor-Id': xVisitorId,
                'X-Address-Key': xAddressKey,
                'X-Addresskey': xAddresskey,
                'X-Locale': xLocale,
                'X-Forwarded-User': xForwardedUser,
                'X-Platform': xPlatform,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get all requested returns
     * @returns ListReturnResponse Successful Response
     * @throws ApiError
     */
    public listReturn({
        requestBody,
        xVisitorId,
        xAddressKey,
        xAddresskey,
        xLocale,
        xForwardedUser,
        xPlatform,
    }: {
        requestBody: ListRequest,
        /**
         * Visitor ID
         */
        xVisitorId?: any,
        /**
         * Address Key
         */
        xAddressKey?: any,
        /**
         * Old Address Key
         */
        xAddresskey?: any,
        /**
         * Locale
         */
        xLocale?: any,
        /**
         * Customer Code
         */
        xForwardedUser?: any,
        /**
         * Platform
         */
        xPlatform?: any,
    }): CancelablePromise<ListReturnResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/_svc/order/aftersales/return/list',
            headers: {
                'X-Visitor-Id': xVisitorId,
                'X-Address-Key': xAddressKey,
                'X-Addresskey': xAddresskey,
                'X-Locale': xLocale,
                'X-Forwarded-User': xForwardedUser,
                'X-Platform': xPlatform,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update return pickup address
     * @returns any Successful Response
     * @throws ApiError
     */
    public updateReturn({
        requestBody,
        xVisitorId,
        xAddressKey,
        xAddresskey,
        xLocale,
        xForwardedUser,
        xPlatform,
    }: {
        requestBody: UpdateReturnAddressRequest,
        /**
         * Visitor ID
         */
        xVisitorId?: any,
        /**
         * Address Key
         */
        xAddressKey?: any,
        /**
         * Old Address Key
         */
        xAddresskey?: any,
        /**
         * Locale
         */
        xLocale?: any,
        /**
         * Customer Code
         */
        xForwardedUser?: any,
        /**
         * Platform
         */
        xPlatform?: any,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/_svc/order/aftersales/return/update_address',
            headers: {
                'X-Visitor-Id': xVisitorId,
                'X-Address-Key': xAddressKey,
                'X-Addresskey': xAddresskey,
                'X-Locale': xLocale,
                'X-Forwarded-User': xForwardedUser,
                'X-Platform': xPlatform,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}

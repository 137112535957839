/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelReturnRequest } from '../models/CancelReturnRequest';
import type { ListReturnEligibleItemsRequest } from '../models/ListReturnEligibleItemsRequest';
import type { ListReturnEligibleItemsResponse } from '../models/ListReturnEligibleItemsResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AftersalesService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get return/exchange eligible items
     * @returns ListReturnEligibleItemsResponse Successful Response
     * @throws ApiError
     */
    public listEligibleItems({
        requestBody,
        xVisitorId,
        xAddressKey,
        xAddresskey,
        xLocale,
        xForwardedUser,
        xPlatform,
    }: {
        requestBody: ListReturnEligibleItemsRequest,
        /**
         * Visitor ID
         */
        xVisitorId?: any,
        /**
         * Address Key
         */
        xAddressKey?: any,
        /**
         * Old Address Key
         */
        xAddresskey?: any,
        /**
         * Locale
         */
        xLocale?: any,
        /**
         * Customer Code
         */
        xForwardedUser?: any,
        /**
         * Platform
         */
        xPlatform?: any,
    }): CancelablePromise<ListReturnEligibleItemsResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/_svc/order/aftersales/eligible-items',
            headers: {
                'X-Visitor-Id': xVisitorId,
                'X-Address-Key': xAddressKey,
                'X-Addresskey': xAddresskey,
                'X-Locale': xLocale,
                'X-Forwarded-User': xForwardedUser,
                'X-Platform': xPlatform,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Cancel return/exchange
     * @returns string Successful Response
     * @throws ApiError
     */
    public cancel({
        requestBody,
        xVisitorId,
        xAddressKey,
        xAddresskey,
        xLocale,
        xForwardedUser,
        xPlatform,
    }: {
        requestBody: CancelReturnRequest,
        /**
         * Visitor ID
         */
        xVisitorId?: any,
        /**
         * Address Key
         */
        xAddressKey?: any,
        /**
         * Old Address Key
         */
        xAddresskey?: any,
        /**
         * Locale
         */
        xLocale?: any,
        /**
         * Customer Code
         */
        xForwardedUser?: any,
        /**
         * Platform
         */
        xPlatform?: any,
    }): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/_svc/order/aftersales/cancel',
            headers: {
                'X-Visitor-Id': xVisitorId,
                'X-Address-Key': xAddressKey,
                'X-Addresskey': xAddresskey,
                'X-Locale': xLocale,
                'X-Forwarded-User': xForwardedUser,
                'X-Platform': xPlatform,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}

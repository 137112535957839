/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { RedeemPaymentsGiftCardPayload } from '../models/RedeemPaymentsGiftCardPayload';
import type { RedeemPaymentsGiftCardResponse } from '../models/RedeemPaymentsGiftCardResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class GiftcardsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Redeem a gift card from credits screen
     * @returns RedeemPaymentsGiftCardResponse Successful Response
     * @throws ApiError
     */
    public redeemPaymentsGiftCard({
        requestBody,
        xMp = 'namshi_v2',
    }: {
        requestBody: RedeemPaymentsGiftCardPayload,
        xMp?: string,
    }): CancelablePromise<RedeemPaymentsGiftCardResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/_svc/giftcards/v1/redeem',
            headers: {
                'x-mp': xMp,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                422: `Unprocessable Entity`,
                500: `Internal Server Error`,
            },
        });
    }

}

import { BASE_HEADERS } from '@nm-namshi-frontend/core/config';
import { ClientApp, TLocaleAPI } from '@nm-namshi-frontend/core/types';
import { OpenAPIConfig } from '@nm-namshi-frontend/services';
import { AppClient } from '@nm-namshi-frontend/services/AppClient';

import { COOKIE_ID } from './constants/uiConstants';
import { getCookie } from './utils/helper';

// Adding this in as a fix for the default OpenAPI config within services/core/OpenAPI.ts being malformed
export const OpenAPIConfigOverride: OpenAPIConfig = {
    BASE: '',
    VERSION: '0.1.0',
    WITH_CREDENTIALS: false,
    CREDENTIALS: 'include',
    TOKEN: undefined,
    USERNAME: undefined,
    PASSWORD: undefined,
    HEADERS: undefined,
    ENCODE_PATH: undefined,
};

let api: AppClient;

export const initiateApiInstance = (config: Partial<OpenAPIConfig> = OpenAPIConfigOverride): void => {
    api = new AppClient(config);
};

type TInitApiInstance = {
    app: ClientApp;
    locale: TLocaleAPI;
    customHeaders?: Partial<OpenAPIConfig>['HEADERS'];
    baseUrl?: string;
};

export const initApiInstance = ({ app, baseUrl, customHeaders = {}, locale }: TInitApiInstance) => {
    let xContent = '';

    if (app === ClientApp.BIGALOG) {
        xContent = 'desktop';
    }
    if (app === ClientApp.SCHMATALOG || app === ClientApp.APP) {
        xContent = 'mobile';
    }

    const [lang, country] = locale?.split('-') || ['en', 'ae'];

    const config: Partial<OpenAPIConfig> = {
        ...(!!baseUrl && { BASE: baseUrl }),
        HEADERS: {
            ...BASE_HEADERS,
            'x-locale': locale,
            'x-content': xContent,
            'N-Locale': `${lang}_${country}`,
            [COOKIE_ID.LAT as string]: getCookie(COOKIE_ID.LAT),
            [COOKIE_ID.LNG as string]: getCookie(COOKIE_ID.LNG),
            [COOKIE_ID.LOC_ID as string]: getCookie(COOKIE_ID.LOC_ID),
            [COOKIE_ID.DELIVERY_ZONE_CODE as string]: getCookie(COOKIE_ID.DELIVERY_ZONE_CODE),
            ...customHeaders,
        },
    };

    api = new AppClient(config);
};

export const getApiInstance = (config: Partial<OpenAPIConfig> = OpenAPIConfigOverride): AppClient => {
    if (!api) {
        // In case of SSR, we will need to instantiate
        if (config) {
            initiateApiInstance(config);
        } else {
            // // @TODO Decide whether to throw error or just return if api instance is not initiated
            throw new Error('API instance not initiated');
        }
    }
    return api;
};

/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateTokenPayload } from '../models/CreateTokenPayload';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AdyenService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Tokenize Adyen Card
     * @returns any Successful Response
     * @throws ApiError
     */
    public tokenizeAdyenCardAdyenTokenizePost({
        requestBody,
        xMp,
    }: {
        requestBody: CreateTokenPayload,
        /**
         * Marketplace code (default is noon)
         */
        xMp?: any,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/_svc/payment/adyen/tokenize',
            headers: {
                'x-mp': xMp,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Store Adyen Card
     * @returns any Successful Response
     * @throws ApiError
     */
    public storeAdyenCardAdyenStorePaymentMethodsPost({
        requestBody,
        xMp,
    }: {
        requestBody: CreateTokenPayload,
        /**
         * Marketplace code (default is noon)
         */
        xMp?: any,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/_svc/payment/adyen/store-payment-methods',
            headers: {
                'x-mp': xMp,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Store Adyen Card
     * @returns any Successful Response
     * @throws ApiError
     */
    public storeAdyenCardAdyenGetStoredPaymentMethodsGet({
        xMp,
    }: {
        /**
         * Marketplace code (default is noon)
         */
        xMp?: any,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/_svc/payment/adyen/get-stored-payment-methods',
            headers: {
                'x-mp': xMp,
            },
        });
    }

}

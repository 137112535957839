/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DeletedResponse } from '../models/DeletedResponse';
import type { DeleteMediaRequest } from '../models/DeleteMediaRequest';
import type { GetNonReviewedOrderProductListResponse } from '../models/GetNonReviewedOrderProductListResponse';
import type { GetNonReviewedProductListResponse } from '../models/GetNonReviewedProductListResponse';
import type { GetProductReviewListRequest } from '../models/GetProductReviewListRequest';
import type { GetProductReviewListResponse } from '../models/GetProductReviewListResponse';
import type { GetProductReviewResponse } from '../models/GetProductReviewResponse';
import type { GetReviewedOrderProductListResponse } from '../models/GetReviewedOrderProductListResponse';
import type { GetReviewedProductListResponse } from '../models/GetReviewedProductListResponse';
import type { GetSignedUrlRequest } from '../models/GetSignedUrlRequest';
import type { GetSignedUrlResponse } from '../models/GetSignedUrlResponse';
import type { ListRequest } from '../models/ListRequest';
import type { ProductReviewCreateRequest } from '../models/ProductReviewCreateRequest';
import type { ProductReviewSummaryResponse } from '../models/ProductReviewSummaryResponse';
import type { ProductReviewUpdateRequest } from '../models/ProductReviewUpdateRequest';
import type { UploadConfirmedRequest } from '../models/UploadConfirmedRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ProductReviewService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Create a product review
     * @returns string Successful Response
     * @throws ApiError
     */
    public createProductReview({
        requestBody,
        xVisitorId,
        xAddressKey,
        xAddresskey,
        xLocale,
        xForwardedUser,
        xPlatform,
    }: {
        requestBody: ProductReviewCreateRequest,
        /**
         * Visitor ID
         */
        xVisitorId?: any,
        /**
         * Address Key
         */
        xAddressKey?: any,
        /**
         * Old Address Key
         */
        xAddresskey?: any,
        /**
         * Locale
         */
        xLocale?: any,
        /**
         * Customer Code
         */
        xForwardedUser?: any,
        /**
         * Platform
         */
        xPlatform?: any,
    }): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/_svc/review/product-review/create',
            headers: {
                'X-Visitor-Id': xVisitorId,
                'X-Address-Key': xAddressKey,
                'X-Addresskey': xAddresskey,
                'X-Locale': xLocale,
                'X-Forwarded-User': xForwardedUser,
                'X-Platform': xPlatform,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update a product review
     * @returns string Successful Response
     * @throws ApiError
     */
    public updateProductReview({
        requestBody,
        xVisitorId,
        xAddressKey,
        xAddresskey,
        xLocale,
        xForwardedUser,
        xPlatform,
    }: {
        requestBody: ProductReviewUpdateRequest,
        /**
         * Visitor ID
         */
        xVisitorId?: any,
        /**
         * Address Key
         */
        xAddressKey?: any,
        /**
         * Old Address Key
         */
        xAddresskey?: any,
        /**
         * Locale
         */
        xLocale?: any,
        /**
         * Customer Code
         */
        xForwardedUser?: any,
        /**
         * Platform
         */
        xPlatform?: any,
    }): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/_svc/review/product-review/update',
            headers: {
                'X-Visitor-Id': xVisitorId,
                'X-Address-Key': xAddressKey,
                'X-Addresskey': xAddresskey,
                'X-Locale': xLocale,
                'X-Forwarded-User': xForwardedUser,
                'X-Platform': xPlatform,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get signed url
     * @returns GetSignedUrlResponse Successful Response
     * @throws ApiError
     */
    public getSignedUrl({
        requestBody,
        xVisitorId,
        xAddressKey,
        xAddresskey,
        xLocale,
        xForwardedUser,
        xPlatform,
    }: {
        requestBody: GetSignedUrlRequest,
        /**
         * Visitor ID
         */
        xVisitorId?: any,
        /**
         * Address Key
         */
        xAddressKey?: any,
        /**
         * Old Address Key
         */
        xAddresskey?: any,
        /**
         * Locale
         */
        xLocale?: any,
        /**
         * Customer Code
         */
        xForwardedUser?: any,
        /**
         * Platform
         */
        xPlatform?: any,
    }): CancelablePromise<GetSignedUrlResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/_svc/review/product-review/get-signed-url',
            headers: {
                'X-Visitor-Id': xVisitorId,
                'X-Address-Key': xAddressKey,
                'X-Addresskey': xAddresskey,
                'X-Locale': xLocale,
                'X-Forwarded-User': xForwardedUser,
                'X-Platform': xPlatform,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Upload confirmed media
     * @returns any Successful Response
     * @throws ApiError
     */
    public uploadConfirmedMedia({
        requestBody,
        xVisitorId,
        xAddressKey,
        xAddresskey,
        xLocale,
        xForwardedUser,
        xPlatform,
    }: {
        requestBody: UploadConfirmedRequest,
        /**
         * Visitor ID
         */
        xVisitorId?: any,
        /**
         * Address Key
         */
        xAddressKey?: any,
        /**
         * Old Address Key
         */
        xAddresskey?: any,
        /**
         * Locale
         */
        xLocale?: any,
        /**
         * Customer Code
         */
        xForwardedUser?: any,
        /**
         * Platform
         */
        xPlatform?: any,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/_svc/review/product-review/upload-confirmed',
            headers: {
                'X-Visitor-Id': xVisitorId,
                'X-Address-Key': xAddressKey,
                'X-Addresskey': xAddresskey,
                'X-Locale': xLocale,
                'X-Forwarded-User': xForwardedUser,
                'X-Platform': xPlatform,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get a product review by review code
     * @returns GetProductReviewResponse Successful Response
     * @throws ApiError
     */
    public getProductReview({
        reviewCode,
        xVisitorId,
        xAddressKey,
        xAddresskey,
        xLocale,
        xForwardedUser,
        xPlatform,
    }: {
        reviewCode: string,
        /**
         * Visitor ID
         */
        xVisitorId?: any,
        /**
         * Address Key
         */
        xAddressKey?: any,
        /**
         * Old Address Key
         */
        xAddresskey?: any,
        /**
         * Locale
         */
        xLocale?: any,
        /**
         * Customer Code
         */
        xForwardedUser?: any,
        /**
         * Platform
         */
        xPlatform?: any,
    }): CancelablePromise<GetProductReviewResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/_svc/review/product-review/get/{review_code}',
            path: {
                'review_code': reviewCode,
            },
            headers: {
                'X-Visitor-Id': xVisitorId,
                'X-Address-Key': xAddressKey,
                'X-Addresskey': xAddresskey,
                'X-Locale': xLocale,
                'X-Forwarded-User': xForwardedUser,
                'X-Platform': xPlatform,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete review media
     * @returns DeletedResponse Successful Response
     * @throws ApiError
     */
    public deleteMedia({
        requestBody,
        xVisitorId,
        xAddressKey,
        xAddresskey,
        xLocale,
        xForwardedUser,
        xPlatform,
    }: {
        requestBody: DeleteMediaRequest,
        /**
         * Visitor ID
         */
        xVisitorId?: any,
        /**
         * Address Key
         */
        xAddressKey?: any,
        /**
         * Old Address Key
         */
        xAddresskey?: any,
        /**
         * Locale
         */
        xLocale?: any,
        /**
         * Customer Code
         */
        xForwardedUser?: any,
        /**
         * Platform
         */
        xPlatform?: any,
    }): CancelablePromise<DeletedResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/_svc/review/product-review/media/delete',
            headers: {
                'X-Visitor-Id': xVisitorId,
                'X-Address-Key': xAddressKey,
                'X-Addresskey': xAddresskey,
                'X-Locale': xLocale,
                'X-Forwarded-User': xForwardedUser,
                'X-Platform': xPlatform,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete product review
     * @returns DeletedResponse Successful Response
     * @throws ApiError
     */
    public deleteReview({
        reviewCode,
        xVisitorId,
        xAddressKey,
        xAddresskey,
        xLocale,
        xForwardedUser,
        xPlatform,
    }: {
        reviewCode: string,
        /**
         * Visitor ID
         */
        xVisitorId?: any,
        /**
         * Address Key
         */
        xAddressKey?: any,
        /**
         * Old Address Key
         */
        xAddresskey?: any,
        /**
         * Locale
         */
        xLocale?: any,
        /**
         * Customer Code
         */
        xForwardedUser?: any,
        /**
         * Platform
         */
        xPlatform?: any,
    }): CancelablePromise<DeletedResponse> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/_svc/review/product-review/delete/{review_code}',
            path: {
                'review_code': reviewCode,
            },
            headers: {
                'X-Visitor-Id': xVisitorId,
                'X-Address-Key': xAddressKey,
                'X-Addresskey': xAddresskey,
                'X-Locale': xLocale,
                'X-Forwarded-User': xForwardedUser,
                'X-Platform': xPlatform,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get a product review summary
     * @returns ProductReviewSummaryResponse Successful Response
     * @throws ApiError
     */
    public getProductReviewSummary({
        parentSku,
        xVisitorId,
        xAddressKey,
        xAddresskey,
        xLocale,
        xForwardedUser,
        xPlatform,
    }: {
        parentSku: string,
        /**
         * Visitor ID
         */
        xVisitorId?: any,
        /**
         * Address Key
         */
        xAddressKey?: any,
        /**
         * Old Address Key
         */
        xAddresskey?: any,
        /**
         * Locale
         */
        xLocale?: any,
        /**
         * Customer Code
         */
        xForwardedUser?: any,
        /**
         * Platform
         */
        xPlatform?: any,
    }): CancelablePromise<ProductReviewSummaryResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/_svc/review/product-review/summary/{parent_sku}',
            path: {
                'parent_sku': parentSku,
            },
            headers: {
                'X-Visitor-Id': xVisitorId,
                'X-Address-Key': xAddressKey,
                'X-Addresskey': xAddresskey,
                'X-Locale': xLocale,
                'X-Forwarded-User': xForwardedUser,
                'X-Platform': xPlatform,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get list of product reviews
     * @returns GetProductReviewListResponse Successful Response
     * @throws ApiError
     */
    public getProductReviewList({
        requestBody,
        xVisitorId,
        xAddressKey,
        xAddresskey,
        xLocale,
        xForwardedUser,
        xPlatform,
    }: {
        requestBody: GetProductReviewListRequest,
        /**
         * Visitor ID
         */
        xVisitorId?: any,
        /**
         * Address Key
         */
        xAddressKey?: any,
        /**
         * Old Address Key
         */
        xAddresskey?: any,
        /**
         * Locale
         */
        xLocale?: any,
        /**
         * Customer Code
         */
        xForwardedUser?: any,
        /**
         * Platform
         */
        xPlatform?: any,
    }): CancelablePromise<GetProductReviewListResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/_svc/review/product-review/list',
            headers: {
                'X-Visitor-Id': xVisitorId,
                'X-Address-Key': xAddressKey,
                'X-Addresskey': xAddresskey,
                'X-Locale': xLocale,
                'X-Forwarded-User': xForwardedUser,
                'X-Platform': xPlatform,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get non reviewed customer items list
     * @returns GetNonReviewedProductListResponse Successful Response
     * @throws ApiError
     */
    public getEligibleProductReviewList({
        requestBody,
        xVisitorId,
        xAddressKey,
        xAddresskey,
        xLocale,
        xForwardedUser,
        xPlatform,
    }: {
        requestBody: ListRequest,
        /**
         * Visitor ID
         */
        xVisitorId?: any,
        /**
         * Address Key
         */
        xAddressKey?: any,
        /**
         * Old Address Key
         */
        xAddresskey?: any,
        /**
         * Locale
         */
        xLocale?: any,
        /**
         * Customer Code
         */
        xForwardedUser?: any,
        /**
         * Platform
         */
        xPlatform?: any,
    }): CancelablePromise<GetNonReviewedProductListResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/_svc/review/product-review/list/eligible-products',
            headers: {
                'X-Visitor-Id': xVisitorId,
                'X-Address-Key': xAddressKey,
                'X-Addresskey': xAddresskey,
                'X-Locale': xLocale,
                'X-Forwarded-User': xForwardedUser,
                'X-Platform': xPlatform,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get reviewed customer items list
     * @returns GetReviewedProductListResponse Successful Response
     * @throws ApiError
     */
    public getReviewedProductReviewList({
        requestBody,
        xVisitorId,
        xAddressKey,
        xAddresskey,
        xLocale,
        xForwardedUser,
        xPlatform,
    }: {
        requestBody: ListRequest,
        /**
         * Visitor ID
         */
        xVisitorId?: any,
        /**
         * Address Key
         */
        xAddressKey?: any,
        /**
         * Old Address Key
         */
        xAddresskey?: any,
        /**
         * Locale
         */
        xLocale?: any,
        /**
         * Customer Code
         */
        xForwardedUser?: any,
        /**
         * Platform
         */
        xPlatform?: any,
    }): CancelablePromise<GetReviewedProductListResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/_svc/review/product-review/list/reviewed-products',
            headers: {
                'X-Visitor-Id': xVisitorId,
                'X-Address-Key': xAddressKey,
                'X-Addresskey': xAddresskey,
                'X-Locale': xLocale,
                'X-Forwarded-User': xForwardedUser,
                'X-Platform': xPlatform,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get reviewed customer order items list
     * @returns GetReviewedOrderProductListResponse Successful Response
     * @throws ApiError
     */
    public getReviewedOrderProductList({
        orderNr,
        xVisitorId,
        xAddressKey,
        xAddresskey,
        xLocale,
        xForwardedUser,
        xPlatform,
    }: {
        orderNr: string,
        /**
         * Visitor ID
         */
        xVisitorId?: any,
        /**
         * Address Key
         */
        xAddressKey?: any,
        /**
         * Old Address Key
         */
        xAddresskey?: any,
        /**
         * Locale
         */
        xLocale?: any,
        /**
         * Customer Code
         */
        xForwardedUser?: any,
        /**
         * Platform
         */
        xPlatform?: any,
    }): CancelablePromise<GetReviewedOrderProductListResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/_svc/review/product-review/list/reviewed-products/{order_nr}',
            path: {
                'order_nr': orderNr,
            },
            headers: {
                'X-Visitor-Id': xVisitorId,
                'X-Address-Key': xAddressKey,
                'X-Addresskey': xAddresskey,
                'X-Locale': xLocale,
                'X-Forwarded-User': xForwardedUser,
                'X-Platform': xPlatform,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get order items eligible for review
     * @returns GetNonReviewedOrderProductListResponse Successful Response
     * @throws ApiError
     */
    public getReviewEligibleOrderProducts({
        orderNr,
        xVisitorId,
        xAddressKey,
        xAddresskey,
        xLocale,
        xForwardedUser,
        xPlatform,
    }: {
        orderNr: string,
        /**
         * Visitor ID
         */
        xVisitorId?: any,
        /**
         * Address Key
         */
        xAddressKey?: any,
        /**
         * Old Address Key
         */
        xAddresskey?: any,
        /**
         * Locale
         */
        xLocale?: any,
        /**
         * Customer Code
         */
        xForwardedUser?: any,
        /**
         * Platform
         */
        xPlatform?: any,
    }): CancelablePromise<GetNonReviewedOrderProductListResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/_svc/review/product-review/list/eligible-products/{order_nr}',
            path: {
                'order_nr': orderNr,
            },
            headers: {
                'X-Visitor-Id': xVisitorId,
                'X-Address-Key': xAddressKey,
                'X-Addresskey': xAddresskey,
                'X-Locale': xLocale,
                'X-Forwarded-User': xForwardedUser,
                'X-Platform': xPlatform,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }


    uploadReviewImage(file: File, signedUrl: string): Promise<any> {
        try {
          const response = fetch(signedUrl, {
            method: 'PUT',
            headers: {
              'Content-Type': '',
            },
            body: file,
          });

          return response;
        } catch (error) {
          console.error(error);
          throw error;
        }
      }
}

/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AccountCouponResponse } from '../models/AccountCouponResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AccountService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * list customer coupons
     * @returns AccountCouponResponse Successful Response
     * @throws ApiError
     */
    public listCustomerCoupons({
        xVisitorId,
        xAddressKey,
        xAddresskey,
        xLocale,
        xForwardedUser,
        xPlatform,
    }: {
        /**
         * Visitor ID
         */
        xVisitorId?: any,
        /**
         * Address Key
         */
        xAddressKey?: any,
        /**
         * Old Address Key
         */
        xAddresskey?: any,
        /**
         * Locale
         */
        xLocale?: any,
        /**
         * Customer Code
         */
        xForwardedUser?: any,
        /**
         * Platform
         */
        xPlatform?: any,
    }): CancelablePromise<Array<AccountCouponResponse>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/_svc/order/account/coupons',
            headers: {
                'X-Visitor-Id': xVisitorId,
                'X-Address-Key': xAddressKey,
                'X-Addresskey': xAddresskey,
                'X-Locale': xLocale,
                'X-Forwarded-User': xForwardedUser,
                'X-Platform': xPlatform,
            },
        });
    }

}

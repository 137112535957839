/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EncryptionTokensResponse } from '../models/EncryptionTokensResponse';
import type { SaveTokensRequest } from '../models/SaveTokensRequest';
import type { SaveTokensResponse } from '../models/SaveTokensResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class TokenService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get encryption tokens
     * @returns EncryptionTokensResponse Successful Response
     * @throws ApiError
     */
    public getEncryptionTokens({
        code,
        xLocale,
        xExperience = 'ecom',
        xMp,
        xFlag,
        xPlatform,
        xForwardedUser,
        xForwardedGuest,
    }: {
        code: string,
        /**
         * Locale eg en-ae
         */
        xLocale?: string,
        /**
         * The experience for this marketplace eg. ecom or food
         */
        xExperience?: string,
        /**
         * Marketplace code
         */
        xMp?: string,
        /**
         * Testing flag
         */
        xFlag?: string,
        /**
         * Platform eg web ios android
         */
        xPlatform?: string,
        /**
         * Customer UID
         */
        xForwardedUser?: string,
        /**
         * Customer UID
         */
        xForwardedGuest?: string,
    }): CancelablePromise<EncryptionTokensResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/_svc/customer-v1/customer/tokens/{code}',
            path: {
                'code': code,
            },
            headers: {
                'X-Locale': xLocale,
                'X-Experience': xExperience,
                'X-Mp': xMp,
                'X-Flag': xFlag,
                'X-Platform': xPlatform,
                'X-Forwarded-User': xForwardedUser,
                'X-Forwarded-Guest': xForwardedGuest,
            },
        });
    }

    /**
     * Save tokens
     * @returns SaveTokensResponse Successful Response
     * @throws ApiError
     */
    public saveCustomerTokens({
        requestBody,
        xLocale,
        xExperience = 'ecom',
        xMp,
        xFlag,
        xPlatform,
        xForwardedUser,
        xForwardedGuest,
    }: {
        requestBody: SaveTokensRequest,
        /**
         * Locale eg en-ae
         */
        xLocale?: string,
        /**
         * The experience for this marketplace eg. ecom or food
         */
        xExperience?: string,
        /**
         * Marketplace code
         */
        xMp?: string,
        /**
         * Testing flag
         */
        xFlag?: string,
        /**
         * Platform eg web ios android
         */
        xPlatform?: string,
        /**
         * Customer UID
         */
        xForwardedUser?: string,
        /**
         * Customer UID
         */
        xForwardedGuest?: string,
    }): CancelablePromise<SaveTokensResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/_svc/customer-v1/customer/tokens',
            headers: {
                'X-Locale': xLocale,
                'X-Experience': xExperience,
                'X-Mp': xMp,
                'X-Flag': xFlag,
                'X-Platform': xPlatform,
                'X-Forwarded-User': xForwardedUser,
                'X-Forwarded-Guest': xForwardedGuest,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}

/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelGenericRequest } from '../models/CancelGenericRequest';
import type { CancelItemsRequest } from '../models/CancelItemsRequest';
import type { CancelOrderPaymentResponse } from '../models/CancelOrderPaymentResponse';
import type { CancelOrderRequest } from '../models/CancelOrderRequest';
import type { CancelReason } from '../models/CancelReason';
import type { CancelShipmentRequest } from '../models/CancelShipmentRequest';
import type { GetCancellableItemsResponse } from '../models/GetCancellableItemsResponse';
import type { GetCancellableResponse } from '../models/GetCancellableResponse';
import type { GetOrderDetailsRequest } from '../models/GetOrderDetailsRequest';
import type { GetOrderDetailsResponse } from '../models/GetOrderDetailsResponse';
import type { GetOrderListRequest } from '../models/GetOrderListRequest';
import type { GetOrderListResponse } from '../models/GetOrderListResponse';
import type { HOMSCzentrixCallbackRequest } from '../models/HOMSCzentrixCallbackRequest';
import type { HOMSShipmentTrackingRequest } from '../models/HOMSShipmentTrackingRequest';
import type { HOMSShipmentTrackingResponse } from '../models/HOMSShipmentTrackingResponse';
import type { ImageProofRequest } from '../models/ImageProofRequest';
import type { ImageProofResponse } from '../models/ImageProofResponse';
import type { OrderPaymentSummaryResponse } from '../models/OrderPaymentSummaryResponse';
import type { PaymentInfoResponse } from '../models/PaymentInfoResponse';
import type { PlaceOrderRequest } from '../models/PlaceOrderRequest';
import type { PlaceOrderResponse } from '../models/PlaceOrderResponse';
import type { UpdateShippingAddressRequest } from '../models/UpdateShippingAddressRequest';
import type { VerifyAddressRequest } from '../models/VerifyAddressRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class OrderService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Place order based on active session
     * @returns PlaceOrderResponse Successful Response
     * @throws ApiError
     */
    public placeOrder({
        requestBody,
        xVisitorId,
        xAddressKey,
        xAddresskey,
        xLocale,
        xForwardedUser,
        xPlatform,
        xPage
    }: {
        requestBody: PlaceOrderRequest,
        /**
         * Visitor ID
         */
        xVisitorId?: any,
        /**
         * Address Key
         */
        xAddressKey?: any,
        /**
         * Old Address Key
         */
        xAddresskey?: any,
        /**
         * Locale
         */
        xLocale?: any,
        /**
         * Customer Code
         */
        xForwardedUser?: any,
        /**
         * Platform
         */
        xPlatform?: any,
        xPage: string
    }): CancelablePromise<PlaceOrderResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/_svc/order/order/place',
            headers: {
                'X-Visitor-Id': xVisitorId,
                'X-Address-Key': xAddressKey,
                'X-Addresskey': xAddresskey,
                'X-Locale': xLocale,
                'X-Forwarded-User': xForwardedUser,
                'X-Platform': xPlatform,
                'X-Page': xPage,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get a single order details
     * @returns GetOrderDetailsResponse Successful Response
     * @throws ApiError
     */
    public getOrderDetails({
        requestBody,
        xVisitorId,
        xAddressKey,
        xAddresskey,
        xLocale,
        xForwardedUser,
        xPlatform,
    }: {
        requestBody: GetOrderDetailsRequest,
        /**
         * Visitor ID
         */
        xVisitorId?: any,
        /**
         * Address Key
         */
        xAddressKey?: any,
        /**
         * Old Address Key
         */
        xAddresskey?: any,
        /**
         * Locale
         */
        xLocale?: any,
        /**
         * Customer Code
         */
        xForwardedUser?: any,
        /**
         * Platform
         */
        xPlatform?: any,
    }): CancelablePromise<GetOrderDetailsResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/_svc/order/order/get',
            headers: {
                'X-Visitor-Id': xVisitorId,
                'X-Address-Key': xAddressKey,
                'X-Addresskey': xAddresskey,
                'X-Locale': xLocale,
                'X-Forwarded-User': xForwardedUser,
                'X-Platform': xPlatform,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get invoice token for order
     * @returns string Successful Response
     * @throws ApiError
     */
    public getInvoiceToken({
        requestBody,
        xVisitorId,
        xAddressKey,
        xAddresskey,
        xLocale,
        xForwardedUser,
        xPlatform,
    }: {
        requestBody: GetOrderDetailsRequest,
        /**
         * Visitor ID
         */
        xVisitorId?: any,
        /**
         * Address Key
         */
        xAddressKey?: any,
        /**
         * Old Address Key
         */
        xAddresskey?: any,
        /**
         * Locale
         */
        xLocale?: any,
        /**
         * Customer Code
         */
        xForwardedUser?: any,
        /**
         * Platform
         */
        xPlatform?: any,
    }): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/_svc/order/order/get-invoice-token',
            headers: {
                'X-Visitor-Id': xVisitorId,
                'X-Address-Key': xAddressKey,
                'X-Addresskey': xAddresskey,
                'X-Locale': xLocale,
                'X-Forwarded-User': xForwardedUser,
                'X-Platform': xPlatform,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get list of all orders details
     * @returns GetOrderListResponse Successful Response
     * @throws ApiError
     */
    public getOrderList({
        requestBody,
        xVisitorId,
        xAddressKey,
        xAddresskey,
        xLocale,
        xForwardedUser,
        xPlatform,
    }: {
        requestBody: GetOrderListRequest,
        /**
         * Visitor ID
         */
        xVisitorId?: any,
        /**
         * Address Key
         */
        xAddressKey?: any,
        /**
         * Old Address Key
         */
        xAddresskey?: any,
        /**
         * Locale
         */
        xLocale?: any,
        /**
         * Customer Code
         */
        xForwardedUser?: any,
        /**
         * Platform
         */
        xPlatform?: any,
    }): CancelablePromise<GetOrderListResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/_svc/order/order/list',
            headers: {
                'X-Visitor-Id': xVisitorId,
                'X-Address-Key': xAddressKey,
                'X-Addresskey': xAddresskey,
                'X-Locale': xLocale,
                'X-Forwarded-User': xForwardedUser,
                'X-Platform': xPlatform,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * get cancellable items of an order
     * @returns GetCancellableItemsResponse Successful Response
     * @throws ApiError
     */
    public getCancellableItems({
        orderNr,
        xVisitorId,
        xAddressKey,
        xAddresskey,
        xLocale,
        xForwardedUser,
        xPlatform,
    }: {
        orderNr: string,
        /**
         * Visitor ID
         */
        xVisitorId?: any,
        /**
         * Address Key
         */
        xAddressKey?: any,
        /**
         * Old Address Key
         */
        xAddresskey?: any,
        /**
         * Locale
         */
        xLocale?: any,
        /**
         * Customer Code
         */
        xForwardedUser?: any,
        /**
         * Platform
         */
        xPlatform?: any,
    }): CancelablePromise<GetCancellableItemsResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/_svc/order/order/{order_nr}/cancellable-items',
            path: {
                'order_nr': orderNr,
            },
            headers: {
                'X-Visitor-Id': xVisitorId,
                'X-Address-Key': xAddressKey,
                'X-Addresskey': xAddresskey,
                'X-Locale': xLocale,
                'X-Forwarded-User': xForwardedUser,
                'X-Platform': xPlatform,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * get cancellable shipments/items of an order
     * @returns GetCancellableResponse Successful Response
     * @throws ApiError
     */
    public getCancellable({
        orderNr,
        xVisitorId,
        xAddressKey,
        xAddresskey,
        xLocale,
        xForwardedUser,
        xPlatform,
    }: {
        orderNr: string,
        /**
         * Visitor ID
         */
        xVisitorId?: any,
        /**
         * Address Key
         */
        xAddressKey?: any,
        /**
         * Old Address Key
         */
        xAddresskey?: any,
        /**
         * Locale
         */
        xLocale?: any,
        /**
         * Customer Code
         */
        xForwardedUser?: any,
        /**
         * Platform
         */
        xPlatform?: any,
    }): CancelablePromise<GetCancellableResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/_svc/order/order/{order_nr}/cancellable',
            path: {
                'order_nr': orderNr,
            },
            headers: {
                'X-Visitor-Id': xVisitorId,
                'X-Address-Key': xAddressKey,
                'X-Addresskey': xAddresskey,
                'X-Locale': xLocale,
                'X-Forwarded-User': xForwardedUser,
                'X-Platform': xPlatform,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Cancel entire order
     * @returns any Successful Response
     * @throws ApiError
     */
    public cancelOrder({
        requestBody,
        xVisitorId,
        xAddressKey,
        xAddresskey,
        xLocale,
        xForwardedUser,
        xPlatform,
    }: {
        requestBody: CancelOrderRequest,
        /**
         * Visitor ID
         */
        xVisitorId?: any,
        /**
         * Address Key
         */
        xAddressKey?: any,
        /**
         * Old Address Key
         */
        xAddresskey?: any,
        /**
         * Locale
         */
        xLocale?: any,
        /**
         * Customer Code
         */
        xForwardedUser?: any,
        /**
         * Platform
         */
        xPlatform?: any,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/_svc/order/order/cancel',
            headers: {
                'X-Visitor-Id': xVisitorId,
                'X-Address-Key': xAddressKey,
                'X-Addresskey': xAddresskey,
                'X-Locale': xLocale,
                'X-Forwarded-User': xForwardedUser,
                'X-Platform': xPlatform,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Cancel selected items from order
     * @returns any Successful Response
     * @throws ApiError
     */
    public cancelItems({
        requestBody,
        xVisitorId,
        xAddressKey,
        xAddresskey,
        xLocale,
        xForwardedUser,
        xPlatform,
    }: {
        requestBody: CancelItemsRequest,
        /**
         * Visitor ID
         */
        xVisitorId?: any,
        /**
         * Address Key
         */
        xAddressKey?: any,
        /**
         * Old Address Key
         */
        xAddresskey?: any,
        /**
         * Locale
         */
        xLocale?: any,
        /**
         * Customer Code
         */
        xForwardedUser?: any,
        /**
         * Platform
         */
        xPlatform?: any,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/_svc/order/order/cancel-items',
            headers: {
                'X-Visitor-Id': xVisitorId,
                'X-Address-Key': xAddressKey,
                'X-Addresskey': xAddresskey,
                'X-Locale': xLocale,
                'X-Forwarded-User': xForwardedUser,
                'X-Platform': xPlatform,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Cancel selected shipment from order
     * @returns any Successful Response
     * @throws ApiError
     */
    public cancelShipment({
        requestBody,
        xVisitorId,
        xAddressKey,
        xAddresskey,
        xLocale,
        xForwardedUser,
        xPlatform,
    }: {
        requestBody: CancelShipmentRequest,
        /**
         * Visitor ID
         */
        xVisitorId?: any,
        /**
         * Address Key
         */
        xAddressKey?: any,
        /**
         * Old Address Key
         */
        xAddresskey?: any,
        /**
         * Locale
         */
        xLocale?: any,
        /**
         * Customer Code
         */
        xForwardedUser?: any,
        /**
         * Platform
         */
        xPlatform?: any,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/_svc/order/order/cancel-shipment',
            headers: {
                'X-Visitor-Id': xVisitorId,
                'X-Address-Key': xAddressKey,
                'X-Addresskey': xAddresskey,
                'X-Locale': xLocale,
                'X-Forwarded-User': xForwardedUser,
                'X-Platform': xPlatform,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Cancel flow
     * @returns any Successful Response
     * @throws ApiError
     */
    public cancelGeneric({
        requestBody,
        xVisitorId,
        xAddressKey,
        xAddresskey,
        xLocale,
        xForwardedUser,
        xPlatform,
    }: {
        requestBody: CancelGenericRequest,
        /**
         * Visitor ID
         */
        xVisitorId?: any,
        /**
         * Address Key
         */
        xAddressKey?: any,
        /**
         * Old Address Key
         */
        xAddresskey?: any,
        /**
         * Locale
         */
        xLocale?: any,
        /**
         * Customer Code
         */
        xForwardedUser?: any,
        /**
         * Platform
         */
        xPlatform?: any,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/_svc/order/order/generic/cancel',
            headers: {
                'X-Visitor-Id': xVisitorId,
                'X-Address-Key': xAddressKey,
                'X-Addresskey': xAddresskey,
                'X-Locale': xLocale,
                'X-Forwarded-User': xForwardedUser,
                'X-Platform': xPlatform,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get cancel reason list for the orders
     * @returns CancelReason Successful Response
     * @throws ApiError
     */
    public getOrderCancelReasons({
        xVisitorId,
        xAddressKey,
        xAddresskey,
        xLocale,
        xForwardedUser,
        xPlatform,
    }: {
        /**
         * Visitor ID
         */
        xVisitorId?: any,
        /**
         * Address Key
         */
        xAddressKey?: any,
        /**
         * Old Address Key
         */
        xAddresskey?: any,
        /**
         * Locale
         */
        xLocale?: any,
        /**
         * Customer Code
         */
        xForwardedUser?: any,
        /**
         * Platform
         */
        xPlatform?: any,
    }): CancelablePromise<Array<CancelReason>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/_svc/order/order/cancel-reasons',
            headers: {
                'X-Visitor-Id': xVisitorId,
                'X-Address-Key': xAddressKey,
                'X-Addresskey': xAddresskey,
                'X-Locale': xLocale,
                'X-Forwarded-User': xForwardedUser,
                'X-Platform': xPlatform,
            },
        });
    }

    /**
     * Update order shipping address
     * @returns any Successful Response
     * @throws ApiError
     */
    public updateShippingAddress({
        requestBody,
        xVisitorId,
        xAddressKey,
        xAddresskey,
        xLocale,
        xForwardedUser,
        xPlatform,
    }: {
        requestBody: UpdateShippingAddressRequest,
        /**
         * Visitor ID
         */
        xVisitorId?: any,
        /**
         * Address Key
         */
        xAddressKey?: any,
        /**
         * Old Address Key
         */
        xAddresskey?: any,
        /**
         * Locale
         */
        xLocale?: any,
        /**
         * Customer Code
         */
        xForwardedUser?: any,
        /**
         * Platform
         */
        xPlatform?: any,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/_svc/order/order/update-shipping-address',
            headers: {
                'X-Visitor-Id': xVisitorId,
                'X-Address-Key': xAddressKey,
                'X-Addresskey': xAddresskey,
                'X-Locale': xLocale,
                'X-Forwarded-User': xForwardedUser,
                'X-Platform': xPlatform,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Run order confirmation for orders linked to address key
     * @returns any Successful Response
     * @throws ApiError
     */
    public verifyAddressOrders({
        requestBody,
        xVisitorId,
        xAddressKey,
        xAddresskey,
        xLocale,
        xForwardedUser,
        xPlatform,
    }: {
        requestBody: VerifyAddressRequest,
        /**
         * Visitor ID
         */
        xVisitorId?: any,
        /**
         * Address Key
         */
        xAddressKey?: any,
        /**
         * Old Address Key
         */
        xAddresskey?: any,
        /**
         * Locale
         */
        xLocale?: any,
        /**
         * Customer Code
         */
        xForwardedUser?: any,
        /**
         * Platform
         */
        xPlatform?: any,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/_svc/order/order/verify-address',
            headers: {
                'X-Visitor-Id': xVisitorId,
                'X-Address-Key': xAddressKey,
                'X-Addresskey': xAddresskey,
                'X-Locale': xLocale,
                'X-Forwarded-User': xForwardedUser,
                'X-Platform': xPlatform,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get payment info for an order
     * @returns PaymentInfoResponse Successful Response
     * @throws ApiError
     */
    public getPaymentInfo({
        requestBody,
        xVisitorId,
        xAddressKey,
        xAddresskey,
        xLocale,
        xForwardedUser,
        xPlatform,
    }: {
        requestBody: GetOrderDetailsRequest,
        /**
         * Visitor ID
         */
        xVisitorId?: any,
        /**
         * Address Key
         */
        xAddressKey?: any,
        /**
         * Old Address Key
         */
        xAddresskey?: any,
        /**
         * Locale
         */
        xLocale?: any,
        /**
         * Customer Code
         */
        xForwardedUser?: any,
        /**
         * Platform
         */
        xPlatform?: any,
    }): CancelablePromise<PaymentInfoResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/_svc/order/order/get-payment-info',
            headers: {
                'X-Visitor-Id': xVisitorId,
                'X-Address-Key': xAddressKey,
                'X-Addresskey': xAddresskey,
                'X-Locale': xLocale,
                'X-Forwarded-User': xForwardedUser,
                'X-Platform': xPlatform,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * get rocket shipment live location
     * @returns HOMSShipmentTrackingResponse Successful Response
     * @throws ApiError
     */
    public getRocketShipmentLocation({
        requestBody,
        xVisitorId,
        xAddressKey,
        xAddresskey,
        xLocale,
        xForwardedUser,
        xPlatform,
    }: {
        requestBody: HOMSShipmentTrackingRequest,
        /**
         * Visitor ID
         */
        xVisitorId?: any,
        /**
         * Address Key
         */
        xAddressKey?: any,
        /**
         * Old Address Key
         */
        xAddresskey?: any,
        /**
         * Locale
         */
        xLocale?: any,
        /**
         * Customer Code
         */
        xForwardedUser?: any,
        /**
         * Platform
         */
        xPlatform?: any,
    }): CancelablePromise<HOMSShipmentTrackingResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/_svc/order/order/rocket-shipment-location',
            headers: {
                'X-Visitor-Id': xVisitorId,
                'X-Address-Key': xAddressKey,
                'X-Addresskey': xAddresskey,
                'X-Locale': xLocale,
                'X-Forwarded-User': xForwardedUser,
                'X-Platform': xPlatform,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delivery Proof & Return Proof
     * @returns ImageProofResponse Successful Response
     * @throws ApiError
     */
    public getImageProof({
        requestBody,
        xVisitorId,
        xAddressKey,
        xAddresskey,
        xLocale,
        xForwardedUser,
        xPlatform,
    }: {
        requestBody: ImageProofRequest,
        /**
         * Visitor ID
         */
        xVisitorId?: any,
        /**
         * Address Key
         */
        xAddressKey?: any,
        /**
         * Old Address Key
         */
        xAddresskey?: any,
        /**
         * Locale
         */
        xLocale?: any,
        /**
         * Customer Code
         */
        xForwardedUser?: any,
        /**
         * Platform
         */
        xPlatform?: any,
    }): CancelablePromise<ImageProofResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/_svc/order/order/image-proof',
            headers: {
                'X-Visitor-Id': xVisitorId,
                'X-Address-Key': xAddressKey,
                'X-Addresskey': xAddresskey,
                'X-Locale': xLocale,
                'X-Forwarded-User': xForwardedUser,
                'X-Platform': xPlatform,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * create czentrix custom lead for rocket shipments
     * @returns any Successful Response
     * @throws ApiError
     */
    public createCzentrixLead({
        requestBody,
        xVisitorId,
        xAddressKey,
        xAddresskey,
        xLocale,
        xForwardedUser,
        xPlatform,
    }: {
        requestBody: HOMSCzentrixCallbackRequest,
        /**
         * Visitor ID
         */
        xVisitorId?: any,
        /**
         * Address Key
         */
        xAddressKey?: any,
        /**
         * Old Address Key
         */
        xAddresskey?: any,
        /**
         * Locale
         */
        xLocale?: any,
        /**
         * Customer Code
         */
        xForwardedUser?: any,
        /**
         * Platform
         */
        xPlatform?: any,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/_svc/order/order/create-czentrix-lead',
            headers: {
                'X-Visitor-Id': xVisitorId,
                'X-Address-Key': xAddressKey,
                'X-Addresskey': xAddresskey,
                'X-Locale': xLocale,
                'X-Forwarded-User': xForwardedUser,
                'X-Platform': xPlatform,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get order details summary
     * @returns OrderPaymentSummaryResponse Successful Response
     * @throws ApiError
     */
    public getOrderSummary({
        requestBody,
        xVisitorId,
        xAddressKey,
        xAddresskey,
        xLocale,
        xForwardedUser,
        xPlatform,
    }: {
        requestBody: GetOrderDetailsRequest,
        /**
         * Visitor ID
         */
        xVisitorId?: any,
        /**
         * Address Key
         */
        xAddressKey?: any,
        /**
         * Old Address Key
         */
        xAddresskey?: any,
        /**
         * Locale
         */
        xLocale?: any,
        /**
         * Customer Code
         */
        xForwardedUser?: any,
        /**
         * Platform
         */
        xPlatform?: any,
    }): CancelablePromise<OrderPaymentSummaryResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/_svc/order/order/summary',
            headers: {
                'X-Visitor-Id': xVisitorId,
                'X-Address-Key': xAddressKey,
                'X-Addresskey': xAddresskey,
                'X-Locale': xLocale,
                'X-Forwarded-User': xForwardedUser,
                'X-Platform': xPlatform,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Mark Order and Payment as Cancelled
     * @returns CancelOrderPaymentResponse Successful Response
     * @throws ApiError
     */
    public markOrderCancelled({
        orderNr,
        xVisitorId,
        xAddressKey,
        xAddresskey,
        xLocale,
        xForwardedUser,
        xPlatform,
    }: {
        orderNr: string,
        /**
         * Visitor ID
         */
        xVisitorId?: any,
        /**
         * Address Key
         */
        xAddressKey?: any,
        /**
         * Old Address Key
         */
        xAddresskey?: any,
        /**
         * Locale
         */
        xLocale?: any,
        /**
         * Customer Code
         */
        xForwardedUser?: any,
        /**
         * Platform
         */
        xPlatform?: any,
    }): CancelablePromise<CancelOrderPaymentResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/_svc/order/order/{order_nr}/cancel',
            path: {
                'order_nr': orderNr,
            },
            headers: {
                'X-Visitor-Id': xVisitorId,
                'X-Address-Key': xAddressKey,
                'X-Addresskey': xAddresskey,
                'X-Locale': xLocale,
                'X-Forwarded-User': xForwardedUser,
                'X-Platform': xPlatform,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}

/* eslint-disable css-modules/no-undef-class */
import React, { CSSProperties } from 'react';

import clsx from 'clsx';
import { isString } from 'lodash';

import styles from './Skeleton.module.scss';

const FALLBACK_WIDTH = 24;
const FALLBACK_HEIGHT = 24;

type TProps = {
    show?: boolean;
    children?: React.ReactNode;
    className?: string;
    style?: CSSProperties;
    width?: number | string;
    height?: number | string;
};

const Skeleton = ({ children, className = '', height, show = true, style, width }: TProps) => {
    const shouldAutoSize = !!children && !(width || height);

    if (!show && !children) return <></>;

    return (
        <div
            className={clsx(
                styles.container,
                className,
                show && styles.show,
                !shouldAutoSize && !!children ? styles.loaded : '',
            )}
            style={
                shouldAutoSize
                    ? {}
                    : {
                          minWidth: width ? `min(${width}, ${FALLBACK_WIDTH})` : FALLBACK_WIDTH,
                          minHeight: height
                              ? `min(${isString(height) ? height : `${height}px`}, ${FALLBACK_WIDTH})`
                              : FALLBACK_HEIGHT,
                          ...(width ? { width } : {}),
                          ...(height ? { height } : {}),
                          ...style,
                      }
            }
        >
            {children}
        </div>
    );
};

export default Skeleton;

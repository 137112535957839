/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ContinueSearchingResponse } from '../models/ContinueSearchingResponse';
import type { ProductsResponse } from '../models/ProductsResponse';
import type { SearchDepartmentUrls } from '../models/SearchDepartmentUrls';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class RecentCarouselsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * get recently viewed products
     * Retrieve recently viewed products carousel.
     *
     * :param department_path: str: The path of the department fashion/men.
     * :param limit: int, optional: The maximum number of products to retrieve. Defaults to 30.
     * :param recency: int, optional: The recency in minutes to consider for recently viewed products. Defaults to 60.
     * :param page: int, optional: The page number for pagination. Defaults to 1.
     *
     * :return:
     * ProductsResponse: The response includes product details and analytics.
     * @returns ProductsResponse Successful Response
     * @throws ApiError
     */
    public getRecentlyViewedProductsCarousel({
        departmentPath,
        limit = 30,
        recency = 60,
        page = 1,
        xVisitorId,
        xAddressKey,
        xLocale,
        xForwardedUser,
        xPlatform,
        xContent,
        xDebug,
    }: {
        departmentPath: string,
        limit?: number,
        recency?: number,
        page?: number,
        /**
         * Visitor ID
         */
        xVisitorId?: any,
        /**
         * Address Key
         */
        xAddressKey?: any,
        /**
         * Locale
         */
        xLocale?: any,
        /**
         * Customer Code
         */
        xForwardedUser?: any,
        /**
         * Platform
         */
        xPlatform?: any,
        /**
         * Content
         */
        xContent?: any,
        /**
         * Debug Mode
         */
        xDebug?: any,
    }): CancelablePromise<ProductsResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/_svc/catalog/product/recently_viewed/{department_path}',
            path: {
                'department_path': departmentPath,
            },
            headers: {
                'X-Visitor-Id': xVisitorId,
                'X-Address-Key': xAddressKey,
                'X-Locale': xLocale,
                'X-Forwarded-User': xForwardedUser,
                'X-Platform': xPlatform,
                'X-Content': xContent,
                'X-Debug': xDebug,
            },
            query: {
                'limit': limit,
                'recency': recency,
                'page': page,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * get recent search terms for customer
     * @returns ContinueSearchingResponse Successful Response
     * @throws ApiError
     */
    public getRecentSearchesCarousel({
        searchDepartment,
        xVisitorId,
        xAddressKey,
        xLocale,
        xForwardedUser,
        xPlatform,
        xContent,
        xDebug,
    }: {
        searchDepartment: SearchDepartmentUrls,
        /**
         * Visitor ID
         */
        xVisitorId?: any,
        /**
         * Address Key
         */
        xAddressKey?: any,
        /**
         * Locale
         */
        xLocale?: any,
        /**
         * Customer Code
         */
        xForwardedUser?: any,
        /**
         * Platform
         */
        xPlatform?: any,
        /**
         * Content
         */
        xContent?: any,
        /**
         * Debug Mode
         */
        xDebug?: any,
    }): CancelablePromise<ContinueSearchingResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/_svc/catalog/recent/continue_search/{search_department}',
            path: {
                'search_department': searchDepartment,
            },
            headers: {
                'X-Visitor-Id': xVisitorId,
                'X-Address-Key': xAddressKey,
                'X-Locale': xLocale,
                'X-Forwarded-User': xForwardedUser,
                'X-Platform': xPlatform,
                'X-Content': xContent,
                'X-Debug': xDebug,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}

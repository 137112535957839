/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';

import styles from './FieldLayout.module.scss';

type TProps = {
    containerClass?: string;
    children: React.ReactNode;
    error?: string | null;
    helperText?: string;
    isInlineLabel?: boolean; // attention ehab from cli - did we need to edit the component for this use case? can't see it anywhere else?
    label?: string;
    bottomHelperText?: string;
};

const FieldLayout = ({
    bottomHelperText,
    children,
    containerClass,
    error,
    helperText,
    isInlineLabel,
    label,
}: TProps): JSX.Element => {
    const renderLabelAndContent = () => (
        <>
            {label && (
                <div className={styles.labelContainer}>
                    <label>{label}</label>
                    {/* For now, do not support helperTexts in case of inline labels */}
                    {helperText && !isInlineLabel && <span className={styles.helperText}>{helperText}</span>}
                </div>
            )}
            {children}
        </>
    );
    return (
        <div className={containerClass}>
            {isInlineLabel ? (
                <div className={styles.inlineField}>{renderLabelAndContent()}</div>
            ) : (
                renderLabelAndContent()
            )}
            {error && <p className={styles.error}>{error}</p>}
            {bottomHelperText && <p className={styles.bottomHelperText}>{bottomHelperText}</p>}
        </div>
    );
};

export default FieldLayout;

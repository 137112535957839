import { create, SetState } from 'zustand';

import { ProductFullResponse, ProductSimpleResponse, ProductWishlistResponse } from '@nm-namshi-frontend/services';

type TAuthModal = { show: boolean; onSuccess?: () => void; enableSignupJourney?: boolean };
type TGroupedProductsModal = {
    show: boolean;
    product: ProductSimpleResponse | ProductFullResponse | null;
    widgetId?: number;
};

export type TLayoutSlice = {
    isFullScreenMapVisible: boolean;
    isFullscreenLoaderShown: boolean;
    toggleShowFullScreenMap: (isVisible: boolean) => void;
    toggleShowFullScreenLoader: (isVisible: boolean) => void;

    // quickbuy modal
    selectedProductforQuickBuy: ProductSimpleResponse | null;
    removeProductforQuickBuy: () => void;
    setSelectedProductforQuickBuy: (selectedProductforQuickBuy: ProductSimpleResponse | null) => void;

    // Auth modal
    authModal: TAuthModal;
    setAuthModal: (args: TAuthModal) => void;

    isSearchOpen: boolean;
    setIsSearchOpen: (isSearchOpen: boolean) => void;

    atbModalProduct: null | ProductFullResponse | ProductWishlistResponse;
    atbSrc: string | null;
    setAtbModalProduct: (
        atbModalProduct: null | ProductFullResponse | ProductWishlistResponse,
        _atbSrc?: string,
    ) => void;

    groupedProductsModal: TGroupedProductsModal;
    setGroupedProductsModal: (groupedProduct: TGroupedProductsModal) => void;
};

const createLayoutSlice = (set: SetState<TLayoutSlice>) => ({
    isFullScreenMapVisible: false,
    isFullscreenLoaderShown: false,
    toggleShowFullScreenMap: (isVisible: boolean) => {
        set(() => ({
            isFullScreenMapVisible: isVisible,
        }));
    },
    toggleShowFullScreenLoader: (isVisible: boolean) => {
        set(() => ({
            isFullscreenLoaderShown: isVisible,
        }));
    },

    // quickbuy modal
    selectedProductforQuickBuy: null,
    setSelectedProductforQuickBuy: (selectedProductforQuickBuy: ProductSimpleResponse | null) => {
        set({ selectedProductforQuickBuy });
    },
    removeProductforQuickBuy: () => {
        set({ selectedProductforQuickBuy: null });
    },

    // Auth modal
    authModal: { show: false, enableSignupJourney: false },
    setAuthModal: (authModal: TAuthModal) => {
        set(() => ({
            authModal,
        }));
    },

    isSearchOpen: false,
    setIsSearchOpen: (isSearchOpen: boolean) => {
        set(() => ({
            isSearchOpen,
        }));
    },

    // add to bag modal
    atbModalProduct: null,
    atbSrc: null,
    setAtbModalProduct: (_atbModalProduct: null | ProductFullResponse | ProductWishlistResponse, _atbSrc?: string) => {
        set(() => ({
            atbModalProduct: _atbModalProduct,
            atbSrc: _atbModalProduct === null ? null : _atbSrc || null,
        }));
    },

    groupedProductsModal: { show: false, product: null },
    setGroupedProductsModal: (groupedProductsModal: TGroupedProductsModal) => {
        set({ groupedProductsModal });
    },
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useLayoutStore = create<TLayoutSlice>((set: SetState<any>) => ({
    ...createLayoutSlice(set),
}));

export default useLayoutStore;

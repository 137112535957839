// TODO
// 1. TYPES

import { CDN_BASE, IMG_BASE } from '@nm-namshi-frontend/core/config';

import { getLocaleLanguage } from './locale';
import { isServer } from './helper';

const imagekeyExtension = '.jpg'; // Default to this until BE includes it in within the imageKey

const getImages = (locale?: string) => {
    const localeLanguage = getLocaleLanguage(locale);

    return {
        emptyStates: {
            catalog: `${IMG_BASE}/sivvi/design-system/empty-states/empty-catalog.svg`,
            emptyWishlistState: `${IMG_BASE}/sivvi/design-system/empty-states/empty-wishlist.svg`,
            orders: `${IMG_BASE}/sivvi/design-system/empty-states/empty-orders.svg`,
            returnExchanges: `${IMG_BASE}/sivvi/design-system/empty-states/empty-return-exchanges.svg`,
            credits: `${IMG_BASE}/sivvi/design-system/empty-states/empty-credits.svg`,
            emptyAddresses: `${IMG_BASE}/sivvi/design-system/empty-states/empty-addresses.svg`,
            savedCards: `${IMG_BASE}/sivvi/design-system/empty-states/empty-saved-cards.svg`,
            emptyBag: `${IMG_BASE}/sivvi/design-system/empty-states/empty-bag.svg`,
            otpTriggerFailure: `${IMG_BASE}/sivvi/design-system/empty-states/error-otp.svg`,
            orderError: `${IMG_BASE}/sivvi/design-system/empty-states/error-order.svg`,
            generalError: `${IMG_BASE}/sivvi/design-system/empty-states/error-general.svg`,
            tplNoCarrierImg: `${IMG_BASE}/namshi/aftersales/tpl_icons/fallback_tpl.png`,
        },
        filterColorSwatches: `${IMG_BASE}/namshi/images/filters-color-swatches`, // @TODO : Ehab - Rethink this
        flags: {
            ae: `${IMG_BASE}/namshi/images/flag-uae.svg`,
            sa: `${IMG_BASE}/namshi/images/flag-saudi.svg`,
            kw: `${IMG_BASE}/namshi/images/flag-kuwait.svg`,
            qa: `${IMG_BASE}/namshi/images/flag-qatar.svg`,
            bh: `${IMG_BASE}/namshi/images/flag-bahrain.svg`,
            om: `${IMG_BASE}/namshi/images/flag-oman.svg`,
            iq: `${IMG_BASE}/namshi/images/flag-iraq.svg`,
        },
        loaders: {
            logo: `${IMG_BASE}/sivvi/design-system/loaders/logo-loader.gif`,
        },
        logos: {
            noonWhite: `${IMG_BASE}/namshi/images/noon-logo-white.svg`,
            noonWhiteAr: `${IMG_BASE}/namshi/images/noon-logo-white-ar.svg`,
            namshi: `${IMG_BASE}/namshi/images/namshi-logo-black.png`,
            namshiApp: `${IMG_BASE}/namshi/images/namshi-app-v2.svg`,
            google: `${IMG_BASE}/namshi/images/google.svg`,
            apple: `${IMG_BASE}/namshi/images/apple.svg`,
            facebook: `${IMG_BASE}/namshi/images/facebook.svg`,
            tabby: `${IMG_BASE}/namshi/images/logo-tabby-v2.svg`,
            tamara: `${IMG_BASE}/namshi/images/logo-tamara-${localeLanguage}-v2.svg`,
        },
        misc: {
            addressTagLocker: `${IMG_BASE}/namshi/icons/address-tag-locker.svg`,
            addressTagPickupPoint: `${IMG_BASE}/namshi/icons/address-tag-pick-up-v2.svg`,
            cvvHelper: `${IMG_BASE}/sivvi/design-system/misc/cvv-helper.svg`,
            ratingsStar: `${IMG_BASE}/sivvi/design-system/misc/ratings-star.svg`,
            moreColors: `${IMG_BASE}/sivvi/images/more-colors.svg`,
            googlePlay: `${IMG_BASE}/sivvi/images/googleplay_light.svg`,
            appStore: `${IMG_BASE}/sivvi/images/appstore_light.svg`,
            huaweiAppGallery: `${IMG_BASE}/namshi/images/huawei_appgallery_light.svg`,
        },
        paymentMethods: {
            applePay: `${IMG_BASE}/sivvi/design-system/payment-methods/payment-method-applepay.svg`,
            amex: `${IMG_BASE}/sivvi/design-system/payment-methods/payment-method-cc-amex.svg`,
            cod: `${IMG_BASE}/sivvi/design-system/payment-methods/payment-method-cod.svg`,
            card: `${IMG_BASE}/sivvi/design-system/payment-methods/payment-method-card.svg`,
            credits: `${IMG_BASE}/sivvi/design-system/payment-methods/payment-method-credits.svg`,
            mada: `${IMG_BASE}/sivvi/design-system/payment-methods/payment-method-cc-mada.svg`,
            mastercard: `${IMG_BASE}/sivvi/design-system/payment-methods/payment-method-cc-mastercard.svg`,
            tabby: `${IMG_BASE}/sivvi/design-system/payment-methods/payment-method-tabby-v2.svg`,
            visa: `${IMG_BASE}/sivvi/design-system/payment-methods/payment-method-cc-visa-v2.svg`,
        },
        socialMedia: {
            email: `${IMG_BASE}/sivvi/images/social-media/email.svg`,
            twitter: `${IMG_BASE}/sivvi/images/social-media/twitter.svg`,
            pinterest: `${IMG_BASE}/sivvi/images/social-media/pinterest.svg`,
            whatsapp: `${IMG_BASE}/sivvi/images/social-media/whatsapp.svg`,
        },
        emptyWishlist: `${IMG_BASE}/sivvi/images/empty-wishlist.svg`,

        ogImage: {
            en: `${IMG_BASE}/namshi/images/namshi_og_en-2.jpg`,
            ar: `${IMG_BASE}/namshi/images/namshi_og_ar-2.jpg`,
        },
        brands: {
            nike: `${IMG_BASE}/namshi/images/nike.jpeg`,
            puma: `${IMG_BASE}/namshi/images/puma.jpeg`,
            new_look: `${IMG_BASE}/namshi/images/newlook.jpeg`,
            adidas: `${IMG_BASE}/namshi/images/adidas.png`,
            forever_21: `${IMG_BASE}/namshi/images/forever21.jpeg`,
            calvin_klein: `${IMG_BASE}/namshi/images/calvinklein.jpeg`,
            jordan: `${IMG_BASE}/namshi/images/jordan.jpeg`,
            skechers: `${IMG_BASE}/namshi/images/skechers.png`,
            missguided: `${IMG_BASE}/namshi/images/missguided.jpeg`,
            topshop: `${IMG_BASE}/namshi/images/topshop.jpeg`,
            new_balance: `${IMG_BASE}/namshi/images/newbalance.jpeg`,
        },
        migration: {
            // NOTE: The files are named with the wrong language
            // Instead of reuploading the assets, we just map the path to the right key so it reflects across the app
            congratsAr: `${IMG_BASE}/namshi/images/cm-congrats-green-en-3.png`,
            congratsEn: `${IMG_BASE}/namshi/images/cm-congrats-green-ar-3.png`,
            noonAndNamshiEn: `${IMG_BASE}/namshi/images/noon_namshi_en.png`,
            noonAndNamshiAr: `${IMG_BASE}/namshi/images/noon_namshi_ar.png`,
        },
        apps: {
            minutes: `${IMG_BASE}/namshi/images/rounded-minutes-logo.svg`,
            noon: `${IMG_BASE}/namshi/images/rounded-noon-logo.svg`,
            food: `${IMG_BASE}/namshi/images/rounded-food-logo-2.png`,
            namshi: `${IMG_BASE}/namshi/images/rounded-namshi-logo-2.png`,
        },
        arrows: {
            leftAnimated: `${IMG_BASE}/namshi/icons/arrow-animated-left.gif`,
            rightAnimated: `${IMG_BASE}/namshi/icons/arrow-animated-right.gif`,
        },
        couponTagWrapper: {
            leftBorder: `${IMG_BASE}/namshi/images/coupon-left-tag-wrapper-1.svg`,
            rightBorder: `${IMG_BASE}/namshi/images/coupon-right-tag-wrapper-1.svg`,
            border: `${IMG_BASE}/namshi/images/coupon-wrapper-body-1.png`,
        },
        midrollFilter: {
            threeBars: `${IMG_BASE}/namshi/images/midroll-filter-animation.gif`,
        },
        error: {
            paymentError: `${IMG_BASE}/namshi/images/payment-error.svg`,
        },
        onBoardingJourney: {
            profileAllSetAnimation: `${IMG_BASE}/namshi/animations/profileAllSet.json`,
        },
    };
};

export type TGetImages = ReturnType<typeof getImages>;

// These are predefined sizes configured in fastly
export enum IMAGE_SIZE {
    XS = 40,
    MS = 90,
    S = 120,
    SM = 150,
    M = 240,
    ML = 400,
    L = 800,
}

export const PRODUCT_IMAGE_RATIO = 1.36375;

const getProcessedImagePath = ({
    baseUrl,
    path,
    width,
}: {
    baseUrl?: string;
    path: string;
    width?: IMAGE_SIZE | 'original';
}): string => {
    if (!path) {
        return '';
    }

    const processedImagePath = new URL(path, baseUrl);

    if (supportedImageFormats.avif) {
        processedImagePath.searchParams.set('format', 'avif');
    } else if (supportedImageFormats.webp) {
        processedImagePath.searchParams.set('format', 'webp');
    } else {
        processedImagePath.searchParams.set('format', 'jpg');
    }

    if (width) {
        processedImagePath.searchParams.set('width', width.toString());
    }

    return processedImagePath.toString();
};

// For Onsite Images used in Widgets, always contains an absolute path to the CDN file
export const getWidgetImageUrl = (url: string): string =>
    getProcessedImagePath({
        path: url,
    });

export const checkImageExisted = async (url: string) =>
    fetch(url, { method: 'HEAD' })
        .then((res) => {
            if (res.ok) {
                return true;
            }
            return false;
        })
        .catch(() => false);

// For Product Images
export const getImageUrl = (key: string, width: IMAGE_SIZE | 'original' = IMAGE_SIZE.L): string =>
    getProcessedImagePath({
        path: key,
        baseUrl: `${CDN_BASE}/p/`,
        width,
    });

const supportedImageFormats = { memoized: false, avif: true, webp: false };
export const getSupportedImageFormats = () => {
    if (supportedImageFormats.memoized) {
        return supportedImageFormats;
    }

    if (isServer()) {
        return supportedImageFormats;
    }

    // reference - https://github.com/kamalendugarai/webp-avif-browsers-support-detection/blob/master/src/index.js#L2-L13
    const images = [
        {
            type: 'avif',
            url: 'data:image/avif;base64,AAAAHGZ0eXBtaWYxAAAAAG1pZjFhdmlmbWlhZgAAAPFtZXRhAAAAAAAAACFoZGxyAAAAAAAAAABwaWN0AAAAAAAAAAAAAAAAAAAAAA5waXRtAAAAAAABAAAAHmlsb2MAAAAABEAAAQABAAAAAAEVAAEAAAAeAAAAKGlpbmYAAAAAAAEAAAAaaW5mZQIAAAAAAQAAYXYwMUltYWdlAAAAAHBpcHJwAAAAUWlwY28AAAAUaXNwZQAAAAAAAAABAAAAAQAAABBwYXNwAAAAAQAAAAEAAAAVYXYxQ4EgAAAKBzgABpAQ0AIAAAAQcGl4aQAAAAADCAgIAAAAF2lwbWEAAAAAAAAAAQABBAECg4QAAAAmbWRhdAoHOAAGkBDQAjITFkAAAEgAAAB5TNw9UxdXU6F6oA == ',
        },
        {
            type: 'webp',
            url: 'data:image/webp;base64,UklGRhwAAABXRUJQVlA4TBAAAAAvAAAAEAfQpv5HmQMR0f8A',
        },
    ] as const;

    try {
        images.forEach((image) => {
            const img = new Image();
            img.src = image.url;
            img.onload = () => {
                supportedImageFormats[image.type] = true;
                supportedImageFormats.memoized = true;
            };
            img.onerror = () => {
                supportedImageFormats[image.type] = false;
                supportedImageFormats.memoized = true;
            };
        });
    } catch (error) {
        /** */
    }
    return supportedImageFormats;
};
getSupportedImageFormats();

export default getImages;

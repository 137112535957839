/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ChangeResponse } from '../models/ChangeResponse';
import type { CreateCustomerAddressRequest } from '../models/CreateCustomerAddressRequest';
import type { CreateCustomerAddressResponse } from '../models/CreateCustomerAddressResponse';
import type { CustomerAddressesResponse } from '../models/CustomerAddressesResponse';
import type { CustomerDataResponse } from '../models/CustomerDataResponse';
import type { CustomerExpiringCredits } from '../models/CustomerExpiringCredits';
import type { CustomerUpdateRequest } from '../models/CustomerUpdateRequest';
import type { CustomerDataResponseV2 } from '../models/CustomerDataResponseV2';
import type { GetNationalitiesResponse } from '../models/GetNationalitiesResponse';
import type { CustomerUpdateRequestV2 } from '../models/CustomerUpdateRequestV2';
import type { DeactivateRequest } from '../models/DeactivateRequest';
import type { DeactivationConfigResponse } from '../models/DeactivationConfigResponse';
import type { GetCustomerWalletResponse } from '../models/GetCustomerWalletResponse';
import type { GetPaymentCardsResponse } from '../models/GetPaymentCardsResponse';
import type { GetPhonesResponse } from '../models/GetPhonesResponse';
import type { LatLngLiteral } from '../models/LatLngLiteral';
import type { Location } from '../models/Location';
import type { PhoneActionRequest } from '../models/PhoneActionRequest';
import type { PhoneCodesResponse } from '../models/PhoneCodesResponse';
import type { RedeemGiftCardRequest } from '../models/RedeemGiftCardRequest';
import type { SelectAddressRequest } from '../models/SelectAddressRequest';
import type { SignupRequest } from '../models/SignupRequest';
import type { UpdateOrderAddressRequest } from '../models/UpdateOrderAddressRequest';
import type { VerifyOTPRequest } from '../models/VerifyOTPRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class CustomerService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get deactivation config
     * @returns DeactivationConfigResponse Successful Response
     * @throws ApiError
     */
    public getDeactivationConfig({
        xContent,
        xPlatform,
        xLocale,
        xMp,
    }: {
        xContent?: string,
        xPlatform?: string,
        xLocale?: string,
        xMp?: string,
    }): CancelablePromise<DeactivationConfigResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/_svc/customer-v1/customer/account/deactivation-config',
            headers: {
                'X-Content': xContent,
                'X-Platform': xPlatform,
                'X-Locale': xLocale,
                'X-Mp': xMp,
            },
        });
    }

    /**
     * Reactivate account
     * @returns CustomerDataResponse Successful Response
     * @throws ApiError
     */
    public reactivateAccount({
        xContent,
        xPlatform,
        xLocale,
        xMp,
    }: {
        xContent?: string,
        xPlatform?: string,
        xLocale?: string,
        xMp?: string,
    }): CancelablePromise<CustomerDataResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/_svc/customer-v1/customer/account/activate',
            headers: {
                'X-Content': xContent,
                'X-Platform': xPlatform,
                'X-Locale': xLocale,
                'X-Mp': xMp,
            },
        });
    }

    /**
     * Deactivate account
     * @returns CustomerDataResponse Successful Response
     * @throws ApiError
     */
    public deactivateAccount({
        xContent,
        xPlatform,
        xLocale,
        xMp,
        requestBody,
    }: {
        xContent?: string,
        xPlatform?: string,
        xLocale?: string,
        xMp?: string,
        requestBody?: DeactivateRequest,
    }): CancelablePromise<CustomerDataResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/_svc/customer-v1/customer/account/deactivate',
            headers: {
                'X-Content': xContent,
                'X-Platform': xPlatform,
                'X-Locale': xLocale,
                'X-Mp': xMp,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get customer
     * @returns CustomerDataResponse Successful Response
     * @throws ApiError
     */
    public getCustomer({
        xContent,
        xPlatform,
        xLocale,
        xMp,
    }: {
        xContent?: string,
        xPlatform?: string,
        xLocale?: string,
        xMp?: string,
    }): CancelablePromise<CustomerDataResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/_svc/customer-v1/customer',
            headers: {
                'X-Content': xContent,
                'X-Platform': xPlatform,
                'X-Locale': xLocale,
                'X-Mp': xMp,
            },
        });
    }

    /**
     * Customer Signup
     * @returns CustomerDataResponse Successful Response
     * @throws ApiError
     */
    public signup({
        xContent,
        xPlatform,
        xLocale,
        xMp,
        requestBody,
    }: {
        xContent?: string,
        xPlatform?: string,
        xLocale?: string,
        xMp?: string,
        requestBody?: SignupRequest,
    }): CancelablePromise<CustomerDataResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/_svc/customer-v1/customer',
            headers: {
                'X-Content': xContent,
                'X-Platform': xPlatform,
                'X-Locale': xLocale,
                'X-Mp': xMp,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Update customer details
     * @returns CustomerDataResponse Successful Response
     * @throws ApiError
     */
    public updateCustomer({
        xContent,
        xPlatform,
        xLocale,
        xMp,
        requestBody,
    }: {
        xContent?: string,
        xPlatform?: string,
        xLocale?: string,
        xMp?: string,
        requestBody?: CustomerUpdateRequest,
    }): CancelablePromise<CustomerDataResponse> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/_svc/customer-v1/customer',
            headers: {
                'X-Content': xContent,
                'X-Platform': xPlatform,
                'X-Locale': xLocale,
                'X-Mp': xMp,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

        /**
     * Update customer details
     * @returns CustomerDataResponse Successful Response
     * @throws ApiError
     */
    public updateV2Customer({
        xContent,
        xPlatform,
        xLocale,
        xMp,
        requestBody,
    }: {
        xContent?: string;
        xPlatform?: string;
        xLocale?: string;
        xMp?: string;
        requestBody?: CustomerUpdateRequestV2;
    }): CancelablePromise<CustomerDataResponseV2> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/_svc/noon-auth/customer/update',
            headers: {
                'X-Content': xContent,
                'X-Platform': xPlatform,
                'X-Locale': xLocale,
                'X-Mp': xMp,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    public getNationalityList({
        xContent,
        xPlatform,
        xLocale,
        xMp,
    }: {
        xContent?: string,
        xPlatform?: string,
        xLocale?: string,
        xMp?: string,
    }): CancelablePromise<GetNationalitiesResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/_svc/noon-auth/customer/nationality-countries',
            headers: {
                'X-Content': xContent,
                'X-Platform': xPlatform,
                'X-Locale': xLocale,
                'X-Mp': xMp,
            },
        });
    }

    public getV2Customer({
        xContent,
        xPlatform,
        xLocale,
        xMp,
    }: {
        xContent?: string,
        xPlatform?: string,
        xLocale?: string,
        xMp?: string,
    }): CancelablePromise<CustomerDataResponseV2> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/_svc/noon-auth/customer/get',
            headers: {
                'X-Content': xContent,
                'X-Platform': xPlatform,
                'X-Locale': xLocale,
                'X-Mp': xMp,
            },
        });
    }


    /**
     * Get customer Address
     * @returns CustomerAddressesResponse Successful Response
     * @throws ApiError
     */
    public getCustomerAddresses({
        xContent,
        xPlatform,
        xLocale,
        xMp,
    }: {
        xContent?: string,
        xPlatform?: string,
        xLocale?: string,
        xMp?: string,
    }): CancelablePromise<CustomerAddressesResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/_svc/customer-v1/customer/addresses',
            headers: {
                'X-Content': xContent,
                'X-Platform': xPlatform,
                'X-Locale': xLocale,
                'X-Mp': xMp,
            },
        });
    }

    /**
     * Create customer Address
     * @returns CreateCustomerAddressResponse Successful Response
     * @throws ApiError
     */
    public addCustomerAddress({
        xContent,
        xPlatform,
        xLocale,
        xMp,
        requestBody,
    }: {
        xContent?: string,
        xPlatform?: string,
        xLocale?: string,
        xMp?: string,
        requestBody?: CreateCustomerAddressRequest,
    }): CancelablePromise<CreateCustomerAddressResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/_svc/customer-v1/customer/addresses',
            headers: {
                'X-Content': xContent,
                'X-Platform': xPlatform,
                'X-Locale': xLocale,
                'X-Mp': xMp,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Check serviceability
     * @returns Location Successful Response
     * @throws ApiError
     */
    public checkServiceability({
        xContent,
        xPlatform,
        xLocale,
        xMp,
        requestBody,
    }: {
        xContent?: string,
        xPlatform?: string,
        xLocale?: string,
        xMp?: string,
        requestBody?: LatLngLiteral,
    }): CancelablePromise<Location> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/_svc/customer-v1/customer/public-area-serviceable',
            headers: {
                'X-Content': xContent,
                'X-Platform': xPlatform,
                'X-Locale': xLocale,
                'X-Mp': xMp,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Select new location
     * @returns any Successful Response
     * @throws ApiError
     */
    public selectNewLocation({
        xContent,
        xPlatform,
        xLocale,
        xMp,
        requestBody,
    }: {
        xContent?: string,
        xPlatform?: string,
        xLocale?: string,
        xMp?: string,
        requestBody?: LatLngLiteral,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/_svc/customer-v1/customer/location',
            headers: {
                'X-Content': xContent,
                'X-Platform': xPlatform,
                'X-Locale': xLocale,
                'X-Mp': xMp,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Select new address
     * @returns any Successful Response
     * @throws ApiError
     */
    public selectNewAddress({
        xContent,
        xPlatform,
        xLocale,
        xMp,
        requestBody,
    }: {
        xContent?: string,
        xPlatform?: string,
        xLocale?: string,
        xMp?: string,
        requestBody?: SelectAddressRequest,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/_svc/customer-v1/customer/addresses/v2',
            headers: {
                'X-Content': xContent,
                'X-Platform': xPlatform,
                'X-Locale': xLocale,
                'X-Mp': xMp,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Set default address
     * @returns CustomerAddressesResponse Successful Response
     * @throws ApiError
     */
    public setDefaultAddress({
        addressId,
        xContent,
        xPlatform,
        xLocale,
        xMp,
    }: {
        addressId: string,
        xContent?: string,
        xPlatform?: string,
        xLocale?: string,
        xMp?: string,
    }): CancelablePromise<CustomerAddressesResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/_svc/customer-v1/customer/addresses/{addressId}/default',
            path: {
                'addressId': addressId,
            },
            headers: {
                'X-Content': xContent,
                'X-Platform': xPlatform,
                'X-Locale': xLocale,
                'X-Mp': xMp,
            },
        });
    }

    /**
     * Get customer Addresses by ID
     * @returns CustomerAddressesResponse Successful Response
     * @throws ApiError
     */
    public getCustomerAddressesById({
        addressId,
        xContent,
        xPlatform,
        xLocale,
        xMp,
    }: {
        addressId: string,
        xContent?: string,
        xPlatform?: string,
        xLocale?: string,
        xMp?: string,
    }): CancelablePromise<CustomerAddressesResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/_svc/customer-v1/customer/addresses/{addressId}',
            path: {
                'addressId': addressId,
            },
            headers: {
                'X-Content': xContent,
                'X-Platform': xPlatform,
                'X-Locale': xLocale,
                'X-Mp': xMp,
            },
        });
    }

    /**
     * Update customer address
     * @returns CustomerAddressesResponse Successful Response
     * @throws ApiError
     */
    public updateCustomerAddress({
        addressId,
        xContent,
        xPlatform,
        xLocale,
        xMp,
        requestBody,
    }: {
        addressId: string,
        xContent?: string,
        xPlatform?: string,
        xLocale?: string,
        xMp?: string,
        requestBody?: CreateCustomerAddressRequest,
    }): CancelablePromise<CustomerAddressesResponse> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/_svc/customer-v1/customer/addresses/{addressId}',
            path: {
                'addressId': addressId,
            },
            headers: {
                'X-Content': xContent,
                'X-Platform': xPlatform,
                'X-Locale': xLocale,
                'X-Mp': xMp,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Remove customer address
     * @returns CustomerAddressesResponse Successful Response
     * @throws ApiError
     */
    public deleteCustomerAddress({
        addressId,
        xContent,
        xPlatform,
        xLocale,
        xMp,
    }: {
        addressId: string,
        xContent?: string,
        xPlatform?: string,
        xLocale?: string,
        xMp?: string,
    }): CancelablePromise<CustomerAddressesResponse> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/_svc/customer-v1/customer/addresses/{addressId}',
            path: {
                'addressId': addressId,
            },
            headers: {
                'X-Content': xContent,
                'X-Platform': xPlatform,
                'X-Locale': xLocale,
                'X-Mp': xMp,
            },
        });
    }

    /**
     * Update customer address on order
     * @returns ChangeResponse Successful Response
     * @throws ApiError
     */
    public updateOrderAddress({
        orderNr,
        xContent,
        xPlatform,
        xLocale,
        xMp,
        requestBody,
    }: {
        orderNr: string,
        xContent?: string,
        xPlatform?: string,
        xLocale?: string,
        xMp?: string,
        requestBody?: UpdateOrderAddressRequest,
    }): CancelablePromise<ChangeResponse> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/_svc/customer-v1/customer/orders/{orderNr}',
            path: {
                'orderNr': orderNr,
            },
            headers: {
                'X-Content': xContent,
                'X-Platform': xPlatform,
                'X-Locale': xLocale,
                'X-Mp': xMp,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get customer paymentcards
     * @returns GetPaymentCardsResponse Successful Response
     * @throws ApiError
     */
    public getCustomerPaymentcards({
        xContent,
        xPlatform,
        xLocale,
        xMp,
    }: {
        xContent?: string,
        xPlatform?: string,
        xLocale?: string,
        xMp?: string,
    }): CancelablePromise<GetPaymentCardsResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/_svc/customer-v1/customer/paymentcards',
            headers: {
                'X-Content': xContent,
                'X-Platform': xPlatform,
                'X-Locale': xLocale,
                'X-Mp': xMp,
            },
        });
    }

    /**
     * Set default paymentcard
     * @returns GetPaymentCardsResponse Successful Response
     * @throws ApiError
     */
    public setDefaultPaymentcard({
        cardId,
        xContent,
        xPlatform,
        xLocale,
        xMp,
    }: {
        cardId: string,
        xContent?: string,
        xPlatform?: string,
        xLocale?: string,
        xMp?: string,
    }): CancelablePromise<GetPaymentCardsResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/_svc/customer-v1/customer/paymentcards/{cardId}/default',
            path: {
                'cardId': cardId,
            },
            headers: {
                'X-Content': xContent,
                'X-Platform': xPlatform,
                'X-Locale': xLocale,
                'X-Mp': xMp,
            },
        });
    }

    /**
     * Delete paymentcard
     * @returns GetPaymentCardsResponse Successful Response
     * @throws ApiError
     */
    public deletePaymentcard({
        cardId,
        xContent,
        xPlatform,
        xLocale,
        xMp,
    }: {
        cardId: string,
        xContent?: string,
        xPlatform?: string,
        xLocale?: string,
        xMp?: string,
    }): CancelablePromise<GetPaymentCardsResponse> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/_svc/customer-v1/customer/paymentcards/{cardId}?fetch=full',
            path: {
                'cardId': cardId,
            },
            headers: {
                'X-Content': xContent,
                'X-Platform': xPlatform,
                'X-Locale': xLocale,
                'X-Mp': xMp,
            },
        });
    }

    /**
     * Get customer phones
     * @returns GetPhonesResponse Successful Response
     * @throws ApiError
     */
    public getCustomerPhones({
        xContent,
        xPlatform,
        xLocale,
        xMp,
    }: {
        xContent?: string,
        xPlatform?: string,
        xLocale?: string,
        xMp?: string,
    }): CancelablePromise<GetPhonesResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/_svc/customer-v1/customer/phones',
            headers: {
                'X-Content': xContent,
                'X-Platform': xPlatform,
                'X-Locale': xLocale,
                'X-Mp': xMp,
            },
        });
    }

    /**
     * Request phones OTP
     * @returns ChangeResponse Successful Response
     * @throws ApiError
     */
    public requestPhoneOtp({
        xContent,
        xPlatform,
        xLocale,
        xMp,
        requestBody,
    }: {
        xContent?: string,
        xPlatform?: string,
        xLocale?: string,
        xMp?: string,
        requestBody?: PhoneActionRequest,
    }): CancelablePromise<ChangeResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/_svc/customer-v1/customer/phone/verify',
            headers: {
                'X-Content': xContent,
                'X-Platform': xPlatform,
                'X-Locale': xLocale,
                'X-Mp': xMp,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Verify OTP
     * @returns ChangeResponse Successful Response
     * @throws ApiError
     */
    public verifyPhoneOtp({
        xContent,
        xPlatform,
        xLocale,
        xMp,
        requestBody,
    }: {
        xContent?: string,
        xPlatform?: string,
        xLocale?: string,
        xMp?: string,
        requestBody?: VerifyOTPRequest,
    }): CancelablePromise<ChangeResponse> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/_svc/customer-v1/customer/phone/verify',
            headers: {
                'X-Content': xContent,
                'X-Platform': xPlatform,
                'X-Locale': xLocale,
                'X-Mp': xMp,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Request phones Callback
     * @returns ChangeResponse Successful Response
     * @throws ApiError
     */
    public requestPhoneCallback({
        xContent,
        xPlatform,
        xLocale,
        xMp,
        requestBody,
    }: {
        xContent?: string,
        xPlatform?: string,
        xLocale?: string,
        xMp?: string,
        requestBody?: PhoneActionRequest,
    }): CancelablePromise<ChangeResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/_svc/customer-v1/customer/phone-callback',
            headers: {
                'X-Content': xContent,
                'X-Platform': xPlatform,
                'X-Locale': xLocale,
                'X-Mp': xMp,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get Phone code
     * @returns PhoneCodesResponse Successful Response
     * @throws ApiError
     */
    public getPhoneCodes({
        xContent,
        xPlatform,
        xLocale,
        xMp,
    }: {
        xContent?: string,
        xPlatform?: string,
        xLocale?: string,
        xMp?: string,
    }): CancelablePromise<PhoneCodesResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/_svc/customer-v1/country-phone-codes',
            headers: {
                'X-Content': xContent,
                'X-Platform': xPlatform,
                'X-Locale': xLocale,
                'X-Mp': xMp,
            },
        });
    }

    /**
     * Get customer wallet
     * @returns GetCustomerWalletResponse Successful Response
     * @throws ApiError
     */
    public getCustomerWallet({
        prevToken,
        nextToken,
        xPlatform,
        xExperience = 'ecom',
        xMp,
        xLocale,
    }: {
        prevToken?: string,
        nextToken?: string,
        /**
         * Platform eg web ios android
         */
        xPlatform?: string,
        /**
         * The experience for this marketplace eg. ecom or food
         */
        xExperience?: string,
        /**
         * Marketplace code
         */
        xMp?: string,
        /**
         * Locale eg en-ae
         */
        xLocale?: string,
    }): CancelablePromise<GetCustomerWalletResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/_svc/customer-v1/credit',
            headers: {
                'X-Platform': xPlatform,
                'X-Experience': xExperience,
                'X-Mp': xMp,
                'X-Locale': xLocale,
            },
            query: {
                'prevToken': prevToken,
                'nextToken': nextToken,
            },
        });
    }

    /**
     * Get customer's expiring credits
     * @returns CustomerExpiringCredits Successful Response
     * @throws ApiError
     */
    public getCustomerExpiryCredit({
        prevToken,
        nextToken,
        xPlatform,
        xExperience = 'ecom',
        xMp,
        xLocale,
    }: {
        prevToken?: string,
        nextToken?: string,
        /**
         * Platform eg web ios android
         */
        xPlatform?: string,
        /**
         * The experience for this marketplace eg. ecom or food
         */
        xExperience?: string,
        /**
         * Marketplace code
         */
        xMp?: string,
        /**
         * Locale eg en-ae
         */
        xLocale?: string,
    }): CancelablePromise<CustomerExpiringCredits> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/_svc/customer-v1/expiring-credit',
            headers: {
                'X-Platform': xPlatform,
                'X-Experience': xExperience,
                'X-Mp': xMp,
                'X-Locale': xLocale,
            },
            query: {
                'prevToken': prevToken,
                'nextToken': nextToken,
            },
        });
    }

    /**
     * Redeem gift card
     * @returns GetCustomerWalletResponse Successful Response
     * @throws ApiError
     */
    public redeemGiftCard({
        requestBody,
        xPlatform,
        xExperience = 'ecom',
        xMp,
        xLocale,
    }: {
        requestBody: RedeemGiftCardRequest,
        /**
         * Platform eg web ios android
         */
        xPlatform?: string,
        /**
         * The experience for this marketplace eg. ecom or food
         */
        xExperience?: string,
        /**
         * Marketplace code
         */
        xMp?: string,
        /**
         * Locale eg en-ae
         */
        xLocale?: string,
    }): CancelablePromise<GetCustomerWalletResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/_svc/customer-v1/gift-card',
            headers: {
                'X-Platform': xPlatform,
                'X-Experience': xExperience,
                'X-Mp': xMp,
                'X-Locale': xLocale,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}

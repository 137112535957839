/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ProxyCapturePaymentPayload } from '../models/ProxyCapturePaymentPayload';
import type { ValuSingleSyncPayload } from '../models/ValuSingleSyncPayload';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ProxyService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Return URL for PG transactions
     * @returns any Successful Response
     * @throws ApiError
     */
    public returningPaymentReturningTokenGet({
        token,
        redirectResult,
        xMp,
    }: {
        token: string,
        redirectResult?: string,
        /**
         * Marketplace code (default is noon)
         */
        xMp?: any,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/_svc/payment/returning/{token}',
            path: {
                'token': token,
            },
            headers: {
                'x-mp': xMp,
            },
            query: {
                'redirectResult': redirectResult,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Capture payment proxy
     * @returns any Successful Response
     * @throws ApiError
     */
    public valuCaptureCaptureTokenPost({
        token,
        requestBody,
        xMp,
    }: {
        token: string,
        requestBody: ProxyCapturePaymentPayload,
        /**
         * Marketplace code (default is noon)
         */
        xMp?: any,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/_svc/payment/capture/{token}',
            path: {
                'token': token,
            },
            headers: {
                'x-mp': xMp,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * OTP Web View
     * @returns any Successful Response
     * @throws ApiError
     */
    public valuOtpOtpTokenGet({
        token,
        xMp,
    }: {
        token: string,
        /**
         * Marketplace code (default is noon)
         */
        xMp?: any,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/_svc/payment/otp/{token}',
            path: {
                'token': token,
            },
            headers: {
                'x-mp': xMp,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Manual sync to valu in the event of timeout/unsync
     * @returns any Successful Response
     * @throws ApiError
     */
    public valuSyncValuSyncPost({
        requestBody,
        xMp,
    }: {
        requestBody: ValuSingleSyncPayload,
        /**
         * Marketplace code (default is noon)
         */
        xMp?: any,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/_svc/payment/valu/sync',
            headers: {
                'x-mp': xMp,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}

/* eslint-disable no-nested-ternary */

import { useTranslation } from 'react-i18next';
import Select, { components, Options, StylesConfig } from 'react-select';
import clsx from 'clsx';

import FieldLayout from '@nm-namshi-frontend/core/components/forms/FieldLayout';
import Icon from '@nm-namshi-frontend/core/components/Icon';

import { isSchmatalog } from '../../utils/helper';

const DEFAULT_MIN_HEIGHT = 40;

type TProps = {
    customSelectClassName?: string;
    disabled?: boolean;
    error?: string;
    helperText?: string;
    id: string;
    label?: string;
    options: Options<any>; // This allows passed in options to be complex objects and not just strings. Might need to rethink how this component was built
    onChange: (value: any) => void; // This allows passed in options to be complex objects and not just strings. Might need to rethink how this component was built
    placeholder?: string;
    rtlDisabled?: boolean; // used for phone numbers in arabic
    tabIndex?: number;
    value: any; // This allows passed in options to be complex objects and not just strings. Might need to rethink how this component was built
    defaultMenuIsOpen?: boolean; // for country code select..
    isLoading?: boolean;
    isInlineLabel?: boolean;
    isSearchable?: boolean;
    menuPortalTarget?: HTMLElement | null;
    onBlur?: any;
    minHeight?: number;
    maxHeight?: number;
    placement?: 'auto' | 'bottom' | 'top';
    size?: 'small' | 'medium';
    variant?: 'default' | 'no-border';
};

// attention cli: nipun gave up here. But will replace with proper type later
const DropdownIndicator = (props: any) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <components.DropdownIndicator {...props}>
        {/* eslint-disable-next-line react/destructuring-assignment */}
        <Icon name={props.selectProps.menuIsOpen ? 'chevronUpBold' : 'chevronDownBold'} size={14} />
    </components.DropdownIndicator>
);

const SelectElement = ({
    customSelectClassName,
    defaultMenuIsOpen = false,
    disabled,
    error,
    helperText,
    id,
    isInlineLabel = false,
    isLoading = false,
    isSearchable = true,
    label,
    maxHeight,
    menuPortalTarget,
    minHeight = DEFAULT_MIN_HEIGHT,
    onBlur,
    onChange,
    options,
    placeholder,
    placement = 'auto',
    rtlDisabled = false,
    size = 'medium',
    tabIndex,
    value,
    variant = 'default',
}: TProps): JSX.Element => {
    const { t } = useTranslation('common');
    const isMobile = isSchmatalog();

    /* attention cli, this is nipun - the false right here    vvvvv  is for multiselect */
    /* not sure how to handle it best */
    /* should we keep it false? */
    const customStyles: StylesConfig<Record<string, unknown>, false> = {
        container: () => ({
            position: 'relative',
            width: '100%',
        }),
        control: (provided, state) => ({
            ...provided,
            backgroundColor: state.isDisabled ? 'var(--color-grey4)' : '',
            borderColor: state.isFocused
                ? 'var(--clr-dark)'
                : error
                ? 'var(--color-system-red)'
                : state.isDisabled
                ? 'var(--color-grey3)'
                : 'var(--clr-grey-l2)',
            borderRadius: '0',
            cursor: state.isDisabled ? 'not-allowed' : 'default',
            transition: 'none',
            boxShadow: 'none',
            minHeight: size === 'small' ? '12px' : 'var(--field-height)',
            fontSize: '16px',
            height: '100%',
            padding: '2px 10px 2px 12px',
            '&:hover': {
                borderColor: state.isFocused
                    ? 'var(--clr-dark)'
                    : state.isDisabled
                    ? 'var(--color-grey3)'
                    : 'var(--color-grey1)',
                cursor: state.isDisabled ? 'not-allowed' : 'pointer',
            },
            ...(variant === 'no-border'
                ? {
                      padding: 0,
                      gap: 30,
                      border: 'none',
                  }
                : {}),
        }),
        placeholder: (provided) => ({
            ...provided,
            color: 'var(--color-grey2)',
        }),
        valueContainer: (provided) => ({
            ...provided,
            overflow: 'inherit',
            padding: isMobile ? '' : '0 12px 0 0' /* this should be 16 but react adds 2px horz padding */,
            color: 'var(--clr-dark)',
            fontSize: '16px',
            height: '100%',
        }),
        singleValue: (provided, state) => ({
            ...provided,
            fontSize: size === 'small' ? '12px' : '16px',
            color: state.isDisabled ? 'var(--color-grey2)' : 'var(--clr-dark)',
            fontWeight: 600,
        }),
        indicatorSeparator: () => ({ display: 'none' }),
        dropdownIndicator: (provided, state) => ({
            ...provided,
            padding: 0,
            opacity: state.isDisabled ? '0.3' : '1',
        }),
        menu: (provided) => ({
            ...provided,
            boxShadow: '0 3px 6px rgba(0, 0, 0, 0.1)',
            border: '1px solid',
            borderColor: 'var(--color-grey3)',
            zIndex: 12,
            borderRadius: 0,
        }),
        option: (provided, state) => ({
            ...provided,
            padding: size === 'small' ? '4px 10px' : '8px 16px',
            fontSize: size === 'small' ? '12px' : '16px',
            fontWeight: 400,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            backgroundColor: state.isSelected
                ? 'var(--clr-dark)'
                : state.isFocused
                ? state.isDisabled
                    ? 'var(--color-grey4)'
                    : 'var(--color-system-blue-tint)'
                : 'transparent',
            // eslint-disable-next-line no-nested-ternary
            color: state.isSelected ? 'var(--clr-white)' : state.isDisabled ? 'var(--color-grey3)' : 'inherit',
            '&:hover': {
                backgroundColor: state.isSelected ? 'var(--clr-dark)' : 'var(--color-system-blue-tint)',
                cursor: 'pointer',
            },
            pointerEvents: state.isDisabled ? 'none' : 'auto',
        }),
        menuPortal: (base) => ({ ...base, zIndex: 999 }),
    };

    const selectedOptionObj = options.find((option) => option.value === value);

    return (
        <FieldLayout label={label} error={error} helperText={helperText} isInlineLabel={isInlineLabel}>
            <div className={clsx(customSelectClassName)}>
                <Select
                    onBlur={onBlur}
                    classNamePrefix="selectComponent"
                    components={{ DropdownIndicator }}
                    instanceId={id}
                    isDisabled={disabled}
                    onChange={(opt) => onChange(opt?.value)}
                    options={options}
                    menuPlacement={placement}
                    placeholder={placeholder || t('shared.select')}
                    styles={customStyles}
                    tabIndex={tabIndex}
                    maxMenuHeight={maxHeight}
                    value={selectedOptionObj || ''}
                    defaultMenuIsOpen={defaultMenuIsOpen}
                    isLoading={isLoading}
                    isSearchable={isSearchable}
                    menuPortalTarget={menuPortalTarget}
                />
            </div>
        </FieldLayout>
    );
};

export default SelectElement;

/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CmsPageResponse } from '../models/CmsPageResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class PageService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get CMS for a particulat page
     * @returns CmsPageResponse Successful Response
     * @throws ApiError
     */
    public getPageCms({
        pageName,
        xVisitorId,
        xAddressKey,
        xLocale,
        xForwardedUser,
        xPlatform,
        xContent,
        xDebug,
    }: {
        pageName: string,
        /**
         * Visitor ID
         */
        xVisitorId?: any,
        /**
         * Address Key
         */
        xAddressKey?: any,
        /**
         * Locale
         */
        xLocale?: any,
        /**
         * Customer Code
         */
        xForwardedUser?: any,
        /**
         * Platform
         */
        xPlatform?: any,
        /**
         * Content
         */
        xContent?: any,
        /**
         * Debug Mode
         */
        xDebug?: any,
    }): CancelablePromise<CmsPageResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/_svc/catalog/cms/{page_name}',
            path: {
                'page_name': pageName,
            },
            headers: {
                'X-Visitor-Id': xVisitorId,
                'X-Address-Key': xAddressKey,
                'X-Locale': xLocale,
                'X-Forwarded-User': xForwardedUser,
                'X-Platform': xPlatform,
                'X-Content': xContent,
                'X-Debug': xDebug,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * get the bag page
     * @returns CmsPageResponse Successful Response
     * @throws ApiError
     */
    public getBag({
        xVisitorId,
        xAddressKey,
        xAddresskey,
        xLocale,
        xForwardedUser,
        xPlatform,
    }: {
        /**
         * Visitor ID
         */
        xVisitorId?: any,
        /**
         * Address Key
         */
        xAddressKey?: any,
        /**
         * Old Address Key
         */
        xAddresskey?: any,
        /**
         * Locale
         */
        xLocale?: any,
        /**
         * Customer Code
         */
        xForwardedUser?: any,
        /**
         * Platform
         */
        xPlatform?: any,
    }): CancelablePromise<CmsPageResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/_svc/order/bag',
            headers: {
                'X-Visitor-Id': xVisitorId,
                'X-Address-Key': xAddressKey,
                'X-Addresskey': xAddresskey,
                'X-Locale': xLocale,
                'X-Forwarded-User': xForwardedUser,
                'X-Platform': xPlatform,
            },
        });
    }

}

import { create } from 'zustand';

import { getApiInstance } from '@nm-namshi-frontend/core/api';
import type { SigninRequest, SignupRequest, ResetPasswordRequest } from '@nm-namshi-frontend/services';
import { deleteCookie, getCookie, setCookie } from '@nm-namshi-frontend/core/utils/helper';

export type TAuthenticationStore = {
    emailId: string;
    signInData: object;
    isLoadingSignIn: boolean;
    signUpData: object;
    isLoadingSignUp: boolean;
    updateEmailId: (value: string) => void;
    clearEmailId: () => void;
    signIn: (value: SigninRequest) => void;
    isLoadingResetPassword: boolean;
    resetPassword: (value: ResetPasswordRequest) => void;
    signUp: (value: SignupRequest) => void;
    isLoggedInToNamshi: boolean;
    setIsLoggedInToNamshi: (val: boolean) => void;
    isLoggedInToNoon: boolean;
    setIsLoggedInToNoon: (val: boolean) => void;
    ongoingMigrationHash: string | null;
    setOngoingMigrationHash: (val: string) => void;
    clearOngoingMigrationHash: () => void;
    isVerifyEmailOpen: boolean;
    isNotCleanUser: boolean;
    v1LoginProvider: 'namshi' | 'apple' | 'google' | 'facebook' | string;
    setV1LoginProvider: (val: string) => void;
    setIsNotClean: (val: boolean) => void;
    setIsVerifyEmailOpen: (val: boolean) => void;
};

const useAuthenticationStore = create<TAuthenticationStore>((set, get) => ({
    emailId: '',
    signInData: {},
    signUpData: {},
    isLoadingSignIn: false,
    isLoadingSignUp: false,
    isLoadingResetPassword: false,
    isLoggedInToNamshi: false,
    isLoggedInToNoon: false,
    ongoingMigrationHash: getCookie('ongoing-migration-hash'),
    isVerifyEmailOpen: false,
    isNotCleanUser: false,
    v1LoginProvider: '',

    updateEmailId: (emailId: string) => {
        set(() => ({
            emailId,
        }));
    },
    clearEmailId: () => {
        set(() => ({
            emailId: '',
        }));
    },
    signIn: async (requestBody: SigninRequest) => {
        set({ isLoadingSignIn: true });
        try {
            const signInData = await getApiInstance().auth.signin({ requestBody });
            set({ isLoadingSignIn: false, signInData });
        } catch (error) {
            set({ isLoadingSignIn: false, signInData: {} });
        }
    },
    signUp: async (requestBody: SignupRequest) => {
        set({ isLoadingSignUp: true });
        try {
            const signUpData = await getApiInstance().customer.signup(requestBody);
            set({ isLoadingSignUp: false, signUpData });
        } catch (error) {
            set({ isLoadingSignUp: false, signUpData: {} });
        }
    },
    resetPassword: async (requestBody: ResetPasswordRequest) => {
        set({ isLoadingResetPassword: true });
        try {
            await getApiInstance().auth.resetPassword({ requestBody });
            set({ isLoadingResetPassword: false });
        } catch (error) {
            set({ isLoadingResetPassword: false });
        }
    },
    setIsLoggedInToNamshi: (isLoggedInToNamshi: boolean) => {
        set(() => ({
            isLoggedInToNamshi,
        }));
    },
    setIsLoggedInToNoon: (isLoggedInToNoon: boolean) => {
        set(() => ({
            isLoggedInToNoon,
        }));
    },

    setOngoingMigrationHash: (last: string) => {
        setCookie('ongoing-migration-hash', last);
        set(() => ({
            ongoingMigrationHash: last,
        }));
    },

    setIsNotClean: (value: boolean) => {
        set(() => ({
            isNotCleanUser: value,
        }));
    },

    setV1LoginProvider: (value: string) => {
        set(() => ({
            v1LoginProvider: value,
        }));
    },

    setIsVerifyEmailOpen: (value: boolean) => {
        set(() => ({
            isVerifyEmailOpen: value,
        }));
    },

    clearOngoingMigrationHash: () => {
        deleteCookie('ongoing-migration-hash');
        set(() => ({
            ongoingMigrationHash: null,
        }));
    },
}));

export default useAuthenticationStore;

/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CollectionPointResponse } from '../models/CollectionPointResponse';
import type { LatLng } from '../models/LatLng';
import type { Location } from '../models/Location';
import type { ShortAddressRequest } from '../models/ShortAddressRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class GeoService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get Serviceability Zone
     * @returns any Successful Response
     * @throws ApiError
     */
    public getServiceabilityZone({
        lat,
        lng,
        xVisitorId,
        xAddressKey,
        xLocale,
        xForwardedUser,
        xPlatform,
        xContent,
        xDebug,
    }: {
        lat?: number,
        lng?: number,
        /**
         * Visitor ID
         */
        xVisitorId?: any,
        /**
         * Address Key
         */
        xAddressKey?: any,
        /**
         * Locale
         */
        xLocale?: any,
        /**
         * Customer Code
         */
        xForwardedUser?: any,
        /**
         * Platform
         */
        xPlatform?: any,
        /**
         * Content
         */
        xContent?: any,
        /**
         * Debug Mode
         */
        xDebug?: any,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/_svc/catalog/get_serviceability_zone',
            headers: {
                'X-Visitor-Id': xVisitorId,
                'X-Address-Key': xAddressKey,
                'X-Locale': xLocale,
                'X-Forwarded-User': xForwardedUser,
                'X-Platform': xPlatform,
                'X-Content': xContent,
                'X-Debug': xDebug,
            },
            query: {
                'lat': lat,
                'lng': lng,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Rocket Features
     * @returns any Successful Response
     * @throws ApiError
     */
    public getRocketFeatures({
        xVisitorId,
        xAddressKey,
        xLocale,
        xForwardedUser,
        xPlatform,
        xContent,
        xDebug,
    }: {
        /**
         * Visitor ID
         */
        xVisitorId?: any,
        /**
         * Address Key
         */
        xAddressKey?: any,
        /**
         * Locale
         */
        xLocale?: any,
        /**
         * Customer Code
         */
        xForwardedUser?: any,
        /**
         * Platform
         */
        xPlatform?: any,
        /**
         * Content
         */
        xContent?: any,
        /**
         * Debug Mode
         */
        xDebug?: any,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/_svc/catalog/rocket_features',
            headers: {
                'X-Visitor-Id': xVisitorId,
                'X-Address-Key': xAddressKey,
                'X-Locale': xLocale,
                'X-Forwarded-User': xForwardedUser,
                'X-Platform': xPlatform,
                'X-Content': xContent,
                'X-Debug': xDebug,
            },
        });
    }

    /**
     * Get Serviceability Zone Codes
     * @returns any Successful Response
     * @throws ApiError
     */
    public getServiceabilityZoneCodes({
        xVisitorId,
        xAddressKey,
        xLocale,
        xForwardedUser,
        xPlatform,
        xContent,
        xDebug,
    }: {
        /**
         * Visitor ID
         */
        xVisitorId?: any,
        /**
         * Address Key
         */
        xAddressKey?: any,
        /**
         * Locale
         */
        xLocale?: any,
        /**
         * Customer Code
         */
        xForwardedUser?: any,
        /**
         * Platform
         */
        xPlatform?: any,
        /**
         * Content
         */
        xContent?: any,
        /**
         * Debug Mode
         */
        xDebug?: any,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/_svc/catalog/get_serviceability_zone_codes',
            headers: {
                'X-Visitor-Id': xVisitorId,
                'X-Address-Key': xAddressKey,
                'X-Locale': xLocale,
                'X-Forwarded-User': xForwardedUser,
                'X-Platform': xPlatform,
                'X-Content': xContent,
                'X-Debug': xDebug,
            },
        });
    }

    /**
     * Get area from location
     * @returns Location Successful Response
     * @throws ApiError
     */
    public getAreaFromLocation({
        xContent,
        xPlatform,
        xLocale,
        xMp,
        requestBody,
    }: {
        xContent?: string,
        xPlatform?: string,
        xLocale?: string,
        xMp?: string,
        requestBody?: LatLng,
    }): CancelablePromise<Location> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/_svc/geo-v1/area',
            headers: {
                'X-Content': xContent,
                'X-Platform': xPlatform,
                'X-Locale': xLocale,
                'X-Mp': xMp,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get default city
     * @returns Location Successful Response
     * @throws ApiError
     */
    public getDefaultLocation({
        xContent,
        xPlatform,
        xLocale,
        xMp,
        requestBody,
    }: {
        xContent?: string,
        xPlatform?: string,
        xLocale?: string,
        xMp?: string,
        requestBody?: LatLng,
    }): CancelablePromise<Location> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/_svc/geo-v1/city-default',
            headers: {
                'X-Content': xContent,
                'X-Platform': xPlatform,
                'X-Locale': xLocale,
                'X-Mp': xMp,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get short address
     * @returns LatLng Successful Response
     * @throws ApiError
     */
    public getShortAddress({
        xContent,
        xPlatform,
        xLocale,
        xMp,
        requestBody,
    }: {
        xContent?: string,
        xPlatform?: string,
        xLocale?: string,
        xMp?: string,
        requestBody?: ShortAddressRequest,
    }): CancelablePromise<LatLng> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/_svc/geo-v1/ksa-na/geo_code_by_short_address',
            headers: {
                'X-Content': xContent,
                'X-Platform': xPlatform,
                'X-Locale': xLocale,
                'X-Mp': xMp,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get collection points
     * @returns CollectionPointResponse Successful Response
     * @throws ApiError
     */
    public getCollectionPoints({
        xContent,
        xPlatform,
        xLocale,
        xMp,
    }: {
        xContent?: string,
        xPlatform?: string,
        xLocale?: string,
        xMp?: string,
    }): CancelablePromise<CollectionPointResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/_svc/geo-v1/collection-point',
            headers: {
                'X-Content': xContent,
                'X-Platform': xPlatform,
                'X-Locale': xLocale,
                'X-Mp': xMp,
            },
        });
    }

}

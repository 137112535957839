/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateExchangeRequest } from '../models/CreateExchangeRequest';
import type { CreateExchangeResponse } from '../models/CreateExchangeResponse';
import type { GetExchangeListResponse } from '../models/GetExchangeListResponse';
import type { ListRequest } from '../models/ListRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ExchangesService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Create exchange
     * @returns CreateExchangeResponse Successful Response
     * @throws ApiError
     */
    public createExchange({
        requestBody,
        xVisitorId,
        xAddressKey,
        xAddresskey,
        xLocale,
        xForwardedUser,
        xPlatform,
    }: {
        requestBody: CreateExchangeRequest,
        /**
         * Visitor ID
         */
        xVisitorId?: any,
        /**
         * Address Key
         */
        xAddressKey?: any,
        /**
         * Old Address Key
         */
        xAddresskey?: any,
        /**
         * Locale
         */
        xLocale?: any,
        /**
         * Customer Code
         */
        xForwardedUser?: any,
        /**
         * Platform
         */
        xPlatform?: any,
    }): CancelablePromise<CreateExchangeResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/_svc/order/aftersales/exchanges/create',
            headers: {
                'X-Visitor-Id': xVisitorId,
                'X-Address-Key': xAddressKey,
                'X-Addresskey': xAddresskey,
                'X-Locale': xLocale,
                'X-Forwarded-User': xForwardedUser,
                'X-Platform': xPlatform,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get all exchanges
     * @returns GetExchangeListResponse Successful Response
     * @throws ApiError
     */
    public getExchangeList({
        requestBody,
        xVisitorId,
        xAddressKey,
        xAddresskey,
        xLocale,
        xForwardedUser,
        xPlatform,
    }: {
        requestBody: ListRequest,
        /**
         * Visitor ID
         */
        xVisitorId?: any,
        /**
         * Address Key
         */
        xAddressKey?: any,
        /**
         * Old Address Key
         */
        xAddresskey?: any,
        /**
         * Locale
         */
        xLocale?: any,
        /**
         * Customer Code
         */
        xForwardedUser?: any,
        /**
         * Platform
         */
        xPlatform?: any,
    }): CancelablePromise<GetExchangeListResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/_svc/order/aftersales/exchanges/list',
            headers: {
                'X-Visitor-Id': xVisitorId,
                'X-Address-Key': xAddressKey,
                'X-Addresskey': xAddresskey,
                'X-Locale': xLocale,
                'X-Forwarded-User': xForwardedUser,
                'X-Platform': xPlatform,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}

/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CustomerDataResponse } from '../models/CustomerDataResponse';
import type { ErrorResponse } from '../models/ErrorResponse';
import type { InitAuthFlowResponse } from '../models/InitAuthFlowResponse';
import type { NamshiResetPasswordRequest } from '../models/NamshiResetPasswordRequest';
import type { PayloadWithAccessTokenAndProvider } from '../models/PayloadWithAccessTokenAndProvider';
import type { PayloadWithCodeAndProvider } from '../models/PayloadWithCodeAndProvider';
import type { PayloadWithEmailAndPassword } from '../models/PayloadWithEmailAndPassword';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class NamshiAuthService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Initialize Auth Flow
     * @returns InitAuthFlowResponse Successful Response
     * @throws ApiError
     */
    public initAuthFlow({
        requestBody,
        xContent,
        xPlatform,
        xLocale,
        xMp,
        v2Frontend = true,
    }: {
        requestBody: any,
        xContent?: string,
        xPlatform?: string,
        xLocale?: string,
        xMp?: string,
        v2Frontend?: boolean,
    }): CancelablePromise<InitAuthFlowResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/_svc/namshi-migration/customer/check-email',
            headers: {
                'X-Content': xContent,
                'X-Platform': xPlatform,
                'X-Locale': xLocale,
                'X-Mp': xMp,
                'v2-frontend': v2Frontend,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Namshi Sign in
     * @returns any Successful Response
     * @throws ApiError
     */
    public namshiSignin({
        xContent,
        xPlatform,
        xLocale,
        xMp,
        requestBody,
    }: {
        xContent?: string,
        xPlatform?: string,
        xLocale?: string,
        xMp?: string,
        requestBody?: (PayloadWithEmailAndPassword | PayloadWithCodeAndProvider | PayloadWithAccessTokenAndProvider),
    }): CancelablePromise<(CustomerDataResponse | ErrorResponse)> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/_svc/namshi-login/login',
            headers: {
                'X-Content': xContent,
                'X-Platform': xPlatform,
                'X-Locale': xLocale,
                'X-Mp': xMp,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Namshi Reset Password
     * @returns any Successful Response
     * @throws ApiError
     */
    public namshiResetPassword({
        email,
        xContent,
        xPlatform,
        xLocale,
        xMp,
        requestBody,
    }: {
        email: string,
        xContent?: string,
        xPlatform?: string,
        xLocale?: string,
        xMp?: string,
        requestBody?: NamshiResetPasswordRequest,
    }): CancelablePromise<(CustomerDataResponse | ErrorResponse)> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/_svc/namshi-login/password-reset-request/{email}',
            path: {
                'email': email,
            },
            headers: {
                'X-Content': xContent,
                'X-Platform': xPlatform,
                'X-Locale': xLocale,
                'X-Mp': xMp,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Namshi Signout
     * @returns any Successful Response
     * @throws ApiError
     */
    public namshiSignout({
        xContent,
        xPlatform,
        xLocale,
        xMp = 'namshi_v2',
    }: {
        xContent?: string,
        xPlatform?: string,
        xLocale?: string,
        xMp?: string,
    }): CancelablePromise<(CustomerDataResponse | ErrorResponse)> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/_svc/namshi-login/auth/logout',
            headers: {
                'X-Content': xContent,
                'X-Platform': xPlatform,
                'X-Locale': xLocale,
                'X-Mp': xMp,
            },
        });
    }

}

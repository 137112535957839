/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetLegacyCustomerResponse } from '../models/GetLegacyCustomerResponse';
import type { GetMigrationHashRequest } from '../models/GetMigrationHashRequest';
import type { InitMigrationFlowRequest } from '../models/InitMigrationFlowRequest';
import type { MigrationSummaryResponse } from '../models/MigrationSummaryResponse';
import type { StartMigrationRequest } from '../models/StartMigrationRequest';
import type { TrackMigrationStatusResponse } from '../models/TrackMigrationStatusResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class CustomerMigrationService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Initialize customer migration flow
     * @returns any Successful Response
     * @throws ApiError
     */
    public initMigrationFlow({
        requestBody,
        xContent,
        xPlatform,
        xLocale,
        xMp = 'namshi_v2',
    }: {
        requestBody: InitMigrationFlowRequest,
        xContent?: string,
        xPlatform?: string,
        xLocale?: string,
        xMp?: string,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/_svc/api-namshi-jerry/migration/init',
            headers: {
                'X-Content': xContent,
                'X-Platform': xPlatform,
                'X-Locale': xLocale,
                'X-Mp': xMp,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get migration hash
     * @returns any Successful Response
     * @throws ApiError
     */
    public getMigrationHash({
        requestBody,
        xContent,
        xPlatform,
        xLocale,
        xMp = 'namshi_v2',
    }: {
        requestBody: GetMigrationHashRequest,
        xContent?: string,
        xPlatform?: string,
        xLocale?: string,
        xMp?: string,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/_svc/api-namshi-jerry/customers/migrate',
            headers: {
                'X-Content': xContent,
                'X-Platform': xPlatform,
                'X-Locale': xLocale,
                'X-Mp': xMp,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get migration status
     * @returns TrackMigrationStatusResponse Successful Response
     * @throws ApiError
     */
    public getMigrationStatus({
        migrationHash,
        xContent,
        xPlatform,
        xLocale,
        xMp = 'namshi_v2',
    }: {
        migrationHash: string,
        xContent?: string,
        xPlatform?: string,
        xLocale?: string,
        xMp?: string,
    }): CancelablePromise<TrackMigrationStatusResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/_svc/customer-migration/customer/migration/{migrationHash}',
            path: {
                'migrationHash': migrationHash,
            },
            headers: {
                'X-Content': xContent,
                'X-Platform': xPlatform,
                'X-Locale': xLocale,
                'X-Mp': xMp,
            },
        });
    }

    /**
     * Start Migration
     * @returns any Successful Response
     * @throws ApiError
     */
    public startMigration({
        requestBody,
        xContent,
        xPlatform,
        xLocale,
        xMp = 'namshi_v2',
    }: {
        requestBody: StartMigrationRequest,
        xContent?: string,
        xPlatform?: string,
        xLocale?: string,
        xMp?: string,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/_svc/customer-migration/customer/migrate',
            headers: {
                'X-Content': xContent,
                'X-Platform': xPlatform,
                'X-Locale': xLocale,
                'X-Mp': xMp,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get migration summary
     * @returns MigrationSummaryResponse Successful Response
     * @throws ApiError
     */
    public migrationSummary({
        xContent,
        xPlatform,
        xLocale,
        xMp = 'namshi_v2',
    }: {
        xContent?: string,
        xPlatform?: string,
        xLocale?: string,
        xMp?: string,
    }): CancelablePromise<MigrationSummaryResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/_svc/customer-migration/customer/migrations/summary',
            headers: {
                'X-Content': xContent,
                'X-Platform': xPlatform,
                'X-Locale': xLocale,
                'X-Mp': xMp,
            },
        });
    }

    /**
     * Get legacy customer data
     * @returns GetLegacyCustomerResponse Successful Response
     * @throws ApiError
     */
    public getLegacyCustomer({
        xContent,
        xPlatform,
        xLocale,
        xMp = 'namshi_v2',
    }: {
        xContent?: string,
        xPlatform?: string,
        xLocale?: string,
        xMp?: string,
    }): CancelablePromise<GetLegacyCustomerResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/_svc/api-namshi-jerry/customers/self',
            headers: {
                'X-Content': xContent,
                'X-Platform': xPlatform,
                'X-Locale': xLocale,
                'X-Mp': xMp,
            },
        });
    }

}

'use client';

import { sendGTMEvent } from '@next/third-parties/google';

import { isBrowser } from './helper';

export const addToDataLayer = (eventData: any): void => {
    if (isBrowser()) {
        sendGTMEvent(eventData);
    }
};

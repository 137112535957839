import React from 'react';

import clsx from 'clsx';

import DiscountTag from '@nm-namshi-frontend/core/page_components/shared/product/DiscountTag';
import { TConversionRates } from '@nm-namshi-frontend/core/types';
import useConfigContext from '@nm-namshi-frontend/core/contexts/ConfigContext';
import useAppContext from '@nm-namshi-frontend/core/contexts/AppContext';

import { convertPrice, formatPrice } from '../../../utils/helper';
import styles from './ProductPrice.module.scss';

type TProps = {
    price: number;
    salePrice?: number;
    size?: 'small' | 'regular' | 'large' | 'x-large';
    discountPercent?: number;
    showDiscount?: boolean;
    hideSalePrice?: boolean;
    currency?: string;
    style?: string;
    priceStyle?: { old?: string; sell?: string };
    priceStyleObj?: { old?: React.CSSProperties; sell?: React.CSSProperties };
    isVertical?: boolean;
    emphasizeValue?: boolean;
    conversionRates?: TConversionRates;
    containerClassname?: string;
};

export default function Price({
    containerClassname,
    conversionRates: oldConversionRates,
    currency,
    discountPercent,
    emphasizeValue = false,
    hideSalePrice = false,
    isVertical = false,
    price,
    priceStyle,
    priceStyleObj,
    salePrice,
    showDiscount = true,
    size = 'regular',
    style,
}: TProps) {
    const { conversionRates } = useConfigContext();
    const { currencyCode: localeCurrency, isArabic } = useAppContext();

    const priceConvertor = (cost: number): number =>
        convertPrice({
            conversionRates: oldConversionRates || (conversionRates as TConversionRates),
            fromCurrency: currency,
            toCurrency: localeCurrency,
            price: cost,
        });

    const priceLocal = priceConvertor(price);
    const salePriceLocal = priceConvertor(salePrice || 0);

    const sellingPrice = formatPrice(salePriceLocal || priceLocal);
    let oldPrice: string | number | null = null;

    if (salePriceLocal) {
        oldPrice = priceLocal > 0 ? formatPrice(priceLocal) : 0;
    }

    const isDiscounted = !!salePriceLocal && salePriceLocal < priceLocal;

    const showDiscountTag = !!showDiscount && !!discountPercent;

    return (
        <section className={clsx(styles.container, style, isVertical && styles.isVertical, containerClassname)}>
            {!hideSalePrice && (
                <span
                    dir="ltr"
                    className={clsx(
                        styles.sellingPrice,
                        priceStyle?.sell,
                        emphasizeValue && styles.emphasizeValue,
                        isDiscounted ? styles.discounted : '',
                        size === 'small' && styles.small,
                        size === 'regular' && styles.regular,
                        size === 'large' && styles.large,
                        size === 'x-large' && styles.xLarge,
                    )}
                    style={priceStyleObj?.sell}
                >
                    <span className={styles.currency} dir={isArabic ? 'rtl' : 'ltr'}>
                        {localeCurrency}
                    </span>
                    <span className={styles.value}>{Number(sellingPrice).toLocaleString()}</span>
                </span>
            )}

            {oldPrice ? (
                <div className={styles.oldPrice}>
                    <div
                        dir="ltr"
                        className={clsx(
                            styles.preReductionPrice,
                            priceStyle?.old,
                            size === 'small' && styles.small,
                            size === 'regular' && styles.regular,
                            size === 'large' && styles.large,
                            size === 'x-large' && styles.xLarge,
                        )}
                        style={priceStyleObj?.old}
                    >
                        {oldPrice}
                    </div>
                    {showDiscountTag && <DiscountTag discountPercent={discountPercent} size={size} />}
                </div>
            ) : (
                ''
            )}
        </section>
    );
}

/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ADCBCardResponse } from '../models/ADCBCardResponse';
import type { ADCBCardTokenizationResponse } from '../models/ADCBCardTokenizationResponse';
import type { ADCBCardTokenizeOTPPayload } from '../models/ADCBCardTokenizeOTPPayload';
import type { ADCBCardTokenizePayload } from '../models/ADCBCardTokenizePayload';
import type { ADCBPaymentOTPPayload } from '../models/ADCBPaymentOTPPayload';
import type { ADCBPaymentOTPResendPayload } from '../models/ADCBPaymentOTPResendPayload';
import type { PaymentStatusResponse } from '../models/PaymentStatusResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AdcbService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * fetch adcb card details
     * @returns ADCBCardResponse Successful Response
     * @throws ApiError
     */
    public getAdcbCardAdcbCardGet({
        xMp,
    }: {
        /**
         * Marketplace code (default is noon)
         */
        xMp?: any,
    }): CancelablePromise<ADCBCardResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/_svc/payment/adcb/card',
            headers: {
                'x-mp': xMp,
            },
        });
    }

    /**
     * tokenize a new adcb card
     * @returns ADCBCardTokenizationResponse Successful Response
     * @throws ApiError
     */
    public addAdcbCardAdcbCardAddPost({
        requestBody,
        xMp,
    }: {
        requestBody: ADCBCardTokenizePayload,
        /**
         * Marketplace code (default is noon)
         */
        xMp?: any,
    }): CancelablePromise<ADCBCardTokenizationResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/_svc/payment/adcb/card/add',
            headers: {
                'x-mp': xMp,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * submit adcb card tokenization otp
     * @returns ADCBCardResponse Successful Response
     * @throws ApiError
     */
    public submitAdcbCardOtpAdcbCardOtpPost({
        requestBody,
        xMp,
    }: {
        requestBody: ADCBCardTokenizeOTPPayload,
        /**
         * Marketplace code (default is noon)
         */
        xMp?: any,
    }): CancelablePromise<ADCBCardResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/_svc/payment/adcb/card/otp',
            headers: {
                'x-mp': xMp,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * submit adcb payment otp
     * @returns PaymentStatusResponse Successful Response
     * @throws ApiError
     */
    public submitAdcbPaymentOtpAdcbPaymentOtpPost({
        requestBody,
        xMp,
    }: {
        requestBody: ADCBPaymentOTPPayload,
        /**
         * Marketplace code (default is noon)
         */
        xMp?: any,
    }): CancelablePromise<PaymentStatusResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/_svc/payment/adcb/payment/otp',
            headers: {
                'x-mp': xMp,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * resend adcb payment otp
     * @returns PaymentStatusResponse Successful Response
     * @throws ApiError
     */
    public resendAdcbPaymentOtpAdcbPaymentOtpResendPost({
        requestBody,
        xMp,
    }: {
        requestBody: ADCBPaymentOTPResendPayload,
        /**
         * Marketplace code (default is noon)
         */
        xMp?: any,
    }): CancelablePromise<PaymentStatusResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/_svc/payment/adcb/payment/otp_resend',
            headers: {
                'x-mp': xMp,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}

/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateSubscriptionRequest } from '../models/CreateSubscriptionRequest';
import type { GetSubscriptionStatusRequest } from '../models/GetSubscriptionStatusRequest';
import type { PaymentStatusResponse } from '../models/PaymentStatusResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class SubscriptionService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Create Subscription
     * @returns PaymentStatusResponse Successful Response
     * @throws ApiError
     */
    public createSubscriptionSubscriptionCreatePost({
        requestBody,
        xMp,
    }: {
        requestBody: CreateSubscriptionRequest,
        /**
         * Marketplace code (default is noon)
         */
        xMp?: any,
    }): CancelablePromise<PaymentStatusResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/_svc/payment/subscription/create',
            headers: {
                'x-mp': xMp,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Subscription Status
     * @returns PaymentStatusResponse Successful Response
     * @throws ApiError
     */
    public getSubscriptionStatusSubscriptionStatusPost({
        requestBody,
        xMp,
    }: {
        requestBody: GetSubscriptionStatusRequest,
        /**
         * Marketplace code (default is noon)
         */
        xMp?: any,
    }): CancelablePromise<PaymentStatusResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/_svc/payment/subscription/status',
            headers: {
                'x-mp': xMp,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}

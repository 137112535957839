/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ProductsResponse } from '../models/ProductsResponse';
import type { ShopTheLookSimilarProductsRequest } from '../models/ShopTheLookSimilarProductsRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ShopTheLookService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Search for similar products for an image with polygons
     * @returns ProductsResponse Successful Response
     * @throws ApiError
     */
    public getSimilarProducts({
        requestBody,
        xVisitorId,
        xAddressKey,
        xLocale,
        xForwardedUser,
        xPlatform,
        xContent,
        xDebug,
    }: {
        requestBody: ShopTheLookSimilarProductsRequest,
        /**
         * Visitor ID
         */
        xVisitorId?: any,
        /**
         * Address Key
         */
        xAddressKey?: any,
        /**
         * Locale
         */
        xLocale?: any,
        /**
         * Customer Code
         */
        xForwardedUser?: any,
        /**
         * Platform
         */
        xPlatform?: any,
        /**
         * Content
         */
        xContent?: any,
        /**
         * Debug Mode
         */
        xDebug?: any,
    }): CancelablePromise<ProductsResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/_svc/catalog/shop_the_look/get_similar_products',
            headers: {
                'X-Visitor-Id': xVisitorId,
                'X-Address-Key': xAddressKey,
                'X-Locale': xLocale,
                'X-Forwarded-User': xForwardedUser,
                'X-Platform': xPlatform,
                'X-Content': xContent,
                'X-Debug': xDebug,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}

/* eslint-disable no-underscore-dangle */
/**
 * For Analytics Documentation, Please visit the wiki {@link https://next-square.atlassian.net/l/c/Fn0H7XVZ Analytics Documentation}.
 * @zynchro: this ^ might be outdated
 */

import { isBoolean, isEmpty } from 'lodash';
import natSDK from 'nat-sdk-web';
import QueryString from 'qs';
import { v4 as uuid } from 'uuid';

import { CustomerDataResponse } from '@nm-namshi-frontend/services';

import packageInfo from '../../package.json';
import { MARKETPLACE } from '../config';
import { TLocaleAPI, TLocaleSEO } from '../types/locale';
import { TTrackEvent, TTrackPageVisit } from './analytics.types';
import { getCookie, getPathname, isBrowser, setCookie } from './helper';
import { getLocaleConfig } from './locale';
import { addToDataLayer } from './tagManager';

// enable only on prod/stg/locale browser
const ENABLE_ANALYTICS = isBrowser();
const PLATFORM = process.env._APP === 'schmatalog' ? 'mweb' : 'web';

let visitorId = '';
const getQueryParams = (key: string) => {
    const params = QueryString.parse(window.location.search?.slice(1), { plainObjects: true });
    return params[key] || '';
};

// #region --- 01: Initializer fuctions
// Initialize everything we need on the client.
export const initializeAnalytics = (locale: TLocaleSEO, vid: string): void => {
    if (!ENABLE_ANALYTICS) {
        return;
    }
    visitorId = vid;
    const { apiLocale } = getLocaleConfig(locale);
    initializeNAT(apiLocale);
};

const initializeNAT = async (apiLocale: TLocaleAPI): Promise<void> => {
    const natURL = process.env.NAT_URL;
    if (!natSDK.initialized && natURL) {
        try {
            natSDK.init(natURL, MARKETPLACE, visitorId, apiLocale, PLATFORM, '', 'beacon');
            natSDK.updateLocale(apiLocale);

            const hasActiveSession = !!getCookie('session_id');

            if (!hasActiveSession) {
                const newSessionId = uuid();
                setCookie('session_id', newSessionId, { maxAge: 30 * 60 });

                reportToNAT({ ev: 'launch' });
            }
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error('Error initializing NAT (report to sentry)');
        }
    }
};

export const setCustomerDataForAnalytics = (customerData: CustomerDataResponse): void => {
    if (!customerData || !customerData.data) {
        return;
    }

    const { email, firstName, id: customerId, lastName, phone, primaryPhoneNumber } = customerData.data;
    natSDK.updateUid(customerId);
    addToDataLayer({
        uid: customerId,
        email,
        firstName,
        lastName,
        phone: phone || primaryPhoneNumber,
    });
};
// #endregion --- Initializer Function

// #region --- 02: Reporter functions
const reportToNAT = (
    eventData: TTrackEvent[keyof TTrackEvent]['nat'] | TTrackPageVisit[keyof TTrackPageVisit]['nat'],
): void => {
    if (!ENABLE_ANALYTICS || !MARKETPLACE) {
        return;
    }

    let pageSourcePath = '';

    const referrer = decodeURIComponent(getCookie('referer') || '');

    if (referrer) {
        const referrerUrlObject = new URL(referrer);
        const referrerPathname = referrerUrlObject?.pathname;
        const referrerSearchParams = referrerUrlObject?.searchParams?.toString();

        pageSourcePath = `${referrerPathname}${referrerSearchParams ? `?${referrerSearchParams}` : ''}`;
    }

    let currentDate = new Date();
    let timestamp = currentDate.getTime();

    const data = {
        mc: MARKETPLACE, // mp_code
        mpe: MARKETPLACE, // mp_experience (eg: noon, food)
        visitorId,
        pagePath: getPathname(),
        campaign: decodeURIComponent(getQueryParams('utm_campaign') as string),
        pageSourcePath,
        appVersion: packageInfo.version,
        sessionId: getCookie('session_id'),
        timestamp,
        ...eventData,
    } as const;

    // Retry in case nat has not been initialized yet
    try {
        if (natSDK.initialized) {
            natSDK.track(data.ev, data as any);
        } else {
            setTimeout(() => {
                currentDate = new Date();
                timestamp = currentDate.getTime();

                // We need to ensure that the tracking is done based on the latest data, not the data when the JS closure was created
                // This means that both timestamp and session_id cookie may both be missing; thus we need to ensure they are available after the timeout is created
                const closureEventData = {
                    ...data,
                    timestamp,
                    sessionId: getCookie('session_id'),
                };

                natSDK.track(closureEventData.ev, closureEventData as any);
            }, 1000);
        }
    } catch (error) {
        // report to sentry
        // reportEvent('Error during track page event, likely not initialized', 'trackNAT', 'error', {
        //     error,
        //     data,
        // });
        // eslint-disable-next-line no-console
        console.error('Error report to NAT (report to sentry)');
    }
};
// #endregion --- 02: Reporter functions

// #region --- 03: Track function
export const trackPage = (data: TTrackPageVisit[keyof TTrackPageVisit]['data']): void => {
    switch (data.event) {
        // PDP
        case 'page_pdp': {
            // Sending existing analytics data if any and appending attribution token data , that comes from PLP
            const analyticsPDP = {
                ...data.catalog.analytics,
                ...(!isEmpty(data.attributionData) ? { td: data.attributionData } : {}),
            };

            reportToNAT({
                ev: data.event,
                sku: data.product.sku,
                psku: data.product.parentSku,
                curl: data.catalog.canonicalUrl,
                url: data.pageUrl,
                netStock: data.netStock,
                isOOS: data.isOOS,
                plpIndex: data.plpIndex,
                ...(data.plpPage ? { plpPage: data.plpPage } : {}),
                pageType: 'pdp',
                productImageUrl: data.productImageUrl,
                productOriginalPrice: data.productOriginalPrice,
                productCurrentPrice: data.productCurrentPrice,
                productPriceCurrency: data.productPriceCurrency,
                productSize: data.productSize,
                isSponsored: data.isSponsored,
                source: data.source,
                ...(isBoolean(data.product.isRocket) ? { isRocket: data.product.isRocket ? 1 : 0 } : {}),
                ...(isBoolean(data.product.isGlobal) ? { isGlobal: data.product.isGlobal ? 1 : 0 } : {}),
                ...(!isEmpty(analyticsPDP) ? { analytics: analyticsPDP } : {}),
                ...(!isEmpty(data.productTag) ? { productTag: data.productTag } : {}),
            });
            addToDataLayer({
                event: 'page',
                pageType: 'pdp',
                sku: data.product.sku,
                psku: data.product.parentSku,
                price: data.productCurrentPrice || data.productOriginalPrice,
                breadcrumbs: data.catalog.breadcrumbs || [],
                category: data.catalog.breadcrumbs?.[data.catalog.breadcrumbs.length - 1],
                currency: data.productPriceCurrencyEN,
                title: data.productTitle,
            });
            break;
        }

        case 'page_plp': {
            reportToNAT({
                ev: data.event,
                curl: data.catalog?.canonicalUrl,
                st: data.catalog?.nav?.searchMeta.query,
                searchTerm: data.searchTerm,
                pageType: 'listing',
                analytics: data.catalog?.analytics,
                skuList: data.skuList,
                resultCount: data.resultCount,
                source: data.source,
                ...(data.pagePath ? { pagePath: data.pagePath } : {}),
            });
            if (data.searchTerm && data.searchTerm !== '*') {
                addToDataLayer({
                    event: 'search',
                    searchTerm: data.searchTerm,
                });
            }
            addToDataLayer({
                event: 'page',
                pageType: 'listing',
                ...(data.pagePath ? { pagePath: data.pagePath } : {}),
            });
            break;
        }

        case 'page': {
            reportToNAT({
                ev: data.event,
                pageType: data.pageType,
                pagePath: data.pagePath,
                orderNr: data.orderNr,
                skuList: data.skuList,
                sku: data.sku,
                purchaseItemNr: data.purchaseItemNr,
                formType: data.formType,
                analytics: data.analytics,
                source: data.source,
                selectedTab: data.selectedTab,
                ...(data.pagePath
                    ? {
                          pagePath: data.pagePath,
                      }
                    : {}),
                ...(data.pageSourcePath
                    ? {
                          pageSourcePath: data.pageSourcePath,
                      }
                    : {}),
                ...(!isEmpty(data.orderNr) ? { orderNr: data.orderNr } : {}),
                ...(data.cart_value_total ? { cart_value_total: data.cart_value_total } : {}),
                ...(data.cart_items_total ? { cart_items_total: data.cart_items_total } : {}),
                ...(data.cart_items_total ? { cart_items_total: data.cart_items_total } : {}),
                ...(!isEmpty(data.skus) ? { skus: data.skus } : {}),
                ...(!isEmpty(data.oosSkus) ? { oosSkus: data.oosSkus } : {}),
                ...(data.profileCompletionPercentage
                    ? { profileCompletionPercentage: data.profileCompletionPercentage }
                    : {}),
            });
            addToDataLayer({
                event: data.event,
                pageType: data.pageType,
                pagePath: data.pagePath,
                ...(data.pageData?.contents?.length
                    ? {
                          contents: data.pageData?.contents,
                      }
                    : {}),
                ...(data.pageData?.value
                    ? {
                          value: data.pageData.value,
                      }
                    : {}),
                ...(data.pageData?.currency
                    ? {
                          currency: data.pageData.currency,
                      }
                    : {}),
                ...(data.pageData?.transaction_id
                    ? {
                          transaction_id: data.pageData.transaction_id,
                      }
                    : {}),
                ...(!isEmpty(data.orderNr) ? { orderNr: data.orderNr } : {}),
                ...(data.cart_value_total ? { cart_value_total: data.cart_value_total } : {}),
                ...(data.cart_items_total ? { cart_items_total: data.cart_items_total } : {}),
                ...(!isEmpty(data.skus) ? { skus: data.skus } : {}),
            });
            break;
        }

        default:
            // eslint-disable-next-line no-console
            console.warn('Unknown track page request', data);
    }
};

export const trackEvent = (data: TTrackEvent[keyof TTrackEvent]['data']): void => {
    switch (data.event) {
        case 'signin':
            reportToNAT({
                ev: data.event,
                uid: data.uid,
                login_method: data.login_method,
            });
            addToDataLayer({
                uid: data.uid,
                email: data.email,
                firstName: data.firstName,
                lastName: data.lastName,
                phone: data.phone,
            });
            break;
        case 'signup':
            reportToNAT({
                ev: data.event,
                uid: data.uid,
            });
            break;
        case 'logout':
            reportToNAT({
                ev: data.event,
            });
            break;
        case 'click_quick_buy':
            reportToNAT({
                ev: data.event,
                sku: data.sku,
                psku: data.parentSku,
                plpPage: data.plpPage,
                contentColumn: data.contentColumn,
                contentRow: data.contentRow,
            });
            break;
        case 'cart_add':
            reportToNAT({
                ev: data.event,
                sku: data.sku,
                psku: data.parentSku,
                event_location: data.event_location,
                sizeSelected: data.sizeSelected,
                isSponsored: data.isSponsored,
                ...(isBoolean(data.isRocket) ? { isRocket: data.isRocket ? 1 : 0 } : {}),
                ...(isBoolean(data.isGlobal) ? { isGlobal: data.isGlobal ? 1 : 0 } : {}),
                ...(data.pagePath ? { pagePath: data.pagePath } : {}),
                ...(data.pageType ? { pageType: data.pageType } : {}),
            });
            addToDataLayer({
                event: data.event,
                sku: data.sku,
                psku: data.parentSku,
                price: data.price,
                value: data.value,
                quantity: data.quantity,
                title: data.title,
                currency: data.currency,
                ...(data.pagePath ? { pagePath: data.pagePath } : {}),
                ...(data.pageType ? { pageType: data.pageType } : {}),
            });
            break;
        case 'cart_remove':
            reportToNAT({
                ev: data.event,
                sku: data.sku,
                psku: data.parentSku,
                event_location: data.event_location,
                sizeSelected: data.sizeSelected,
                ...(isBoolean(data.isRocket) ? { isRocket: data.isRocket ? 1 : 0 } : {}),
                ...(isBoolean(data.isGlobal) ? { isGlobal: data.isGlobal ? 1 : 0 } : {}),
                ...(data.pagePath ? { pagePath: data.pagePath } : {}),
                ...(data.pageType ? { pageType: data.pageType } : {}),
                ...(data.isOOS ? { isOOS: data.isOOS } : {}),
            });
            break;
        case 'wishlist_add':
            reportToNAT({
                ev: data.event,
                sku: data.sku,
                psku: data.parentSku,
                pageType: data.pageType,
                ...(isBoolean(data.isRocket) ? { isRocket: data.isRocket ? 1 : 0 } : {}),
                ...(isBoolean(data.isGlobal) ? { isGlobal: data.isGlobal ? 1 : 0 } : {}),
                ...(data.pagePath ? { pagePath: data.pagePath } : {}),
                ...(data.isOOS ? { isOOS: data.isOOS } : {}),
            });
            addToDataLayer({
                event: data.event,
                sku: data.sku,
                psku: data.parentSku,
                price: data.price,
                currency: data.currency,
            });
            break;
        case 'wishlist_remove':
            reportToNAT({
                ev: data.event,
                sku: data.sku,
                psku: data.parentSku,
                ...(isBoolean(data.isRocket) ? { isRocket: data.isRocket ? 1 : 0 } : {}),
            });
            break;
        // Account
        case 'page_addresses':
            reportToNAT({
                ev: data.event,
            });
            break;
        case 'page_credits':
            reportToNAT({
                ev: data.event,
            });
            break;
        case 'page_orders':
            reportToNAT({
                ev: data.event,
            });
            break;
        case 'page_returns':
            reportToNAT({
                ev: data.event,
            });
            break;
        case 'page_payments':
            reportToNAT({
                ev: data.event,
            });
            break;
        case 'page_profile':
            reportToNAT({
                ev: data.event,
            });
            break;
        // --- Address / Map Stuff
        case 'address_add':
            reportToNAT({
                ev: data.event,
            });
            break;
        case 'address_delete':
            reportToNAT({
                ev: data.event,
            });
            break;
        case 'address_edit':
            reportToNAT({
                ev: data.event,
            });
            break;
        case 'address_pin_confirm':
            reportToNAT({
                ev: data.event,
            });
            break;
        case 'address_pin_drop':
            reportToNAT({
                ev: data.event,
                area: data.area,
                ac: data.ac,
            });
            break;
        case 'address_save':
            reportToNAT({
                ev: data.event,
            });
            break;

        case 'address_pin_er':
            reportToNAT({
                ev: data.event,
                error: data.error,
            });
            break;
        // checkout
        case 'add_payment_method':
            reportToNAT({
                ev: data.event,
            });
            break;
        case 'select_payment_method':
            reportToNAT({
                ev: data.event,
                pym: data.paymentMethod,
            });
            break;
        case 'apply_credits':
            reportToNAT({
                ev: data.event,
            });
            break;
        case 'applyCouponButtonPressed':
            reportToNAT({
                ev: data.event,
                couponCode: data.couponCode,
                couponType: data.couponType,
                response: data.response,
                pagePath: data.pagePath,
                event_location: data.event_location,
                responseText: data.responseText,
            });
            break;
        case 'checkout':
            reportToNAT({
                ev: data.event,
            });
            addToDataLayer({
                event: data.event,
                contents: data.contents,
                currency: data.currency,
                value: data.value,
            });
            break;
        case 'purchase':
            reportToNAT({
                ev: data.event,
                orderNr: data.orderNr,
                skuList: data.skuList,
                paymentMethod: data.paymentMethod,
                hasCoupon: data.hasCoupon,
                couponList: data.couponList,
                discountCodes: data.discountCodes,
            });
            break;
        case 'deactivate_viewed':
            reportToNAT({
                ev: data.event,
            });
            break;
        case 'deactivate_submit':
            reportToNAT({
                ev: data.event,
            });
            break;
        case 'search':
            reportToNAT({
                ev: data.event,
                searchTerm: data.searchTerm,
                suggestionPosition: data.suggestionPosition,
                suggestionTerm: data.suggestionTerm,
                suggestionType: data.suggestionType,
            });
            addToDataLayer({
                event: data.event,
                searchTerm: data.searchTerm,
            });
            break;

        case 'impression_ssuggest':
            reportToNAT({
                ev: data.event,
                searchTerm: data.searchTerm,
                suggestionPosition: data.suggestionPosition,
                suggestionTerm: data.suggestionTerm,
                suggestionType: data.suggestionType,
            });

            break;
        case 'noResults':
            reportToNAT({
                ev: data.event,
                searchTerm: data.searchTerm,
            });
            break;
        case 'impression_detail':
            reportToNAT({
                ev: data.event,
                sku: data.sku,
                psku: data.parentSku,
                pageType: data.pageType,
                contentCreative: data.contentCreative,
                contentColumn: data.contentColumn,
                contentRow: data.contentRow,
                widgetId: data.widgetId,
                imagePath: data.imagePath,
                plpPage: data.plpPage,
                isSponsored: data.isSponsored,
                ...(isBoolean(data.isRocket) ? { isRocket: data.isRocket ? 1 : 0 } : {}),
                ...(isBoolean(data.isGlobal) ? { isGlobal: data.isGlobal ? 1 : 0 } : {}),
                ...(data.pagePath ? { pagePath: data.pagePath } : {}),
                ...(!isEmpty(data.productTag) ? { productTag: data.productTag } : {}),
            });
            break;
        case 'recos_click':
            reportToNAT({
                ev: data.event,
                sku: data.sku,
                psku: data.parentSku,
                pageType: data.pageType,
                contentCreative: data.contentCreative,
                contentColumn: data.contentColumn,
                contentRow: data.contentRow,
                imagePath: data.imagePath,
            });
            break;
        case 'impression_content':
            reportToNAT({
                ev: data.event,
                contentClickUrl: data.contentClickUrl,
                contentCreative: data.contentCreative,
                contentModuleType: data.contentModuleType,
                contentColumn: data.contentColumn,
                contentRow: data.contentRow,
                imagePath: data.imagePath,
                pageType: data.pageType,
                widgetId: data.widgetId,
                analytics: data.analytics,
                row: data.row,
                column: data.column,
                searchTerm: data.searchTerm,
                isLocked: data.isLocked,
                ...(data.pagePath
                    ? {
                          pagePath: data.pagePath,
                      }
                    : {}),
            });
            break;
        case 'contentClick':
            reportToNAT({
                ev: data.event,
                contentClickUrl: data.contentClickUrl,
                contentCreative: data.contentCreative,
                contentModuleType: data.contentModuleType,
                contentColumn: data.contentColumn,
                contentRow: data.contentRow,
                imagePath: data.imagePath,
                pageType: data.pageType,
                widgetId: data.widgetId,
                analytics: data.analytics,
                ...(data.pagePath
                    ? {
                          pagePath: data.pagePath,
                      }
                    : {}),
            });
            break;
        case 'filterClicked':
            reportToNAT({
                ev: data.event,
                filterKey: data.filterKey,
                filterValue: data.filterValue,
                filterType: data.filterType,
                pageType: data.pageType,
            });
            break;
        case 'filterUnclicked':
            reportToNAT({
                ev: data.event,
                filterKey: data.filterKey,
                filterValue: data.filterValue,
                filterType: data.filterType,
                pageType: data.pageType,
            });
            break;
        case 'sortClicked':
            reportToNAT({
                ev: data.event,
                sortKey: data.sortKey,
                sortDirection: data.sortDirection,
            });
            break;
        case 'popup':
            reportToNAT({
                ev: data.event,
                popupName: data.popupName,
                popupAction: data.popupAction,
                migWalletAmt: data.migWalletAmt,
                migWishlistCnt: data.migWishlistCnt,
                migBagCnt: data.migBagCnt,
                defaultLoginType: data.defaultLoginType,
                popupContent: data.popupContent,
                pagePath: data.pagePath,
                pageType: data.pageType,
                couponStatus: data.couponStatus,
            });
            break;
        case 'error':
            reportToNAT({
                ev: data.event,
                errorText: data.errorText,
            });
            break;
        case 'brokenCatalog':
            reportToNAT({
                ev: data.event,
            });
            break;
        case 'impression_filter':
            reportToNAT({
                ev: data.event,
                pageType: data.pageType,
                filterKey: data.filterKey,
                searchTerm: data.searchTerm,
                pagePath: data.pagePath,
            });
            break;
        case 'gwp_cart_interaction':
            reportToNAT({
                ev: data.event,
                gwp: data.gwp,
            });
            break;

        case 'feed_selection':
            reportToNAT({
                ev: data.event,
                pageType: data.pageType,
                feedL1: data.feedL1,
                feedL2: data.feedL2,
                ...(data.pagePath
                    ? {
                          pagePath: data.pagePath,
                      }
                    : {}),
            });
            break;

        case 'imageSwipe':
            reportToNAT({
                ev: data.event,
                pageType: data.pageType,
                sku: data.sku,
                contentCreative: data.contentCreative,
                contentColumn: data.contentColumn,
                contentRow: data.contentRow,
                imagePath: data.imagePath,
                searchTerm: data.searchTerm,
            });
            break;
        case 'brandClick':
            reportToNAT({
                ev: data.event,
                brandCode: data.brandCode,
                contentClickUrl: data.contentClickUrl,
            });

            break;
        case 'impressionSizeGuide':
            reportToNAT({
                ev: data.event,
                conversionAvailable: data.conversionAvailable,
                measurementAvailable: data.measurementAvailable,
                defaultMatrix: data.defaultMatrix, //  size standard (uk, eu ..)
                selectedMatrix: data.selectedMatrix,
                tab: data.tab,
                sku: data.sku,
            });

            break;
        case 'impressionSizeSelector':
            reportToNAT({
                ev: data.event,
                pagePath: data.pagePath,
                pageType: data.pageType,
            });
            break;
        case 'gwpClick':
            reportToNAT({
                ev: data.event,
                contentClickUrl: data.contentClickUrl,
            });
            break;
        case 'discountClick':
            reportToNAT({
                ev: data.event,
                contentClickUrl: data.contentClickUrl,
            });
            break;
        case 'timerClick':
            reportToNAT({
                ev: data.event,
                contentClickUrl: data.contentClickUrl,
            });
            break;
        case 'shipmentModeClick':
            reportToNAT({
                ev: data.event,
                shippingMode: data.shippingMode,
                shippingPromise: data.shippingPromise,
            });
            break;
        case 'brandFollow':
            reportToNAT({
                ev: data.event,
                brandCode: data.brandCode,
                widgetId: data.widgetId,
                pageType: data.pageType,
            });
            break;
        case 'brandUnfollow':
            reportToNAT({
                ev: data.event,
                brandCode: data.brandCode,
                widgetId: data.widgetId,
                pageType: data.pageType,
            });
            break;
        case 'linkShare':
            reportToNAT({
                ev: data.event,
                link: data.link,
                pageType: data.pageType,
            });
            break;
        case 'contentShare':
            reportToNAT({
                ev: data.event,
                contentModuleType: data.contentModuleType,
                column: data.column,
                row: data.row,
                widgetId: data.widgetId,
                pageType: data.pageType,
                utmParam: data.utmParam,
            });
            break;
        case 'impression_shipping':
            reportToNAT({
                ev: data.event,
                pageType: data.pageType,
                pagePath: data.pagePath,
                shippingKey: data.shippingKey,
                state: data.state,
                value: data.value,
            });
            break;
        case 'createNewWardrobe':
            reportToNAT({
                ev: data.event,
            });
            break;
        case 'wardrobe_add':
            reportToNAT({
                ev: data.event,
                skus: data.skus,
            });
            break;
        case 'wardrobe_remove':
            reportToNAT({
                ev: data.event,
                sku: data.sku,
            });
            break;
        case 'shareWardrobe':
            reportToNAT({
                ev: data.event,
                wardrobeName: data.wardrobeName,
                wardrobeCode: data.wardrobeCode,
                utmParam: data.utmParam,
            });
            break;

        case 'mapImpression':
            reportToNAT({
                ev: data.event,
                orderNr: data.orderNr,
                pageType: data.pageType,
            });
            break;
        case 'couponImpression':
            reportToNAT({
                ev: data.event,
                coupon_code: data.coupon_code,
                discount_code: data.discount_code,
                event_location: data.event_location,
                pagePath: data.pagePath,
                url: data.url,
                position: data.position,
            });
            break;
        case 'couponContentClick':
            reportToNAT({
                ev: data.event,
                pagePath: data.pagePath,
                action: data.action,
                url: data.url,
                event_location: data.event_location,
            });
            break;
        case 'orderSummaryImpression':
            reportToNAT({
                ev: data.event,
                pageType: data.pageType,
                pagePath: data.pagePath,
                orderSummaryContent: data.orderSummaryContent,
            });
            break;
        case 'couponProgressImpression':
            reportToNAT({
                ev: data.event,
                steps: data.steps,
                current_step: data.current_step,
                next_step: data.next_step,
                pagePath: data.pagePath,
                event_location: data.event_location,
            });
            break;
        case 'postOrderClick':
            reportToNAT({
                ev: data.event,
                action: data.action,
                orderNr: data.orderNr,
            });
            break;
        case 'genericButtonClick':
            reportToNAT({
                ev: data.event,
                action: data.action,
                pageType: data.pageType,
                pagePath: data.pagePath,
                sku: data.sku,
            });
            break;
        case 'checkoutBankOfferImpression':
            reportToNAT({
                ev: data.event,
                pagePath: data.pagePath,
                pageType: data.pageType,
                discountCode: data.discountCode,
            });
            break;
        case 'helpModuleClick':
            reportToNAT({
                ev: data.event,
                action: data.action,
                searchQuery: data.searchQuery,
                option: data.option,
                qid: data.qid,
                purchaseItemNr: data.purchaseItemNr,
            });
            break;
        default:
            // eslint-disable-next-line no-console
            console.warn('Unknown track event request', data);
    }
};

export default initializeAnalytics;
